import React, { useState, useEffect } from 'react';
import {
	Button,
	Grid,
	MenuItem,
	Paper,
	Select,
	Typography,
	FormControl,
	InputLabel,
	TextField,
} from '@mui/material';
import { green } from '@mui/material/colors';
import {
	apiUpdateProjecScope,
	apiUpdateMaterial,
	IProjectScopeBackEnd,
	IProjectScopeInput,
	IMaterialBackEnd,
	IMaterialInput,
  apiDeleteMaterial,
} from '../../../services/apiService';
import { toast } from 'react-toastify';

interface UpdateMaterialProps {
	material: IMaterialBackEnd[];
	updatedDataToggle: boolean;
}

function UpdateMaterial({ material, updatedDataToggle }: UpdateMaterialProps) {
	const [updatedMaterials, setUpdatedMaterials] = useState<IMaterialBackEnd[]>(
		[]
	);

	useEffect(() => {
		// Ordena os materiales uma vez quando o componente é montado
		const sortedMaterials = [...material].sort((a, b) =>
			a.description.localeCompare(b.description)
		);
		setUpdatedMaterials(sortedMaterials);
	}, [material, updatedDataToggle]);

	const handleUpdateMaterial = async (
		material: IMaterialBackEnd
	) => {
		if (material.description === '') {
			toast.error('O campo não pode ser vazio');
			return;
		}

		const updatedMaterial = {
			description: material.description,
		};

		try {
			await apiUpdateMaterial(material.id, updatedMaterial);
			toast.success('Material atualizado com sucesso');
		} catch (error) {
			toast.error('Erro ao atualizar o material');
		}
	};

	const handleDelete = async (materialId: number) => {
		try {
			await apiDeleteMaterial(materialId);
			const updatedMaterialsNew = updatedMaterials.filter(
				material => material.id !== materialId
			);
			setUpdatedMaterials(updatedMaterialsNew);
			toast.success('Registro deletado com sucesso!');
		} catch (error) {
			toast.error(
				'Erro: o material possui um ou mais projetos ligado a ele. Altere-os antes.'
			);
		}
	};

	const handleNameChange = (id: number, newMaterial: string) => {
		setUpdatedMaterials(prevMaterials =>
			prevMaterials.map(material =>
				material.id === id
					? { ...material, description: newMaterial }
					: material
			)
		);
	};

	return (
		<Grid item xs={12}>
			<Paper elevation={5} style={{ padding: 20 }}>
				<Typography
					variant="h6"
					component="h3"
					style={{ marginBottom: '20px' }}
				>
					Materiais
				</Typography>
				{updatedMaterials.map(material => (
					<Grid
						container
						spacing={2}
						alignItems="center"
						key={material.id}
						style={{ marginBottom: 20 }}
					>
						<Grid item xs={3}>
							<TextField
								fullWidth
								label="Material"
								value={material.description}
								onChange={e =>
									handleNameChange(material.id, e.target.value)
								}
								variant="outlined"
							/>
						</Grid>
						<Grid item xs={2}>
							<Button
								color="primary"
								variant="contained"
								onClick={() => handleUpdateMaterial(material)}
							>
								Atualizar
							</Button>
							<Button
								variant="contained"
								onClick={() => handleDelete(material.id)} // Correção: Passar uma função anônima que chama handleDelete
								sx={{
									backgroundColor: 'red',
									'&:hover': {
										backgroundColor: 'darkred',
									},
									color: 'white',
									marginLeft: 2,
								}}
							>
								Deletar
							</Button>
						</Grid>
					</Grid>
				))}
			</Paper>
		</Grid>
	);
}

export default UpdateMaterial;
