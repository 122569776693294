import React, { useEffect, useState } from 'react';
import {
	Button,
	Grid,
	MenuItem,
	Paper,
	Select,
	Typography,
	FormControl,
	InputLabel,
	TextField,
	Tooltip,
	IconButton,
	List,
	ListItem,
	ListItemText,
} from '@mui/material';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
	apiGetAllWorkerNames,
	apiGetWorkerById,
	apiGeLevelBySkillId,
	apiUpdateWorkerSkill,
	apiGetAllSkills,
	apiCreateWorkerSkill,
	IWorkerNameBackEnd,
	IWorkerSkill,
	ILevel,
	ISkill,
} from '../services/apiService';
import { green } from '@mui/material/colors';
import { InfoOutlined } from '@mui/icons-material';
import { existInVisibilityRoleInLocalStorage } from '../utils/existInVisibilityRoleInLocalStorage';

interface NewSkill {
	skillId: number;
	levelId: number;
}

const WorkerSkills: React.FC = () => {
	const [workerNames, setWorkerNames] = useState<IWorkerNameBackEnd[]>([]);
	const [selectedWorkerId, setSelectedWorkerId] = useState<number | ''>('');
	const [workerSkills, setWorkerSkills] = useState<IWorkerSkill[]>([]);
	const [allSkills, setAllSkills] = useState<ISkill[]>([]);
	const [newSkill, setNewSkill] = useState<NewSkill>({
		skillId: 0,
		levelId: 0,
	});
	const [newSkillLevels, setNewSkillLevels] = useState<ILevel[]>([]);
	const [levels, setLevels] = useState<{ [key: number]: ILevel[] }>({});
	const [hasSearched, setHasSearched] = useState(false);
	const [showInfo, setShowInfo] = useState(false);
	const [hideAddSkillButton, setHideAddSkillButton] = useState(false);
	const [hideUpdateSkillButton, setHideUpdateSkillButton] = useState(false);

	useEffect(() => {
		setHideAddSkillButton(
			existInVisibilityRoleInLocalStorage(
				'hide add skill button (worker skills)'
			)
		);

		setHideUpdateSkillButton(
			existInVisibilityRoleInLocalStorage(
				'hide update skill button (worker skills)'
			)
		);
		const fetchData = async () => {
			try {
				const names = await apiGetAllWorkerNames();
				const sortedNames = names.sort((a, b) =>
					a.worker_name.localeCompare(b.worker_name)
				);
				setWorkerNames(sortedNames);
				const skills = await apiGetAllSkills();
				setAllSkills(skills);
			} catch (error) {
				toast.error('Erro ao carregar dados iniciais');
			}
		};
		fetchData();
	}, []);

	const handleFetchWorkerSkills = async () => {
		setHasSearched(true);
		if (selectedWorkerId !== '') {
			try {
				const worker = await apiGetWorkerById(selectedWorkerId);
				const workerSkills = worker.worker_skill;
				setWorkerSkills(workerSkills);

				// Load levels for each skill
				const levelsData: { [key: number]: ILevel[] } = {};
				await Promise.all(
					workerSkills.map(async (skill: IWorkerSkill) => {
						const skillLevels = await apiGeLevelBySkillId(skill.skill_id);
						levelsData[skill.skill_id] = skillLevels;
					})
				);
				setLevels(levelsData);
			} catch (error) {
				toast.error('Erro ao carregar as habilidades do trabalhador');
			}
		}
	};

	const handleUpdateWorkerSkill = async (skill: IWorkerSkill) => {
		try {
			await apiUpdateWorkerSkill(skill.id, {
				skill_id: skill.skill_id,
				worker_id: skill.worker_id,
				level_id: skill.level_id,
			});
			toast.success('Habilidade atualizada com sucesso');
		} catch (error) {
			toast.error('Erro ao atualizar a habilidade do trabalhador');
		}
	};

	const handleAddWorkerSkill = async () => {
		try {
			await apiCreateWorkerSkill({
				skill_id: newSkill.skillId,
				worker_id: selectedWorkerId as number,
				level_id: newSkill.levelId,
			});
			handleFetchWorkerSkills();
			setNewSkill({ skillId: 0, levelId: 0 });
			toast.success('Habilidade adicionada com sucesso');
		} catch (error) {
			toast.error('Erro ao adicionar a habilidade do trabalhador');
		}
	};

	const handleNewSkillChange = async (skillId: number) => {
		setNewSkill(prevSkill => ({ ...prevSkill, skillId, levelId: 0 }));
		try {
			const levels = await apiGeLevelBySkillId(skillId);
			setNewSkillLevels(levels);
		} catch (error) {
			toast.error('Erro ao carregar os níveis da habilidade');
		}
	};

	const handleOpenLevelSelect = async (skillId: number) => {
		if (!levels[skillId]) {
			try {
				const skillLevels = await apiGeLevelBySkillId(skillId);
				setLevels(prevLevels => ({ ...prevLevels, [skillId]: skillLevels }));
			} catch (error) {
				toast.error('Erro ao carregar os níveis da habilidade');
			}
		}
	};

	const handleWorkerChange = (id: number) => {
		setSelectedWorkerId(id);
		setWorkerSkills([]);
		setNewSkill({ skillId: 0, levelId: 0 });
		setHasSearched(false);
	};

	const handleInfoClick = () => {
		setShowInfo(!showInfo);
	};

	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<Paper elevation={5} style={{ padding: 20 }}>
					<Typography
						variant="h5"
						component="h2"
						style={{ marginBottom: '20px' }}
					>
						Habilidades do Trabalhador
						<Tooltip title="Informação" arrow>
							<IconButton onClick={handleInfoClick}>
								<InfoOutlined />
							</IconButton>
						</Tooltip>
					</Typography>{' '}
					{showInfo && (
						<Typography variant="body1" style={{ marginBottom: '40px' }}>
							<div>Legenda dos níveis:</div>
							<List
								style={{
									marginTop: '5px',
									paddingLeft: '20px',
									listStyleType: 'disc',
								}}
							>
								<ListItem
									style={{
										paddingBottom: '5px',
										paddingTop: '5px',
										display: 'list-item',
										listStyleType: 'disc',
									}}
								>
									<ListItemText
										primary={
											<span>
												<strong>A</strong> = Executor e Multiplicador
											</span>
										}
									/>
								</ListItem>
								<ListItem
									style={{
										paddingBottom: '5px',
										paddingTop: '5px',
										display: 'list-item',
										listStyleType: 'disc',
									}}
								>
									<ListItemText
										primary={
											<span>
												<strong>B</strong> = Executor
											</span>
										}
									/>
								</ListItem>
								<ListItem
									style={{
										paddingBottom: '5px',
										paddingTop: '5px',
										display: 'list-item',
										listStyleType: 'disc',
									}}
								>
									<ListItemText
										primary={
											<span>
												<strong>C</strong> = Executor sob supervisão
											</span>
										}
									/>
								</ListItem>
								<ListItem
									style={{
										paddingBottom: '5px',
										paddingTop: '5px',
										display: 'list-item',
										listStyleType: 'disc',
									}}
								>
									<ListItemText
										primary={
											<span>
												<strong>D</strong> = Requer Treinamento
											</span>
										}
									/>
								</ListItem>
							</List>
						</Typography>
					)}
					<Grid item xs={3.2}>
						<FormControl fullWidth variant="outlined">
							<InputLabel id="worker-select-label">Trabalhador</InputLabel>
							<Select
								labelId="worker-select-label"
								value={selectedWorkerId}
								onChange={e => handleWorkerChange(Number(e.target.value))}
								label="Trabalhador"
							>
								{workerNames.map(worker => (
									<MenuItem key={worker.id} value={worker.id}>
										{worker.worker_name}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Grid>
					<Button
						variant="contained"
						color="primary"
						onClick={handleFetchWorkerSkills}
						style={{
							marginTop: '20px',
							background: '#e5e5e5',
							color: 'black',
						}}
					>
						<strong>Pesquisar</strong>
					</Button>
				</Paper>
			</Grid>

			{hasSearched && selectedWorkerId !== '' && (
				<Grid item xs={12}>
					<Paper elevation={5} style={{ padding: 20 }}>
						<Typography
							variant="h6"
							component="h3"
							style={{ marginBottom: '20px' }}
						>
							Nova Habilidade
						</Typography>
						<Grid container spacing={2} alignItems="center">
							<Grid item xs={3}>
								<FormControl fullWidth variant="outlined">
									<InputLabel id="new-skill-select-label">
										Habilidade
									</InputLabel>
									<Select
										labelId="new-skill-select-label"
										value={newSkill.skillId}
										onChange={e => handleNewSkillChange(Number(e.target.value))}
										label="Habilidade"
									>
										{allSkills
											.sort((a, b) =>
												a.description.localeCompare(b.description)
											)
											.map(skill => (
												<MenuItem key={skill.id} value={skill.id}>
													{skill.description}
												</MenuItem>
											))}
									</Select>
								</FormControl>
							</Grid>
							<Grid item xs={3}>
								<FormControl fullWidth variant="outlined">
									<InputLabel id="new-level-select-label">Nível</InputLabel>
									<Select
										labelId="new-level-select-label"
										value={newSkill.levelId}
										onChange={e =>
											setNewSkill(prevSkill => ({
												...prevSkill,
												levelId: Number(e.target.value),
											}))
										}
										label="Nível"
									>
										{newSkillLevels
											.sort((a, b) =>
												a.description.localeCompare(b.description)
											)
											.map(level => (
												<MenuItem key={level.id} value={level.id}>
													{level.description}
												</MenuItem>
											))}
									</Select>
								</FormControl>
							</Grid>
							{!hideAddSkillButton && (
								<Grid item xs={2}>
									<Button
										variant="contained"
										onClick={handleAddWorkerSkill}
										sx={{
											backgroundColor: green[500], // Cor de fundo verde
											'&:hover': {
												backgroundColor: green[700], // Cor de fundo mais escura ao passar o mouse
											},
											color: 'white', // Cor do texto
										}}
									>
										<strong>Adicionar</strong>
									</Button>
								</Grid>
							)}
						</Grid>
					</Paper>
				</Grid>
			)}

			{hasSearched && workerSkills.length === 0 && (
				<Grid item xs={12}>
					<Paper elevation={5} style={{ padding: 20 }}>
						<Typography
							variant="h6"
							component="h3"
							style={{ marginBottom: '20px' }}
						>
							Nenhuma habilidade encontrada.
						</Typography>
					</Paper>
				</Grid>
			)}

			{workerSkills.length > 0 && (
				<Grid item xs={12}>
					<Paper elevation={5} style={{ padding: 20 }}>
						<Typography
							variant="h6"
							component="h3"
							style={{ marginBottom: '20px' }}
						>
							Habilidades
						</Typography>
						{workerSkills
							.sort((a, b) =>
								a.skill.description.localeCompare(b.skill.description)
							)
							.map(skill => (
								<Grid
									container
									spacing={2}
									alignItems="center"
									key={skill.id}
									style={{ marginBottom: 20 }}
								>
									<Grid item xs={3}>
										<TextField
											fullWidth
											label="Habilidade"
											value={skill.skill.description}
											InputProps={{
												readOnly: true,
											}}
										/>
									</Grid>
									<Grid item xs={3}>
										<FormControl fullWidth variant="outlined">
											<InputLabel id={`level-select-label-${skill.id}`}>
												Nível
											</InputLabel>
											<Select
												labelId={`level-select-label-${skill.id}`}
												value={skill.level_id}
												onOpen={() => handleOpenLevelSelect(skill.skill_id)}
												onChange={e =>
													setWorkerSkills(prevSkills =>
														prevSkills.map(s =>
															s.id === skill.id
																? { ...s, level_id: Number(e.target.value) }
																: s
														)
													)
												}
												label="Nível"
											>
												{(levels[skill.skill_id] || [])
													.sort((a, b) =>
														a.description.localeCompare(b.description)
													)
													.map(level => (
														<MenuItem key={level.id} value={level.id}>
															{level.description}
														</MenuItem>
													))}
											</Select>
										</FormControl>
									</Grid>
									{!hideUpdateSkillButton && (
										<Grid item xs={2}>
											<Button
												variant="contained"
												color="primary"
												onClick={() => handleUpdateWorkerSkill(skill)}
											>
												Atualizar
											</Button>
										</Grid>
									)}
								</Grid>
							))}
					</Paper>
				</Grid>
			)}
		</Grid>
	);
};

export default WorkerSkills;
