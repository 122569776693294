export function getMonthBoundaryDate(type: 'first' | 'last'): string {
	const currentDate = new Date();
	const year = currentDate.getFullYear();
	const month = currentDate.getMonth();

	if (type === 'first') {
		const firstDayOfMonth = new Date(year, month, 1);
		return firstDayOfMonth.toISOString().slice(0, 10);
	} else if (type === 'last') {
		const lastDayOfMonth = new Date(year, month + 1, 0);
		return lastDayOfMonth.toISOString().slice(0, 10);
	} else {
		throw new Error('Invalid argument. Type must be "first" or "last".');
	}
}
