import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
	IBoardingBackend,
	IDrakeWorkerBoardingBackEnd,
	IWorkerBoardingBackEnd,
	apiGetBoardingById,
	apiGetDrakeWorkerBoardingByBoardingId,
	apiGetWorkerBoardingByBoardingId,
} from '../../services/apiService';
import { toast } from 'react-toastify';
import BoardingFormEditPlanning from '../../components/BoardingFormEditPlanning';
import DrakeWorkerEditPlanning from '../../components/DrakeWorkerEditPlanning';
import WorkerFormEditPlanning from '../../components/WorkerFormEditPlanning';
import { Button, Collapse, Grid, Paper, Typography } from '@mui/material';
import { MoonLoader } from 'react-spinners';
import axios from 'axios';

function EditPlanningPage() {
	const { id } = useParams();
	const [workerBoardings, setWorkerBoardings] = useState<
		IWorkerBoardingBackEnd[]
	>([]);
	const [drakeWorkerBoardings, setDrakeWorkerBoardings] = useState<
		IDrakeWorkerBoardingBackEnd[]
	>([]);
	const [boardings, setBoardings] = useState<IBoardingBackend[]>([]);
	const [isLoading, setIsLoading] = useState(true);
  const [isLoading2, setIsLoading2] = useState(true);

	const handleRemoveWorkerBoarding = (workerBoardingId: number) => {
		setWorkerBoardings(currentEvents =>
			currentEvents.filter(item => item.id !== workerBoardingId)
		);
	};

	useEffect(() => {
		const fetchData = async () => {
			try {
				if (id !== undefined) {
					const backEndBoardings = await apiGetBoardingById(+id);
					setBoardings(backEndBoardings);
				}
			} catch (error) {
				console.log('🚀  error:', error);
				toast.error('ERROR: falha ao carregar os dados de embarque.');
			} finally {
				setTimeout(() => {
					setIsLoading(false);
				}, 250); // Define o tempo mínimo de exibição do spinner como 1 segundo
			}

			try {
				if (id !== undefined) {
					const backEndWorkerBoardings = await apiGetWorkerBoardingByBoardingId(
						+id
					);
					setWorkerBoardings(backEndWorkerBoardings);
				}
			} catch (error) {
				if (axios.isAxiosError(error)) {
					if (error.response?.status === 404) {
						setWorkerBoardings([]);
					} else {
						toast.error('ERROR: falha ao carregar funcionários embarcados (programado).');
					}
				} else {
					toast.error(
						'ERROR: falha ao carregar funcionários embarcados (programado).'
					);
				}
			} finally {
				setTimeout(() => {
					setIsLoading(false);
				}, 250); // Define o tempo mínimo de exibição do spinner como 1 segundo
			}

			try {
				if (id !== undefined) {
					const backEndDrakeWorkerBoardings =
						await apiGetDrakeWorkerBoardingByBoardingId(+id);
					setDrakeWorkerBoardings(backEndDrakeWorkerBoardings);
				}
			} catch (error) {
				if (axios.isAxiosError(error)) {
					if (error.response?.status === 404) {
						setDrakeWorkerBoardings([]);
					} else {
						toast.error('ERROR: falha ao carregar funcionários embarcados (realizado).');
					}
				} else {
					toast.error(
						'ERROR: falha ao carregar funcionários embarcados (realizado).'
					);
				}
			} finally {
				setTimeout(() => {
					setIsLoading2(false);
				}, 100); // Define o tempo mínimo de exibição do spinner como 1 segundo
			}
		};

		fetchData();
	}, []);

	return (
		<>
			{isLoading || isLoading2 ? (
				<div>
					<MoonLoader color="#36d7b7" />
				</div>
			) : (
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<BoardingFormEditPlanning boarding={boardings[0]} />
					</Grid>
					<Grid item xs={12}>
						{/* <WorkerForm workerBoarding={workerBoardings[0]} /> */}
						<Paper elevation={5} style={{ padding: 20 }}>
							<Typography
								variant="h5"
								component="h2"
								style={{ marginBottom: '20px' }}
							>
								Funcionários programados
							</Typography>
							<div>
								{workerBoardings.map(item => (
									<Grid
										item
										key={item.id}
										style={{
											marginBottom: '40px',
											borderTop: '1px solid #b0aeae',
											paddingTop: '40px',
										}}
									>
										<WorkerFormEditPlanning
											workerBoarding={item}
											onRemove={handleRemoveWorkerBoarding}
										/>
									</Grid>
								))}
							</div>
						</Paper>
					</Grid>
					<Grid item xs={12}>
						{/* <WorkerForm workerBoarding={workerBoardings[0]} /> */}
						<Paper elevation={5} style={{ padding: 20 }}>
							<Typography
								variant="h5"
								component="h2"
								style={{ marginBottom: '20px' }}
							>
								Embarcados
							</Typography>
							<div>
								{drakeWorkerBoardings.map(item => (
									<Grid
										item
										key={item.id}
										style={{
											marginBottom: '40px',
											borderTop: '1px solid #b0aeae',
											paddingTop: '40px',
										}}
									>
										<DrakeWorkerEditPlanning
											drakeWorkerBoarding={item}
										/>
									</Grid>
								))}
							</div>
						</Paper>
					</Grid>
				</Grid>
			)}
		</>
	);
}

export default EditPlanningPage;
