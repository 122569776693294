import React, { useEffect, useState } from 'react';
import { TextField, Button, Container, Typography } from '@mui/material';
import { login } from '../../features/auth/authSlice';
import { useDispatch } from '../../app/hooks'; // Importe o hook customizado
import { useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import { useNavigate } from 'react-router-dom';
import { existInVisibilityRoleInLocalStorage } from '../../utils/existInVisibilityRoleInLocalStorage';

export default function Login() {
	console.log('Env: ', process.env.REACT_APP_IP_BACKEND);

	const dispatch = useDispatch();
	const isLoggedIn = useSelector((state: RootState) => state.auth.isLoggedIn);
	const navigate = useNavigate(); // Hook do React Router para redirecionamento

	const [credentials, setCredentials] = useState({
		username: '',
		password: '',
	});
	const errorMessage = useSelector((state: RootState) => state.auth.error);

	useEffect(() => {
		if (isLoggedIn) {
			if (!existInVisibilityRoleInLocalStorage('hide project registration')) {
				navigate('/project-registration');
			} else {
				navigate('/project-edit');
			}
		}
	}, [isLoggedIn, navigate]);

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		setCredentials(prev => ({ ...prev, [name]: value }));
	};

	const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		try {
			// Supondo que a ação de login retorne uma promise quando chamada com dispatch
			await dispatch(login(credentials)).unwrap();
			// Redireciona para a dashboard após o sucesso no login
			if (existInVisibilityRoleInLocalStorage('hide project registration')) {
				navigate('/project-registration');
			} else {
				navigate('/project-edit');
			}
		} catch (error) {
			// Trate os erros de login aqui, como mostrar mensagens ao usuário
			console.error('Falha no login:', error);
		}
	};

	return (
		<Container component="main" maxWidth="xs" style={{ marginTop: '20px' }}>
			<Typography component="h1" variant="h5">
				Login
			</Typography>
			<form onSubmit={handleSubmit}>
				<TextField
					variant="outlined"
					margin="normal"
					required
					fullWidth
					id="username"
					label="E-mail"
					name="username"
					autoComplete="email"
					autoFocus
					onChange={handleChange}
					value={credentials.username}
				/>
				<TextField
					variant="outlined"
					margin="normal"
					required
					fullWidth
					name="password"
					label="Senha"
					type="password"
					id="password"
					autoComplete="current-password"
					onChange={handleChange}
					value={credentials.password}
				/>
				<Button type="submit" fullWidth variant="contained" color="primary">
					Entrar
				</Button>
				<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
					<img
						src="qualitech.jpeg"
						alt="Sua Imagem"
						width="120"
						height="auto"
						style={{ display: 'block' }}
					/>
				</div>
			</form>
			{errorMessage && <div>SENHA OU EMAIL INVÁLIDOS</div>}
		</Container>
	);
}
