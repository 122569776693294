import React, { useEffect, useState } from 'react';
import AddClient from './AddClient';
import UpdateClient from './UpdateClient';
import {
	apiGetAllClient,
	apiGetAllUsers,
	IClientBackend,
	IUserBackEnd,
} from '../../../services/apiService';
import { toast } from 'react-toastify';
import { MoonLoader } from 'react-spinners';

function ClientSettings() {
	const [coordinatorsDropdown, setCoordinatorsDropdown] = useState<
		IUserBackEnd[]
	>([]);
	const [clients, setClients] = useState<IClientBackend[]>([]);
	const [updatedDataToggle, setUpdatedDataToggle] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const users = await apiGetAllUsers();
				setCoordinatorsDropdown(users.filter(user => user.coordinator));
			} catch (error) {
				toast.error('Erro ao carregar dados iniciais');
			}
			try {
				const clients = await apiGetAllClient();
				setClients(clients);
			} catch (error) {
				toast.error('Erro ao carregar clientes');
			} finally {
				setTimeout(() => {
					setIsLoading(false);
				}, 500);
			}
		};
		fetchData();
	}, []);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const clients = await apiGetAllClient();
				setClients(clients);
			} catch (error) {
				toast.error('Erro ao recarregar clientes');
			} finally {
				setTimeout(() => {
					setIsLoading(false);
				}, 500);
			}
		};
		setIsLoading(true);
		fetchData();
	}, [updatedDataToggle]);

	return (
		<div>
			<AddClient
				coordinators={coordinatorsDropdown}
				updatedDataToggle={updatedDataToggle}
				setUpdatedDataToggle={setUpdatedDataToggle}
			/>
			{isLoading ? (
				<div>
					<MoonLoader color="#36d7b7" />
				</div>
			) : (
				<UpdateClient
					clients={clients}
					coordinators={coordinatorsDropdown}
					updatedDataToggle={updatedDataToggle}
				/>
			)}
		</div>
	);
}

export default ClientSettings;
