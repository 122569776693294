import React, { useEffect, useState } from 'react';
import {
	Grid,
	MenuItem,
	Paper,
	Select,
	Typography,
	FormControl,
	InputLabel,
} from '@mui/material';
import 'react-toastify/dist/ReactToastify.css';
import ClientSettings from './ClientSettings/ClientSettings';
import PlatformSettings from './PlatformSettings/PlatformSettings';
import SowSettings from './SowSettings/SowSettings';
import SowDetaisSettings from './SowDetaisSettings/SowDetaisSettings';
import SaleOperationRoleSettings from './SaleOperationRoleSettings/SaleOperationRoleSettings';
import BoardinStatusSettings from './BoardinStatusSettings/BoardinStatusSettings';
import SkillSettings from './SkillSettings/SkillSettings';
import LevelSettings from './LevelSettings/LevelSettings';
import SkillLevelSettings from './SkillLevelSettings/SkillLevelSettings';
import MaterialSettings from './MaterialSettings/MaterialSettings';

const SettingsModule: React.FC = () => {
	const [selectedModuleName, setSelectedModuleName] = useState<string>('');

	const moduleNames = [
		'Cliente',
		'Plataforma',
		'SOW',
		'SOW Detais',
		'Função comercial',
		'Material',
		'Status do Projeto',
		'Habilidade',
		'Habilidade / Nível',
		'Nível',
	];

	const renderModuleComponent = () => {
		switch (selectedModuleName) {
			case 'Cliente':
				return <ClientSettings />;
			case 'Plataforma':
				return <PlatformSettings />;
			case 'SOW':
				return <SowSettings />;
			case 'SOW Detais':
				return <SowDetaisSettings />;
			case 'Função comercial':
				return <SaleOperationRoleSettings />;
			case 'Status do Projeto':
				return <BoardinStatusSettings />;
			case 'Habilidade':
				return <SkillSettings />;
			case 'Habilidade / Nível':
				return <SkillLevelSettings />;
			case 'Nível':
				return <LevelSettings />;
			case 'Material':
				return <MaterialSettings />;
			default:
				return null;
		}
	};

	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<Paper elevation={5} style={{ padding: 20, marginBottom: 27 }}>
					<Typography
						variant="h5"
						component="h2"
						style={{ marginBottom: '20px' }}
					>
						Configurações
					</Typography>
					<Grid item xs={2.5}>
						<FormControl fullWidth variant="outlined">
							<InputLabel id="module-select-label">Módulo</InputLabel>
							<Select
								labelId="module-select-label"
								value={selectedModuleName}
								onChange={e => setSelectedModuleName(e.target.value)}
								label="Módulo"
							>
								{moduleNames
									.sort((a, b) => a.localeCompare(b))
									.map(module => (
										<MenuItem key={module} value={module}>
											{module}
										</MenuItem>
									))}
							</Select>
						</FormControl>
					</Grid>
				</Paper>
				<>{renderModuleComponent()}</>
			</Grid>
		</Grid>
	);
};

export default SettingsModule;
