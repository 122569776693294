import React from 'react';
import { Grid, Typography, Paper, Box } from '@mui/material';
import ProgressBarComponent from './ProgressBarComponent';

import { IOperationalProjection } from '../../../services/apiService';
import SemiCircularDonut from './SemiCircularDonut';

interface DataSummaryProps {
	operationalProjection: IOperationalProjection | undefined;
	startDate: string;
	endDate: string;
}

const SummaryBar: React.FC<DataSummaryProps> = ({
	operationalProjection,
	startDate,
	endDate,
}) => {
	const planned = operationalProjection?.data_summary.planned ?? 0;
	const scheduled = operationalProjection?.data_summary.scheduled ?? 0;

	const planProgDifference = planned - scheduled;
	const planProgPercentage = isNaN((scheduled / planned) * 100)
		? 0
		: (scheduled / planned) * 100;

	return (
		<Grid container spacing={2} style={{ marginTop: 10, marginBottom: 10 }}>
			<Grid item xs={3} style={{ maxWidth: '250px' }}>
				<Paper
					style={{
						padding: '10px',
						textAlign: 'center',
						backgroundColor: '#007EB8',
						color: 'white',
					}}
				>
					<Typography variant="h4">{planned}</Typography>
					<Typography>Planejado</Typography>
				</Paper>
			</Grid>
			<Grid item xs={3} style={{ maxWidth: '250px' }}>
				<Paper
					style={{
						padding: '10px',
						textAlign: 'center',
						backgroundColor: '#001D55',
						color: 'white',
					}}
				>
					<Typography variant="h4">{scheduled}</Typography>
					<Typography>Programado</Typography>
				</Paper>
			</Grid>
			<Grid item xs={2}>
				<Box display="flex" alignItems="center" justifyContent="center">
					<Paper elevation={0} style={{ width: '100%' }}>
						{/* <ProgressBarComponent percentage={planProgPercentage} /> */}
						<SemiCircularDonut percentage={+planProgPercentage.toFixed(2)} />
					</Paper>
				</Box>
			</Grid>
			<Grid item xs={3} style={{ maxWidth: '250px', paddingLeft: 40 }}>
				<Paper
					style={{
						padding: '10px',
						textAlign: 'center',
						backgroundColor: '#004753',
						color: 'white',
					}}
				>
					<Typography variant="h4">{planProgDifference}</Typography>
					<Typography>Falta para Programação</Typography>
				</Paper>
			</Grid>
		</Grid>
	);
};

export default SummaryBar;
