import React, { useEffect, useState } from 'react';
import AddSow from './AddSow';
import UpdateSow from './UpdateSow';
import {
	apiGetAllSow,
	IProjectScopeBackEnd,
} from '../../../services/apiService';
import { toast } from 'react-toastify';
import { MoonLoader } from 'react-spinners';

function SowSettings() {
	const [sows, setSows] = useState<IProjectScopeBackEnd[]>([]);
	const [updatedDataToggle, setUpdatedDataToggle] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const sows = await apiGetAllSow();
				setSows(sows);
			} catch (error) {
				toast.error('Erro ao carregar sows');
			} finally {
				setTimeout(() => {
					setIsLoading(false);
				}, 500);
			}
		};
		fetchData();
	}, []);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const sows = await apiGetAllSow();
				setSows(sows);
			} catch (error) {
				toast.error('Erro ao recarregar sows');
			} finally {
				setTimeout(() => {
					setIsLoading(false);
				}, 500);
			}
		};
		setIsLoading(true);
		fetchData();
	}, [updatedDataToggle]);

	return (
		<div>
			<AddSow
				updatedDataToggle={updatedDataToggle}
				setUpdatedDataToggle={setUpdatedDataToggle}
			/>
			{isLoading ? (
				<div>
					<MoonLoader color="#36d7b7" />
				</div>
			) : (
				<UpdateSow
					sows={sows}
					updatedDataToggle={updatedDataToggle}
				/>
			)}
		</div>
	);
}

export default SowSettings;
