import { Box, Grid, Paper, Typography, IconButton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Filters from '../../components/PerformanceOverview/Filters/Filters';
import {
	IPerformanceOverview,
	apiGetPerformanceOverview,
} from '../../services/apiService';
import SummaryBar from '../../components/PerformanceOverview/SummaryBar/SummaryBar';
import { getMonthBoundaryDate } from '../../utils/getMonthBoundaryDate';
import axios from 'axios';
import { toast } from 'react-toastify';
import LineChart from '../../components/PerformanceOverview/LineChart/LineChart';
import VariationSelect from '../../components/PerformanceOverview/VariationSelect/VariationSelect';
import BarChart from '../../components/PerformanceOverview/BarChart/BarChart';
import { Download } from '@mui/icons-material';
import { handleDownloadExcel } from './utils'; // Função de manipulação dos dados no utils.ts

function PerformanceOverview() {
	const [performanceOverview, setPerformanceOverview] = useState<
		IPerformanceOverview | undefined
	>(undefined);
	const [startDate, setStartDate] = useState<string>(
		getMonthBoundaryDate('first')
	);
	const [endDate, setEndDate] = useState<string>(getMonthBoundaryDate('last'));
	const [variation, setVariation] = useState<'Planejado' | 'Programado'>(
		'Planejado'
	);

	const handleVariationChange = (event: any) => {
		setVariation(event.target.value);
	};

	useEffect(() => {
		const fetchPerformanceOverview = async () => {
			try {
				const performanceOverview = await apiGetPerformanceOverview(
					startDate,
					endDate,
					'',
					'',
					'',
					'',
					'',
					''
				);
				setPerformanceOverview(performanceOverview);
			} catch (error) {
				setPerformanceOverview(undefined);
				if (axios.isAxiosError(error)) {
					if (error.response?.status !== 404) {
						toast.error('ERROR: falha ao carregar dados.');
					} else {
						toast.warning('WARNING: nenhum dado encontrado.');
					}
				} else {
					toast.error('ERROR: falha ao carregar dados.');
				}
			}
		};
		fetchPerformanceOverview();
	}, []);

	return (
		<Grid style={{ padding: 20 }}>
			<Typography variant="h5" component="h2" style={{ marginBottom: '40px' }}>
				Visão Geral da Performance da Produção
			</Typography>
			<Filters
				setPerformanceOverview={setPerformanceOverview}
				startDate={startDate}
				setStartDate={setStartDate}
				endDate={endDate}
				setEndDate={setEndDate}
			/>
			<Box display="flex" flexDirection="row">
				<Box display="flex" width="100%" maxWidth="900px" marginTop={2}>
					<Paper
						elevation={3}
						style={{
							paddingLeft: 15,
							paddingRight: 0,
							paddingBottom: 0.5,
							width: '100%',
						}}
					>
						<SummaryBar
							performanceOverview={performanceOverview}
							startDate={startDate}
							endDate={endDate}
						/>
					</Paper>
				</Box>
			</Box>
			{/* Container para o gráfico de linha e botão de download */}
			<Box position="relative" marginTop={2}>
				{/* Botão de download posicionado no canto superior direito */}
				<IconButton
					onClick={() =>
						handleDownloadExcel(performanceOverview, startDate, endDate)
					}
					style={{
						backgroundColor: '#007eb8',
						color: 'white',
						width: '30px',
						height: '30px',
						borderRadius: '4px',
						position: 'absolute',
						top: '-35px', // Ajusta a posição vertical do botão
						right: '-0px', // Ajusta a posição horizontal do botão
						zIndex: 10, // Garante que o botão fique acima do gráfico
					}}
				>
					<Download />
				</IconButton>
				{/* Gráfico de linha */}
				<Box
					padding={1}
					border={2}
					borderColor="#0B7DBB"
					borderRadius={3}
					boxShadow={3}
				>
					<LineChart data={performanceOverview} />
				</Box>
			</Box>
			<Box paddingTop={2}>
				<VariationSelect value={variation} onChange={handleVariationChange} />
			</Box>
			<Box display="flex">
				<Box border={2} borderColor="#0B7DBB" borderRadius={3} boxShadow={3}>
					<BarChart
						data={performanceOverview?.actual_percentage_clients}
						type={variation}
						title="% Realizado por Cliente"
					/>
				</Box>
				<Box
					border={2}
					marginLeft={2}
					borderColor="#0B7DBB"
					borderRadius={3}
					boxShadow={3}
				>
					<BarChart
						data={performanceOverview?.actual_percentage_coordinators}
						type={variation}
						title="% Realizado por Coordenador"
					/>
				</Box>
			</Box>
		</Grid>
	);
}

export default PerformanceOverview;
