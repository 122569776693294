import 'react-toastify/dist/ReactToastify.css';
import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {
	TextField,
	Button,
	Grid,
	MenuItem,
	Select,
	InputLabel,
	FormControl,
	Typography,
	makeStyles,
	OutlinedInput,
	Chip,
	SelectChangeEvent,
	Paper,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import {
	IBoardingStatusBackEnd,
	IWorkerBoardingBackEnd,
	apiDeleteWorkerBoarding,
	apiGetAllBoardingStatus,
	apiUpdateBoarding,
	apiUpdateWorkerBoarding,
} from '../services/apiService';
import internal from 'stream';
import axios, { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { IEditWorkerBoardingForm } from '../services/apiService';
const validationSchema = yup.object({
	workerName: yup
		.string()
		.max(255, 'O nome do operation_role deve ter no máximo 255 caracteres'),
	operationRole: yup
		.string()
		.max(255, 'O perfil do funcionário deve ter no máximo 255 caracteres'),
	scheduled_start_date: yup.date().nullable(),
	scheduled_end_date: yup.date().nullable(),
	actual_start_date: yup.date().nullable(),
	actual_end_date: yup.date().nullable(),
});

interface Props {
	workerBoarding: IWorkerBoardingBackEnd;
	onRemove: (workerBoardingId: number) => void;
}

const WorkerFormEditPlanning: React.FC<Props> = ({
	workerBoarding,
	onRemove,
}) => {
	console.log('🚀  workerBoarding:', workerBoarding);

	const formik = useFormik({
		initialValues: {
			workerName: workerBoarding.worker?.worker_name || 'A Definir',
			operationRole:
				workerBoarding.worker?.operation_role.description || 'A Definir',
			scheduled_start_date: workerBoarding.scheduled_start_date || null,
			scheduled_end_date: workerBoarding.scheduled_end_date || null,
		},
		validationSchema: validationSchema,
		onSubmit: async values => {
			console.log('🚀  values:', values);
			console.log('🚀  id:', workerBoarding.id);
			const { workerName, operationRole, ...dates } = values;
			// dates.worker_id = workerBoarding.worker_id
			// dates.
			const editData: IEditWorkerBoardingForm = {
				worker_id: workerBoarding.worker_id,
				boarding_id: workerBoarding.boarding_id,
				...dates,
			};
			console.log('🚀  editData:', editData);

			try {
				const updatedBoarding = await apiUpdateWorkerBoarding(
					workerBoarding.id,
					editData
				);
				toast.success('SUCCESS: funcionário atualizado');
				// formik.resetForm();
			} catch (error) {
				if (axios.isAxiosError(error)) {
					const axiosError = error as AxiosError;
					if (
						axiosError.response &&
						axiosError.response.data !== null &&
						typeof axiosError.response.data === 'object' &&
						'detail' in axiosError.response.data
					) {
						const errorMessage = axiosError.response.data.detail;
						console.log('Detalhe do erro:', errorMessage);
						toast.error('ERROR: ' + (errorMessage as string));
					} else {
						console.log('Erro desconhecido:', axiosError);
						toast.error('ERROR: erro desconhecido (*1)');
					}
				} else {
					console.log('Erro desconhecido:', error);
					toast.error('ERROR: erro desconhecido (*2)');
				}
			}
			console.log('🚀  editData:', editData);
			console.log('🚀  editData:', editData);
			console.log('🚀  editData:', editData);
		},
	});

	const handleKeyDown = (event: React.KeyboardEvent<HTMLFormElement>) => {
		if (event.key === 'Enter') {
			event.preventDefault();
		}
	};

	const handleDelete = async () => {
		try {
			await apiDeleteWorkerBoarding(workerBoarding.id); // Substitua pelo nome correto da sua função de API para deletar
			toast.success('Registro deletado com sucesso!');
			onRemove(workerBoarding.id);
		} catch (error) {
			toast.error('Erro ao deletar o registro.');
			// Lide com erros específicos aqui
		}
	};

	return (
		<form onSubmit={formik.handleSubmit} onKeyDown={handleKeyDown}>
			<Grid container spacing={3} direction="row">
				<Grid item>
					<TextField
						id="workerName"
						name="workerName"
						label="Nome"
						value={formik.values.workerName}
						onChange={formik.handleChange}
						error={
							formik.touched.workerName && Boolean(formik.errors.workerName)
						}
						helperText={formik.touched.workerName && formik.errors.workerName}
						disabled
					/>
				</Grid>
				<Grid item>
					<TextField
						id="operationRole"
						name="operationRole"
						label="Função (perfil)"
						value={formik.values.operationRole}
						onChange={formik.handleChange}
						error={
							formik.touched.operationRole &&
							Boolean(formik.errors.operationRole)
						}
						helperText={
							formik.touched.operationRole && formik.errors.operationRole
						}
						disabled
					/>
				</Grid>

				<Grid item>
					<TextField
						id="scheduled_start_date"
						name="scheduled_start_date"
						label="Embarque (PROG)"
						type="date"
						InputLabelProps={{
							shrink: true,
						}}
						value={formik.values.scheduled_start_date}
						onChange={formik.handleChange}
						error={
							formik.touched.scheduled_start_date &&
							Boolean(formik.errors.scheduled_start_date)
						}
						helperText={
							formik.touched.scheduled_start_date &&
							formik.errors.scheduled_start_date
						}
						style={{ width: '100%', maxWidth: '235px' }}
					/>
				</Grid>
				<Grid item>
					<TextField
						id="scheduled_end_date"
						name="scheduled_end_date"
						label="Desembarque (PROG)"
						type="date"
						InputLabelProps={{
							shrink: true,
						}}
						value={formik.values.scheduled_end_date}
						onChange={formik.handleChange}
						error={
							formik.touched.scheduled_end_date &&
							Boolean(formik.errors.scheduled_end_date)
						}
						helperText={
							formik.touched.scheduled_end_date &&
							formik.errors.scheduled_end_date
						}
						style={{ width: '100%', maxWidth: '235px' }}
					/>
				</Grid>
				<Grid item>
					<Button
						color="primary"
						variant="contained"
						type="submit"
						sx={{ mr: 2 }}
					>
						Atualizar
					</Button>
					<Button
						variant="contained"
						onClick={handleDelete}
						sx={{
							backgroundColor: 'red', // Define a cor de fundo para vermelho
							'&:hover': {
								backgroundColor: 'darkred', // Define uma cor mais escura ao passar o mouse sobre o botão
							},
							color: 'white', // Define a cor do texto para branco
						}}
					>
						Deletar
					</Button>
				</Grid>
			</Grid>
		</form>
	);
};

export default WorkerFormEditPlanning;
