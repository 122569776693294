import React, { useState } from 'react';
import ProjectPeople from '../../components/ProjectPeople';
import WorkerPeople from '../../components/WorkerPeople';
import { Grid } from '@mui/material';
import { IBoardingBackend, IWorkerBackEnd } from '../../services/apiService';

function PeoplePage() {
	const [boarding, setBoarding] = useState<IBoardingBackend | null>(null);
	const [workers, setWorkers] = useState<IWorkerBackEnd[]>([]);
	const [addedWorker, setAddedWorker] = useState<boolean>(false);
	const [selectedBoardingNumber, setSelectedBoardingNumber] = useState<
		number | ''
	>('');

	const updateBoarding = (boarding: IBoardingBackend | null) => {
		setBoarding(boarding);
	};

	return (
		<div>
			<Grid container spacing={3}>
				<ProjectPeople
					updateBoarding={updateBoarding}
					setWorkers={setWorkers}
					addedWorker={addedWorker}
					setAddedWorker={setAddedWorker}
					selectedBoardingNumber={selectedBoardingNumber}
					setSelectedBoardingNumber={setSelectedBoardingNumber}
				/>

				<WorkerPeople
					boarding={boarding}
					workers={workers}
					setWorkers={setWorkers}
					addedWorker={addedWorker}
					setAddedWorker={setAddedWorker}
					selectedBoardingNumber={selectedBoardingNumber}
				/>
			</Grid>
		</div>
	);
}

export default PeoplePage;
