import React, { useState } from 'react';
import {
	Button,
	Grid,
	MenuItem,
	Paper,
	Select,
	Typography,
	FormControl,
	InputLabel,
	TextField,
} from '@mui/material';
import { green } from '@mui/material/colors';
import {
	apiCreateSkillLevel,
	ILevel,
	ISkillLevelInput,
	ISkill,
} from '../../../services/apiService';
import { toast } from 'react-toastify';

interface AddSkillLevelProps {
	levels: ILevel[];
	skills: ISkill[];
	updatedDataToggle: boolean;
	setUpdatedDataToggle: React.Dispatch<React.SetStateAction<boolean>>;
}

function AddSkillLevel({
	levels,
	skills,
	updatedDataToggle,
	setUpdatedDataToggle,
}: AddSkillLevelProps) {
	const [newSkillLevel, setNewSkillLevel] = useState<ISkillLevelInput>({
		skillId: null,
		levelId: null,
	});

	const handleAddSkillLevel = async () => {
		if (newSkillLevel.skillId === null || newSkillLevel.levelId === null) {
			toast.warning('Os campos precisam estar preenchidos');
		} else {
			try {
				await apiCreateSkillLevel(newSkillLevel);
				setNewSkillLevel({
					skillId: null,
					levelId: null,
				});
				setUpdatedDataToggle(!updatedDataToggle);
				toast.success('Registro adicionado com sucesso');
			} catch (error) {
				toast.error('Erro ao adicionar registro');
			}
		}
	};

	return (
		<Grid item xs={12} style={{ marginBottom: 27 }}>
			<Paper elevation={5} style={{ padding: 20 }}>
				<Typography
					variant="h6"
					component="h3"
					style={{ marginBottom: '20px' }}
				>
					Nova relação "habilidade nível"
				</Typography>
				<Grid container spacing={2} alignItems="center">
					<Grid item xs={3}>
						<FormControl fullWidth variant="outlined">
							<InputLabel id="skill-select-label">Habilidade</InputLabel>
							<Select
								labelId="skill-select-label"
								value={newSkillLevel.skillId}
								onChange={e =>
									setNewSkillLevel({
										...newSkillLevel,
										skillId: Number(e.target.value),
									})
								}
								label="Habilidade"
							>
								{skills
									.sort((a, b) => a.description.localeCompare(b.description))
									.map(skill => (
										<MenuItem key={skill.id} value={skill.id}>
											{skill.description}
										</MenuItem>
									))}
							</Select>
						</FormControl>
					</Grid>
					<Grid item xs={3}>
						<FormControl fullWidth variant="outlined">
							<InputLabel id="user-select-label">Nível</InputLabel>
							<Select
								labelId="user-select-label"
								value={newSkillLevel.levelId}
								onChange={e =>
									setNewSkillLevel({
										...newSkillLevel,
										levelId: Number(e.target.value),
									})
								}
								label="Nível"
							>
								{levels
									.sort((a, b) => a.description.localeCompare(b.description))
									.map(level => (
										<MenuItem key={level.id} value={level.id}>
											{level.description}
										</MenuItem>
									))}
							</Select>
						</FormControl>
					</Grid>
					<Grid item xs={2}>
						<Button
							variant="contained"
							onClick={handleAddSkillLevel}
							sx={{
								backgroundColor: green[500], // Cor de fundo verde
								'&:hover': {
									backgroundColor: green[700], // Cor de fundo mais escura ao passar o mouse
								},
								color: 'white', // Cor do texto
							}}
						>
							<strong>Adicionar</strong>
						</Button>
					</Grid>
				</Grid>
			</Paper>
		</Grid>
	);
}

export default AddSkillLevel;
