import React, { useEffect, useState } from 'react';
import AddMaterial from './AddMaterial';
import {
	apiGetAllMaterial,
	IProjectScopeBackEnd,
	IMaterialBackEnd,
} from '../../../services/apiService';
import { toast } from 'react-toastify';
import { MoonLoader } from 'react-spinners';
import UpdateMaterial from './UpdateMaterial';

function MaterialSettings() {
	const [material, setMaterial] = useState<
		IMaterialBackEnd[]
	>([]);
	const [updatedDataToggle, setUpdatedDataToggle] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const material = await apiGetAllMaterial();
				setMaterial(material);
			} catch (error) {
				toast.error('Erro ao carregar material');
			} finally {
				setTimeout(() => {
					setIsLoading(false);
				}, 500);
			}
		};
		fetchData();
	}, []);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const material = await apiGetAllMaterial();
				setMaterial(material);
			} catch (error) {
				toast.error('Erro ao recarregar material');
			} finally {
				setTimeout(() => {
					setIsLoading(false);
				}, 500);
			}
		};
		setIsLoading(true);
		fetchData();
	}, [updatedDataToggle]);

	return (
		<div>
			<AddMaterial
				updatedDataToggle={updatedDataToggle}
				setUpdatedDataToggle={setUpdatedDataToggle}
			/>
			{isLoading ? (
				<div>
					<MoonLoader color="#36d7b7" />
				</div>
			) : (
				<UpdateMaterial
					material={material}
					updatedDataToggle={updatedDataToggle}
				/>
			)}
		</div>
	);
}

export default MaterialSettings;
