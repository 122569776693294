import React, { useState } from 'react';
import {
	Button,
	Grid,
	MenuItem,
	Paper,
	Select,
	Typography,
	FormControl,
	InputLabel,
	TextField,
} from '@mui/material';
import { green } from '@mui/material/colors';
import {
	apiCreateClient,
	IClientAdd,
	IUserBackEnd,
} from '../../../services/apiService';
import { toast } from 'react-toastify';

interface AddClientProps {
	coordinators: IUserBackEnd[];
	updatedDataToggle: boolean;
	setUpdatedDataToggle: React.Dispatch<React.SetStateAction<boolean>>;
}

function AddClient({
	coordinators,
	updatedDataToggle,
	setUpdatedDataToggle,
}: AddClientProps) {
	const [newClient, setNewClient] = useState<IClientAdd>({
		clientName: '',
		userId: null,
	});

	const handleAddClient = async () => {
		if (newClient.clientName === '' || newClient.userId === null) {
			toast.warning('Os campos precisam estar preenchidos');
		} else {
			try {
				await apiCreateClient(newClient);
				setNewClient({
					clientName: '',
					userId: null,
				});
        setUpdatedDataToggle(!updatedDataToggle)
				toast.success('Cliente adicionado com sucesso');
			} catch (error) {
				toast.error('Erro ao adicionar o cliente');
			}
		}
	};

	return (
		<Grid item xs={12} style={{ marginBottom: 27 }}>
			<Paper elevation={5} style={{ padding: 20 }}>
				<Typography
					variant="h6"
					component="h3"
					style={{ marginBottom: '20px' }}
				>
					Novo Cliente
				</Typography>
				<Grid container spacing={2} alignItems="center">
					<Grid item xs={3}>
						<TextField
							fullWidth
							label="Nome Cliente"
							value={newClient.clientName}
							onChange={e =>
								setNewClient({ ...newClient, clientName: e.target.value })
							}
							variant="outlined"
						/>
					</Grid>
					<Grid item xs={2.5}>
						<FormControl fullWidth variant="outlined">
							<InputLabel id="user-select-label">Usuário</InputLabel>
							<Select
								labelId="user-select-label"
								value={newClient.userId}
								onChange={e =>
									setNewClient({ ...newClient, userId: Number(e.target.value) })
								}
								label="Usuário"
							>
								{coordinators.map(user => (
									<MenuItem key={user.id} value={user.id}>
										{user.username}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Grid>
					<Grid item xs={2}>
						<Button
							variant="contained"
							onClick={handleAddClient}
							sx={{
								backgroundColor: green[500], // Cor de fundo verde
								'&:hover': {
									backgroundColor: green[700], // Cor de fundo mais escura ao passar o mouse
								},
								color: 'white', // Cor do texto
							}}
						>
							<strong>Adicionar</strong>
						</Button>
					</Grid>
				</Grid>
			</Paper>
		</Grid>
	);
}

export default AddClient;
