// utils.ts

import { IWorkerHistogram } from '../../services/apiService';
import * as XLSX from 'xlsx';

// Função para formatar a data no formato dd/mm/yyyy
export function formatDateToExcel(date: string): string {
	const [year, month, day] = date.split('-');
	return `${day}/${month}/${year}`;
}

// Função para formatar os dados do histograma para exportação
export function formatHistogramDataForExport(
	workerHistogram: IWorkerHistogram | undefined
) {
	if (!workerHistogram) return [];

	const { workers, days } = workerHistogram;

	// Cabeçalhos das colunas com a data formatada
	const headers = [
		'Nome',
		'Função de operação',
		...days.map(formatDateToExcel),
	];

	// Dados do histograma
	const data = workers.map(worker => [
		worker.worker_name,
		worker.operation_role,
		...worker.events,
	]);

	return [headers, ...data];
}

// Função para baixar os dados do histograma em Excel
export function downloadHistogramExcel(
	workerHistogram: IWorkerHistogram | undefined
) {
	const histogramData = formatHistogramDataForExport(workerHistogram);

	if (histogramData.length > 0) {
		const worksheet = XLSX.utils.aoa_to_sheet(histogramData);
		const workbook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(workbook, worksheet, 'Worker Histogram');
		XLSX.writeFile(workbook, 'worker_histogram.xlsx');
	} else {
		throw new Error('Nenhum dado disponível para download.');
	}
}
