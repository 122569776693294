import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
	Drawer,
	List,
	ListItemButton,
	ListItemIcon,
	ListItemText,
} from '@mui/material';
import AssignmentIcon from '@mui/icons-material/Assignment'; // Ícone para cadastro
import ScheduleIcon from '@mui/icons-material/Schedule'; // Ícone para planejamento
import PeopleIcon from '@mui/icons-material/People';
import ViewTimelineIcon from '@mui/icons-material/ViewTimeline';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import BarChartIcon from '@mui/icons-material/BarChart';
import BuildIcon from '@mui/icons-material/Build';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import SettingsIcon from '@mui/icons-material/Settings';
import { existInVisibilityRoleInLocalStorage } from '../utils/existInVisibilityRoleInLocalStorage';

const drawerWidth = 240;

const drawerStyles = {
	width: drawerWidth,
	flexShrink: 0,
	'& .MuiDrawer-paper': {
		width: drawerWidth,
		boxSizing: 'border-box',
		backgroundColor: '#f0f8ff',
		borderRight: 'none', // Remove a borda direita
		boxShadow: 'none', // Remove qualquer sombra
	},
	'& .MuiButtonBase-root': {
		margin: '8px',
		borderRadius: '15px',
		backgroundColor: '#d0e3f1',
		color: '#0d47a1',
		padding: '10px 8px',
		'&:hover': {
			backgroundColor: '#b0cfe0',
		},
		display: 'flex',
		justifyContent: 'start',
		boxShadow: '0px 2px 4px rgba(0,0,0,0.2)',
	},
	'& .MuiListItemIcon-root': {
		minWidth: 'auto',
		marginLeft: 'auto',
		color: 'inherit',
	},
};

const Sidebar = () => {
	// Estado local para controlar a visibilidade de cada item baseado no visibilityRole
	const [hideShowEditProject, setHideShowEditProject] = useState(false);
	const [hideAddBoarding, setHideAddBoarding] = useState(false);
	const [hidePeople, setHidePeople] = useState(false);
	const [hidePlanningBoarding, setHidePlanningBoarding] = useState(false);
	const [hideOperationalProjection, setHideOperationalProjection] =
		useState(false);
	const [hideHistogram, setHideHistogram] = useState(false);
	const [hidePerformance, setHidePerformance] = useState(false);
	const [hideWorkerSkills, setHideWorkerSkills] = useState(false);
	const [hideBoardingAssignment, setHideBoardingAssignment] = useState(false);
	const [hideSettings, setHideSettings] = useState(false);
	const [hideProjectRegistration, setHideProjectRegistration] = useState(false);

	useEffect(() => {
		// Verifica no localStorage para cada item
		setHideShowEditProject(
			existInVisibilityRoleInLocalStorage('hide show/edit project')
		);
		setHideAddBoarding(
			existInVisibilityRoleInLocalStorage('hide add boarding')
		);
		setHidePeople(existInVisibilityRoleInLocalStorage('hide people'));
		setHidePlanningBoarding(
			existInVisibilityRoleInLocalStorage('hide planning boarding')
		);
		setHideOperationalProjection(
			existInVisibilityRoleInLocalStorage('hide operational projection')
		);
		setHideHistogram(existInVisibilityRoleInLocalStorage('hide histogram'));
		setHidePerformance(existInVisibilityRoleInLocalStorage('hide performance'));
		setHideWorkerSkills(
			existInVisibilityRoleInLocalStorage('hide worker skills')
		);
		setHideBoardingAssignment(
			existInVisibilityRoleInLocalStorage('hide boarding assignment')
		);
		setHideSettings(existInVisibilityRoleInLocalStorage('hide settings'));
		setHideProjectRegistration(
			existInVisibilityRoleInLocalStorage('hide project registration')
		);
	}, []);

	return (
		<Drawer variant="permanent" anchor="left" sx={drawerStyles}>
			<List>
				{!hideProjectRegistration && (
					<ListItemButton component={Link} to="/project-registration">
						<ListItemText
							primary="Cadastro de Projeto"
							primaryTypographyProps={{ fontWeight: 'fontWeightMedium' }}
						/>
						<ListItemIcon>
							<AssignmentIcon fontSize="small" />
						</ListItemIcon>
					</ListItemButton>
				)}

				{/* Condicional para "Visualizar / Editar Projeto" */}
				{!hideShowEditProject && (
					<ListItemButton component={Link} to="/project-edit">
						<ListItemText
							primary="Visualizar / Editar Projeto"
							primaryTypographyProps={{ fontWeight: 'fontWeightMedium' }}
						/>
						<ListItemIcon>
							<EditIcon fontSize="small" />
						</ListItemIcon>
					</ListItemButton>
				)}
				{!hideAddBoarding && (
					<ListItemButton component={Link} to="/boarding-add">
						<ListItemText
							primary="Adicionar Embarque"
							primaryTypographyProps={{ fontWeight: 'fontWeightMedium' }}
						/>
						<ListItemIcon>
							<AddIcon fontSize="small" />
						</ListItemIcon>
					</ListItemButton>
				)}
				{/* Condicional para "Planejamento do Projeto" */}
				{!hidePlanningBoarding && (
					<ListItemButton component={Link} to="/project-planning">
						<ListItemText
							primary="Planejamento do Projeto"
							primaryTypographyProps={{ fontWeight: 'fontWeightMedium' }}
						/>
						<ListItemIcon>
							<ScheduleIcon fontSize="small" />
						</ListItemIcon>
					</ListItemButton>
				)}

				{/* Condicional para "Pessoas" */}
				{!hidePeople && (
					<ListItemButton component={Link} to="/people">
						<ListItemText
							primary="Pessoas"
							primaryTypographyProps={{ fontWeight: 'fontWeightMedium' }}
						/>
						<ListItemIcon>
							<PeopleIcon fontSize="small" />
						</ListItemIcon>
					</ListItemButton>
				)}

				{/* Condicional para "Projeção Operacional" */}
				{!hideOperationalProjection && (
					<ListItemButton component={Link} to="/operational-projection">
						<ListItemText
							primary="Projeção Operacional"
							primaryTypographyProps={{ fontWeight: 'fontWeightMedium' }}
						/>
						<ListItemIcon>
							<ViewTimelineIcon fontSize="small" />
						</ListItemIcon>
					</ListItemButton>
				)}

				{/* Condicional para "Histograma de Pessoas" */}
				{!hideHistogram && (
					<ListItemButton component={Link} to="/worker-histogram">
						<ListItemText
							primary="Histograma de Pessoas"
							primaryTypographyProps={{ fontWeight: 'fontWeightMedium' }}
						/>
						<ListItemIcon>
							<CalendarMonthIcon fontSize="small" />
						</ListItemIcon>
					</ListItemButton>
				)}

				{/* Condicional para "Visão da Performance" */}
				{!hidePerformance && (
					<ListItemButton component={Link} to="/performance-overview">
						<ListItemText
							primary="Visão da Performance"
							primaryTypographyProps={{ fontWeight: 'fontWeightMedium' }}
						/>
						<ListItemIcon>
							<BarChartIcon fontSize="small" />
						</ListItemIcon>
					</ListItemButton>
				)}

				{/* Condicional para "Habilidades Trabalhador" */}
				{!hideWorkerSkills && (
					<ListItemButton component={Link} to="/worker-skills">
						<ListItemText
							primary="Habilidades Trabalhador"
							primaryTypographyProps={{ fontWeight: 'fontWeightMedium' }}
						/>
						<ListItemIcon>
							<BuildIcon fontSize="small" />
						</ListItemIcon>
					</ListItemButton>
				)}

				{/* Condicional para "Atribuição de Embarque" */}
				{!hideBoardingAssignment && (
					<ListItemButton component={Link} to="/boarding-assignment">
						<ListItemText
							primary="Atribuição de Embarque"
							primaryTypographyProps={{ fontWeight: 'fontWeightMedium' }}
						/>
						<ListItemIcon>
							<CompareArrowsIcon fontSize="small" />
						</ListItemIcon>
					</ListItemButton>
				)}

				{/* Condicional para "Configurações" */}
				{!hideSettings && (
					<ListItemButton component={Link} to="/settings-module">
						<ListItemText
							primary="Configurações"
							primaryTypographyProps={{ fontWeight: 'fontWeightMedium' }}
						/>
						<ListItemIcon>
							<SettingsIcon fontSize="small" />
						</ListItemIcon>
					</ListItemButton>
				)}
			</List>
		</Drawer>
	);
};

export default Sidebar;
