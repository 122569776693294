import React, { useState } from 'react';
import {
	Button,
	Grid,
	MenuItem,
	Paper,
	Select,
	Typography,
	FormControl,
	InputLabel,
	TextField,
} from '@mui/material';
import { green } from '@mui/material/colors';
import {
	apiCreateProjectScope,
	apiCreateBoardingStatus,
	IProjectScopeInput,
	IBoardingStatusInput,
	IUserBackEnd,
} from '../../../services/apiService';
import { toast } from 'react-toastify';

interface AddBoardinStatus {
	updatedDataToggle: boolean;
	setUpdatedDataToggle: React.Dispatch<React.SetStateAction<boolean>>;
}

function AddBoardingStatus({
	updatedDataToggle,
	setUpdatedDataToggle,
}: AddBoardinStatus) {
	const [newBoardingStatus, setNewBoardingStatus] =
		useState<IBoardingStatusInput>({
			description: '',
		});

	const handleAddBoardingStatus = async () => {
		if (newBoardingStatus.description === '') {
			toast.warning('O campo precisa estar preenchido');
		} else {
			try {
				await apiCreateBoardingStatus(newBoardingStatus);
				setNewBoardingStatus({
					description: '',
				});
				setUpdatedDataToggle(!updatedDataToggle);
				toast.success('Função comercial adicionada com sucesso');
			} catch (error) {
				toast.error('Erro ao adicionar função comercial');
			}
		}
	};

	return (
		<Grid item xs={12} style={{ marginBottom: 27 }}>
			<Paper elevation={5} style={{ padding: 20 }}>
				<Typography
					variant="h6"
					component="h3"
					style={{ marginBottom: '20px' }}
				>
					Nova Status
				</Typography>
				<Grid container spacing={2} alignItems="center">
					<Grid item xs={3}>
						<TextField
							fullWidth
							label="Status"
							value={newBoardingStatus.description}
							onChange={e =>
								setNewBoardingStatus({
									...newBoardingStatus,
									description: e.target.value,
								})
							}
							variant="outlined"
						/>
					</Grid>
					<Grid item xs={2}>
						<Button
							variant="contained"
							onClick={handleAddBoardingStatus}
							sx={{
								backgroundColor: green[500], // Cor de fundo verde
								'&:hover': {
									backgroundColor: green[700], // Cor de fundo mais escura ao passar o mouse
								},
								color: 'white', // Cor do texto
							}}
						>
							<strong>Adicionar</strong>
						</Button>
					</Grid>
				</Grid>
			</Paper>
		</Grid>
	);
}

export default AddBoardingStatus;
