import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../features/auth/authSlice';

export type RootState = ReturnType<typeof store.getState>;

export const store = configureStore({
	reducer: {
		auth: authReducer,
		// Inclua outros reducers aqui conforme necessário
	},
	// O middleware é configurado automaticamente aqui
});
