// src/components/PrivateRoute.tsx
import React, { useEffect } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../app/store';
import { validToken } from '../utils/validToken';
import { setLoggedIn } from '../features/auth/authSlice';
import Sidebar from './Sidebar';

const PrivateRoute = () => {
	const { isLoggedIn } = useSelector((state: RootState) => state.auth);

	useEffect(() => {
		if (validToken()) {
			setLoggedIn(true);
		} else {
			setLoggedIn(false);
		}
	}, []);

	if (validToken()) {
		return (
			<div style={{ display: 'flex' }}>
				<Sidebar />
				<main style={{ flexGrow: 1, padding: '20px' }}>
					<Outlet />
				</main>
			</div>
		);
	}

	return <Navigate to="/login" />;
};

export default PrivateRoute;
