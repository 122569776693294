export function existInVisibilityRoleInLocalStorage(
	roleDescription: string
): boolean {
	// Obtendo o item 'visibilityRoles' do localStorage
	const visibilityRoles = localStorage.getItem('visibilityRoles');

	// Verificando se o item existe e se não está vazio
	if (visibilityRoles) {
		try {
			// Convertendo o item do localStorage de volta para um array
			const rolesArray = JSON.parse(visibilityRoles) as {
				description: string;
			}[];

			// Verificando se a string está presente em algum dos objetos no array
			return rolesArray.some(role => role.description === roleDescription);
		} catch (error) {
			console.error('Erro ao parsear visibilityRoles do localStorage:', error);
			return false;
		}
	}

	// Retorna false se o item não existir no localStorage
	return false;
}
