import React, { useEffect, useState } from 'react';
import AddSaleOperationRole from './AddSaleOperationRole';
import {
	apiGetAllSaleOperationRole,
	IProjectScopeBackEnd,
  ISaleOperationRoleBackEnd,
} from '../../../services/apiService';
import { toast } from 'react-toastify';
import { MoonLoader } from 'react-spinners';
import UpdateSaleOperationRole from './UpdateSaleOperationRole';

function SaleOperationRoleSettings() {
	const [saleOperationRoles, setSaleOperationRoles] = useState<
		ISaleOperationRoleBackEnd[]
	>([]);
	const [updatedDataToggle, setUpdatedDataToggle] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const sale_operation_roles = await apiGetAllSaleOperationRole();
				setSaleOperationRoles(sale_operation_roles);
			} catch (error) {
				toast.error('Erro ao carregar sale_operation_roles');
			} finally {
				setTimeout(() => {
					setIsLoading(false);
				}, 500);
			}
		};
		fetchData();
	}, []);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const sale_operation_roles = await apiGetAllSaleOperationRole();
				setSaleOperationRoles(sale_operation_roles);
			} catch (error) {
				toast.error('Erro ao recarregar sale_operation_roles');
			} finally {
				setTimeout(() => {
					setIsLoading(false);
				}, 500);
			}
		};
		setIsLoading(true);
		fetchData();
	}, [updatedDataToggle]);

	return (
		<div>
			<AddSaleOperationRole
				updatedDataToggle={updatedDataToggle}
				setUpdatedDataToggle={setUpdatedDataToggle}
			/>
			{isLoading ? (
				<div>
					<MoonLoader color="#36d7b7" />
				</div>
			) : (
				<UpdateSaleOperationRole
					saleOperationRole={saleOperationRoles}
					updatedDataToggle={updatedDataToggle}
				/>
			)}
		</div>
	);
}

export default SaleOperationRoleSettings;
