import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { IPerformanceOverview } from '../../../services/apiService';

interface PerformanceChartProps {
	data: IPerformanceOverview | undefined;
}

const LineChart: React.FC<PerformanceChartProps> = ({ data }) => {
	const chartRef = useRef<HTMLCanvasElement>(null);

	useEffect(() => {
		if (chartRef.current && data) {
			const ctx = chartRef.current.getContext('2d');
			if (ctx) {
				const chart = new Chart(ctx, {
					type: 'line',
					data: {
						labels: data.days.map(day => {
							const date = new Date(day + 'T00:00:00'); // Assegura que a data é tratada corretamente
							return date.toLocaleDateString('pt-BR', {
								day: '2-digit',
								month: '2-digit',
							});
						}),
						datasets: data.daily_sum.map(item => ({
							label: item.situation,
							data: item.number_of_workers,
							borderColor: getLineColor(item.situation),
							backgroundColor: getLineColor(item.situation), // Cor de fundo igual a cor da borda
							fill: false,
							cubicInterpolationMode: 'monotone',
							pointBackgroundColor: getLineColor(item.situation),
							pointBorderColor: getLineColor(item.situation), // Cor do contorno do ponto igual à cor da linha
							pointBorderWidth: 0, // Nenhum contorno no ponto
							pointRadius: 5,
						})),
					},
					options: {
						responsive: true,
						maintainAspectRatio: false,
						scales: {
							x: {
								display: true,
								grid: {
									display: false,
								},
								ticks: {
									padding: 12, // Aumenta a distância dos labels em relação ao eixo x
								},
							},
							y: {
								display: false,
								grid: {
									display: false,
								},
							},
						},
						plugins: {
							tooltip: {
								enabled: true,
							},
							datalabels: {
								anchor: function (context) {
									return context.dataset.label === 'Planejado'
										? 'center'
										: 'end';
								},
								align: function (context) {
									return context.dataset.label === 'Planejado'
										? 'start'
										: 'end';
								},
								color: function (context) {
									return context.dataset.borderColor as string;
								},
								font: {
									weight: 'bold',
								},
								formatter: function (value, context) {
									return `${value}`;
								},
								offset: 4,
							},
							legend: {
								labels: {
									usePointStyle: true,
									pointStyle: 'rectRounded',
								},
							},
						},
					},
					plugins: [ChartDataLabels],
				});

				return () => {
					chart.destroy();
				};
			}
		}
	}, [data]);

	const getLineColor = (label: string): string => {
		switch (label) {
			case 'Planejado':
				return '#007EB8';
			case 'Programado':
				return '#001D55';
			case 'Realizado':
				return '#CCAA14';
			default:
				return '#000000';
		}
	};

	return (
		<div style={{ height: '400px' }}>
			<canvas ref={chartRef} />
		</div>
	);
};

export default LineChart;
