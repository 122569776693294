import React from 'react';
import { Grid, TextField } from '@mui/material';
import { IDrakeWorkerBoardingBackEnd } from '../services/apiService';

interface Props {
	drakeWorkerBoarding: IDrakeWorkerBoardingBackEnd;
}

const DrakeWorkerEditPlanning: React.FC<Props> = ({ drakeWorkerBoarding }) => {
	return (
		<Grid container spacing={3} direction="row">
			<Grid item>
				<TextField
					id="workerName"
					label="Nome"
					value={drakeWorkerBoarding.worker?.worker_name || 'N/A'}
					disabled
					variant="outlined"
					fullWidth
				/>
			</Grid>
			<Grid item>
				<TextField
					id="operationRole"
					label="Função (perfil)"
					value={
						drakeWorkerBoarding.worker?.operation_role.description || 'N/A'
					}
					disabled
					variant="outlined"
					fullWidth
				/>
			</Grid>
			<Grid item>
				<TextField
					id="actual_start_date"
					label="Embarque (REAL)"
					type="date"
					InputLabelProps={{
						shrink: true,
					}}
					value={drakeWorkerBoarding.actual_start_date || ''}
					disabled
					variant="outlined"
					fullWidth
				/>
			</Grid>
			<Grid item>
				<TextField
					id="actual_end_date"
					label="Desembarque (REAL)"
					type="date"
					InputLabelProps={{
						shrink: true,
					}}
					value={drakeWorkerBoarding.actual_end_date || ''}
					disabled
					variant="outlined"
					fullWidth
				/>
			</Grid>
		</Grid>
	);
};

export default DrakeWorkerEditPlanning;
