import { Grid } from '@mui/material';
import React, { useState } from 'react';
import DrakeWorkerBoardingAssignment from '../../components/BoardingAssignment/DrakeWorkerBoardingAssignment';
import BoardingAssignment from '../../components/BoardingAssignment/BoardingAssignment';

function BoardingAssignmentPage() {
	const [selectedCardDrakeWorkerBoarding, setSelectedCardDrakeWorkerBoarding] =
		useState<number | null>(null);
	const [selectedCardBoarding, setSelectedCardBoarding] = useState<
		number | null
	>(null);
	const [clickAssign, setClickAssign] = useState<boolean>(false);

	return (
		<div style={{ height: '100vh' }}>
			<Grid container direction="row" spacing={3} style={{ height: '100%' }}>
				<Grid item xs={6} style={{ height: '100%' }}>
					<DrakeWorkerBoardingAssignment
						selectedCard={selectedCardDrakeWorkerBoarding}
						setSelectedCard={setSelectedCardDrakeWorkerBoarding}
						selectedCardBoarding={selectedCardBoarding}
						setSelectedCardBoarding={setSelectedCardBoarding}
						clickAssign={clickAssign}
            setClickAssign={setClickAssign}
					/>
				</Grid>
				<Grid item xs={6} style={{ height: '100%' }}>
					<BoardingAssignment
						selectedCard={selectedCardBoarding}
						setSelectedCard={setSelectedCardBoarding}
						clickAssign={clickAssign}
					/>
				</Grid>
			</Grid>
		</div>
	);
}

export default BoardingAssignmentPage;
