import React, { useState } from 'react';
import {
	Button,
	Grid,
	MenuItem,
	Paper,
	Select,
	Typography,
	FormControl,
	InputLabel,
	TextField,
} from '@mui/material';
import { green } from '@mui/material/colors';
import {
	apiCreatePlatform,
	IClientBackEnd,
	IPlatformInput,
	IUserBackEnd,
} from '../../../services/apiService';
import { toast } from 'react-toastify';

interface AddPlatformProps {
	clients: IClientBackEnd[];
	updatedDataToggle: boolean;
	setUpdatedDataToggle: React.Dispatch<React.SetStateAction<boolean>>;
}

function AddPlatform({
	clients,
	updatedDataToggle,
	setUpdatedDataToggle,
}: AddPlatformProps) {
	const [newPlatform, setNewPlatform] = useState<IPlatformInput>({
		platformName: '',
		platformCode: '',
		clientId: null,
	});

	const handleAddPlatform = async () => {
		if (newPlatform.platformName === '' || newPlatform.clientId === null) {
			toast.warning('Os campos precisam estar preenchidos');
		} else {
			try {
				await apiCreatePlatform(newPlatform);
				setNewPlatform({
					platformName: '',
					platformCode: '',
					clientId: null,
				});
				setUpdatedDataToggle(!updatedDataToggle);
				toast.success('Plataforma adicionado com sucesso');
			} catch (error) {
				toast.error('Erro ao adicionar o Plataforma');
			}
		}
	};

	return (
		<Grid item xs={12} style={{ marginBottom: 27 }}>
			<Paper elevation={5} style={{ padding: 20 }}>
				<Typography
					variant="h6"
					component="h3"
					style={{ marginBottom: '20px' }}
				>
					Nova Plataforma
				</Typography>
				<Grid container spacing={2} alignItems="center">
					<Grid item xs={4}>
						<TextField
							fullWidth
							label="Nome Plataforma"
							value={newPlatform.platformName}
							onChange={e =>
								setNewPlatform({ ...newPlatform, platformName: e.target.value })
							}
							variant="outlined"
						/>
					</Grid>
					<Grid item xs={2}>
						<TextField
							fullWidth
							label="Código Plataforma"
							value={newPlatform.platformCode}
							onChange={e =>
								setNewPlatform({ ...newPlatform, platformCode: e.target.value })
							}
							variant="outlined"
						/>
					</Grid>
					<Grid item xs={3}>
						<FormControl fullWidth variant="outlined">
							<InputLabel id="user-select-label">Cliente</InputLabel>
							<Select
								labelId="user-select-label"
								value={newPlatform.clientId}
								onChange={e =>
									setNewPlatform({
										...newPlatform,
										clientId: Number(e.target.value),
									})
								}
								label="Cliente"
							>
								{clients
									.sort((a, b) => a.client_name.localeCompare(b.client_name))
									.map(client => (
										<MenuItem key={client.id} value={client.id}>
											{client.client_name}
										</MenuItem>
									))}
							</Select>
						</FormControl>
					</Grid>
					<Grid item xs={2}>
						<Button
							variant="contained"
							onClick={handleAddPlatform}
							sx={{
								backgroundColor: green[500], // Cor de fundo verde
								'&:hover': {
									backgroundColor: green[700], // Cor de fundo mais escura ao passar o mouse
								},
								color: 'white', // Cor do texto
							}}
						>
							<strong>Adicionar</strong>
						</Button>
					</Grid>
				</Grid>
			</Paper>
		</Grid>
	);
}

export default AddPlatform;
