import 'react-toastify/dist/ReactToastify.css';
import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {
	TextField,
	Button,
	Grid,
	MenuItem,
	Select,
	InputLabel,
	FormControl,
	Typography,
	makeStyles,
	OutlinedInput,
	Chip,
	SelectChangeEvent,
	Paper,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import {
	apiCreateProject,
	apiGetAllBoardingStatus,
	apiGetAllContractCategory,
	apiGetAllEmbarkationRegime,
	apiGetAllMaterial,
	apiGetAllPlatformsClients,
	apiGetAllProjectClassification,
	apiGetAllProjectScope,
	apiGetAllProjectScopeDetais,
	apiGetAllShift,
	apiGetAllUsers,
	apiGetAllWorker,
	apiGetLastProjectNumber,
	apiGetSaleOperationRole,
	IBoardingStatusBackEnd,
	IClientBackEnd,
	IContractCategoryBackEnd,
	IEmbarkationRegimeBackEnd,
	IMaterialBackEnd,
	IOperationRoleBackEnd,
	IPlatformBackEnd,
	IProjectClassificationBackEnd,
	IProjectFormBackend,
	IProjectScopeBackEnd,
	IProjectScopeDetaisBackEnd,
	ISaleOperationRoleBackEnd,
	IUserBackEnd,
	IWorkerBackEnd,
} from '../../services/apiService';
import internal from 'stream';
import { removeDuplicatesByIdUtil } from '../../utils/clientsBackEnd';
import axios, { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { green } from '@mui/material/colors';

export default function ProjectRegistrationPage() {
	const [dropdownClient, setDropdownClient] = useState<IClientBackEnd[]>([]);
	const [dropdownPlatform, setDropdownPlatform] = useState<IPlatformBackEnd[]>(
		[]
	);
	const [dropdownUser, setDropdownUser] = useState<IUserBackEnd[]>([]);
	const [dropdownUserFiltered, setDropdownUserFiltered] = useState<
		IUserBackEnd[]
	>([]);
	const [dropdownContractCategory, setDropdownContractCategory] = useState<
		IContractCategoryBackEnd[]
	>([]);
	const [dropdownShift, setDropdownShift] = useState<
		IContractCategoryBackEnd[]
	>([]);
	const [dropdownProjectClassification, setDropdownProjectClassification] =
		useState<IProjectClassificationBackEnd[]>([]);
	const [dropdownProjectScope, setDropdownProjectScope] = useState<
		IProjectScopeBackEnd[]
	>([]);
	const [dropdownProjectScopeDetais, setDropdownProjectScopeDetais] = useState<
		IProjectScopeDetaisBackEnd[]
	>([]);
	const [selectedClientId, setSelectedClientId] = useState<number>(0);
	const [selectedProjectScopeId, setSelectedProjectScopeId] =
		useState<number>(0);
	const [filteredPlatforms, setFilteredPlatforms] = useState<
		IPlatformBackEnd[]
	>([]);
	// const [filteredScopeDetais, setFilteredScopeDetais] = useState<
	// 	IProjectScopeDetaisBackEnd[]
	// >([]);
	const [dropdownSaleOperationRole, setDropdownSaleOperationRole] = useState<
		ISaleOperationRoleBackEnd[]
	>([]);
	const [materialMultipleSelectOptions, setMaterialMultipleSelectOptions] =
		useState<IMaterialBackEnd[]>([]);
	const [dropdownEmbarkationRegime, setDropdownEmbarkationRegime] = useState<
		IEmbarkationRegimeBackEnd[]
	>([]);
	const [dropdownBoardingStatus, setDropdownBoardingStatus] = useState<
		IBoardingStatusBackEnd[]
	>([]);
	const [teamSize, setTeamSize] = useState<number>(0);
	const [
		selectedSaleOperationRoleIdsOptions,
		setSaleSelectedOperationRoleIdsOptions,
	] = useState<number[]>([]);
	const [lastProjectNumber, setLastProjectNumber] = useState<number>(0);
	const [saleOperationRoleDropdowns, setSaleOperationRoleDropdowns] = useState<
		number[]
	>([]);
	const [scopeCount, setScopeCount] = useState<number>(0);
	const [projectScopeDropdowns, setProjectScopeDropdowns] = useState<number[]>(
		[]
	);
	const [filteredScopeDetais, setFilteredScopeDetais] = useState<
		IProjectScopeDetaisBackEnd[][]
	>([]);
	const [materialCount, setMaterialCount] = useState<number>(0);
	const [materialDropdowns, setMaterialDropdowns] = useState<number[]>([]);

	const handleMaterialCountChange = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		const count = parseInt(event.target.value, 10);
		setMaterialCount(count);
		formik.setFieldValue('materialCount', count);
	};

	useEffect(() => {
		const updatedDropdowns = Array.from(
			{ length: materialCount },
			(_, index) => index
		);
		setMaterialDropdowns(updatedDropdowns);
	}, [materialCount]);

	const handleScopeCountChange = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		const count = parseInt(event.target.value, 10);
		setScopeCount(count);
		formik.setFieldValue('scopeCount', count);

		const updatedDropdowns = Array.from({ length: count }, (_, index) => index);
		setProjectScopeDropdowns(updatedDropdowns);
	};

	const handleTeamSizeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const size = parseInt(event.target.value, 10);
		setTeamSize(size);
		formik.setFieldValue('teamSize', size);

		const updatedDropdowns = Array.from({ length: size }, (_, index) => index);
		setSaleOperationRoleDropdowns(updatedDropdowns);
	};

	useEffect(() => {
		const fetchDropdownData = async () => {
			try {
				const lastProjectNumber = await apiGetLastProjectNumber();
				setLastProjectNumber(lastProjectNumber.project_number + 1);
				formik.setFieldValue(
					'projectNumber',
					lastProjectNumber.project_number + 1
				);
			} catch (error) {
				console.log('🚀  error:', error);
				toast.error('ERROR: falha ao carregar o número do último projeto.');
			}

			try {
				const backEndAllPlatformsClients = await apiGetAllPlatformsClients();
				const clients = backEndAllPlatformsClients.map(obj => obj.client);
				const clientsUnique = removeDuplicatesByIdUtil(clients);
				setDropdownClient(clientsUnique);
				setDropdownPlatform(backEndAllPlatformsClients);
			} catch (error) {
				console.log('🚀  error:', error);
				toast.error(
					'ERROR: falha ao carregar os dados de clientes e plataformas.'
				);
			}

			try {
				const backEndAllUser = await apiGetAllUsers();
				setDropdownUser(backEndAllUser);
			} catch (error) {
				console.log('🚀  error:', error);
				toast.error('ERROR: falha ao carregar os dados de coordenadores.');
			}

			try {
				const backEndAllContractCategory = await apiGetAllContractCategory();
				setDropdownContractCategory(backEndAllContractCategory);
			} catch (error) {
				console.log('🚀  error:', error);
				toast.error(
					'ERROR: falha ao carregar os dados de categorias de contrato.'
				);
			}

			try {
				const backEndAllShift = await apiGetAllShift();
				setDropdownShift(backEndAllShift);
			} catch (error) {
				console.log('🚀  error:', error);
				toast.error('ERROR: falha ao carregar os dados de turnos.');
			}

			try {
				const backEndAllProjectClassification =
					await apiGetAllProjectClassification();
				setDropdownProjectClassification(backEndAllProjectClassification);
			} catch (error) {
				console.log('🚀  error:', error);
				toast.error(
					'ERROR: falha ao carregar os dados de classificação de projeto.'
				);
			}

			try {
				const backEndAllProjectScope = await apiGetAllProjectScope();
				setDropdownProjectScope(backEndAllProjectScope);
			} catch (error) {
				console.log('🚀  error:', error);
				toast.error('ERROR: falha ao carregar os dados de escopo de projeto.');
			}

			try {
				const backEndAllProjectScopeDetais =
					await apiGetAllProjectScopeDetais();
				setDropdownProjectScopeDetais(backEndAllProjectScopeDetais);
			} catch (error) {
				console.log('🚀  error:', error);
				toast.error(
					'ERROR: falha ao carregar os dados de escopo de projeto (SOW detais).'
				);
			}

			try {
				// eslint-disable-next-line @typescript-eslint/no-unused-vars
				const backEndAllSaleOperationRole = await apiGetSaleOperationRole();
				setDropdownSaleOperationRole(backEndAllSaleOperationRole);
			} catch (error) {
				console.log('🚀  error:', error);
				toast.error(
					'ERROR: falha ao carregar os dados de perfil dos funcionários.'
				);
			}

			try {
				const backEndAllMaterial = await apiGetAllMaterial();
				setMaterialMultipleSelectOptions(backEndAllMaterial);
			} catch (error) {
				console.log('🚀  error:', error);
				toast.error('ERROR: falha ao carregar os dados de materiais.');
			}

			try {
				const backEndAllEmbarkationRegime = await apiGetAllEmbarkationRegime();
				setDropdownEmbarkationRegime(backEndAllEmbarkationRegime);
			} catch (error) {
				console.log('🚀  error:', error);
				toast.error('ERROR: falha ao carregar os dados de regime de embarque.');
			}

			try {
				const backEndAllBoardingStatus = await apiGetAllBoardingStatus();
				setDropdownBoardingStatus(backEndAllBoardingStatus);
			} catch (error) {
				console.log('🚀  error:', error);
				toast.error('ERROR: falha ao carregar os dados de status do embarque.');
			}
		};

		fetchDropdownData();
	}, []);

	useEffect(() => {
		if (selectedClientId) {
			const filteredPlatform = dropdownPlatform.filter(
				platform => platform.client.id === selectedClientId
			);
			setFilteredPlatforms(filteredPlatform);

			const userIds = filteredPlatform.map(item => item.client.user_id);
			const filteredUser = dropdownUser.filter(user =>
				userIds.includes(user.id)
			);
			setDropdownUserFiltered(filteredUser);
		} else {
			setFilteredPlatforms([]);
			setDropdownUserFiltered([]);
		}
	}, [selectedClientId]);

	// useEffect(() => {
	// 	console.log('🚀  selectedProjectScopeId:', selectedProjectScopeId);
	// 	if (selectedProjectScopeId) {
	// 		const filtered = dropdownProjectScopeDetais.filter(
	// 			scopeDetais => scopeDetais.project_scope_id === selectedProjectScopeId
	// 		);
	// 		setFilteredScopeDetais(filtered);
	// 	} else {
	// 		setFilteredScopeDetais([]);
	// 	}
	// }, [selectedProjectScopeId]);

	const getValidationSchema = () => {
		return yup.object({
			projectNumber: yup
				.number()
				.required('O número do projeto é obrigatório')
				.integer()
				.positive(),
			purchaseOrder: yup
				.string()
				.required('A PO é obrigatório')
				.max(1000, 'Este campo pode ter no máximo 1000 caracteres'),
			proposal: yup
				.string()
				.required('A proposta é obrigatório')
				.max(1000, 'Este campo pode ter no máximo 1000 caracteres'),
			expectedEmbarkationDate: yup
				.date()
				.required('A data de embarcação esperada é obrigatória'),
			projectName: yup
				.string()
				.required('O nome do projeto é obrigatório')
				.max(255, 'O nome do projeto deve ter no máximo 255 caracteres'),
			clientId: yup.string().required('O cliente é obrigatório'),
			platformId: yup.string().required('A plataforma é obrigatória'),
			userId: yup.string().required('O coordenador é obrigatório'),
			projectClassificationId: yup
				.string()
				.required('A classificação do projeto é obrigatória'),
			contractCategoryId: yup
				.string()
				.required('A categoria do projeto é obrigatória'),
			shiftId: yup.string().required('O turno do projeto é obrigatório'),
			// projectScopeId: yup
			// 	.string()
			// 	.required('O escopo do projeto (SOW) é obrigatório'),
			// projectScopeDetaisId: yup
			// 	.string()
			// 	.required('O escopo do projeto (SOW Detais) é obrigatório'),
			scopeObservations: yup
				.string()
				.max(1000, 'Este campo pode ter no máximo 1000 caracteres'),
			teamQuantity: yup
				.number()
				.required('A quantidade de equipes é obrigatório')
				.integer()
				.positive(),
			teamSize: yup
				.number()
				.required('O número de pessoas na equipe obrigatório')
				.integer()
				.positive(),
			saleOperationRoleIds: yup
				.array()
				.of(yup.string().required('Seleção obrigatória'))
				.min(1, 'Pelo menos uma opção deve ser selecionada'),
			materialIds: yup
				.array()
				.of(yup.string().required('Seleção obrigatória'))
				.min(1, 'Pelo menos uma opção deve ser selecionada'),
			customerExpectation: yup
				.string()
				.max(1000, 'Este campo pode ter no máximo 1000 caracteres'),
			embarkationPeriod: yup
				.number()
				.when('embarkationRegime', (embarkationRegime, schema) => {
					const isDisabled = handleDisableEmbarkationPeriod();
					return !isDisabled
						? schema
								.required('O período de embarque é obrigatório')
								.integer()
								.positive()
						: schema.nullable();
				}),

			embarkationRegime: yup
				.string()
				.required('O regime de embarque é obrigatória'),

			numberOfBoarding: yup
				.number()
				.required('A quantidade de embarque é obrigatório')
				.integer()
				.positive(),

			observation: yup
				.string()
				.max(1000, 'Este campo pode ter no máximo 1000 caracteres'),

			boardingStatus: yup
				.string()
				.required('O status do projeto é obrigatória'),

			projectScopeIds: yup
				.array()
				.of(
					yup.object({
						projectScopeId: yup.number().required('Escopo SOW é obrigatório.'),
						projectScopeDetaisId: yup
							.number()
							.required('Escopo SOW Details é obrigatório.'),
					})
				)
				.required(),

			// projectScopeIds: yup.array().of(
			// 	yup.object({
			// 		projectScopeId: yup.number(),
			// 		projectScopeDetaisId: yup.number(),
			// 	})
			// ),
			materialCount: yup
				.number()
				.required('A quantidade de materiais é obrigatória')
				.integer()
				.positive(),
			materialSelections: yup.array().of(
				yup.object({
					materialId: yup.string().required('Seleção de material obrigatória'),
					quantity: yup
						.number()
						.required('Quantidade é obrigatória')
						.integer()
						.positive(),
				})
			),
		});
	};

	const formik = useFormik({
		initialValues: {
			projectNumber: lastProjectNumber || '',
			projectName: '',
			userId: '',
			clientId: '',
			platformId: '',
			purchaseOrder: '',
			proposal: '',
			projectClassificationId: '',
			contractCategoryId: '',
			scopeObservations: '',
			teamQuantity: '',
			teamSize: '',
			shiftId: '',
			customerExpectation: '',
			expectedEmbarkationDate: '',
			embarkationPeriod: null, // Alterado de null para ''
			embarkationRegime: '',
			numberOfBoarding: '',
			observation: '',
			boardingStatus: '',
			saleOperationRoleIds: [],
			projectScopeIds: [{ projectScopeId: '', projectScopeDetaisId: '' }],
			scopeCount: 0,
			materialCount: 0,
			materialSelections: [{ materialId: '', quantity: '' }],
		},
		validationSchema: getValidationSchema(),
		// onSubmit: async (values: IProjectFormBackend) => {
		onSubmit: async values => {
			// Removendo `scopeCount` dos valores antes de enviar para o backend
			// Removendo `scopeCount` e `materialCount` dos valores antes de enviar para o backend
			const {
				scopeCount,
				materialCount,
				materialSelections,
				...backendValues
			} = values as any;

			// Renomeando `materialSelections` para `materials`
			const transformedValues = {
				...backendValues,
				materials: materialSelections,
			};
			console.log('🚀  values:', values);
			setSaleOperationRoleDropdowns([]);

			try {
				console.log(backendValues);
				const newProject = await apiCreateProject(transformedValues);
				toast.success('SUCCESS: projeto criado');
				formik.resetForm();
				setSaleSelectedOperationRoleIdsOptions([]);
				setProjectScopeDropdowns([]);
				try {
					const lastProjectNumber = await apiGetLastProjectNumber();
					setLastProjectNumber(lastProjectNumber.project_number + 1);
					formik.setFieldValue(
						'projectNumber',
						lastProjectNumber.project_number + 1
					);
				} catch (error) {
					console.log('🚀  error:', error);
					toast.error('ERROR: falha ao carregar o número do último projeto.');
				}
			} catch (error) {
				if (axios.isAxiosError(error)) {
					const axiosError = error as AxiosError;
					if (
						axiosError.response &&
						axiosError.response.data !== null &&
						typeof axiosError.response.data === 'object' &&
						'detail' in axiosError.response.data
					) {
						const errorMessage = axiosError.response.data.detail;
						console.log('Detalhe do erro:', errorMessage);
						toast.error('ERROR: ' + (errorMessage as string));
					} else {
						console.log('Erro desconhecido:', axiosError);
						toast.error('ERROR: erro desconhecido (*1)');
					}
				} else {
					console.log('Erro desconhecido:', error);
					toast.error('ERROR: erro desconhecido (*2)');
				}
			}
		},
	});

	// Efeito para atualizar os detalhes filtrados sempre que o escopo é selecionado
	useEffect(() => {
		const newFilteredScopeDetais = formik.values.projectScopeIds.map(
			scopeIdObj => {
				return dropdownProjectScopeDetais.filter(
					detail =>
						detail.project_scope_id === Number(scopeIdObj.projectScopeId)
				);
			}
		);
		setFilteredScopeDetais(newFilteredScopeDetais);
	}, [formik.values.projectScopeIds]);

	useEffect(() => {
		formik.setFieldValue('embarkationPeriod', null);
	}, [formik.values.embarkationRegime]);

	const handleKeyDown = (event: React.KeyboardEvent<HTMLFormElement>) => {
		if (event.key === 'Enter') {
			event.preventDefault();
		}
	};

	const handleDisableEmbarkationPeriod = (): boolean => {
		if (formik.values.embarkationRegime === '') {
			return true;
		}
		return (
			dropdownEmbarkationRegime.filter(
				regime => regime.id === +formik.values.embarkationRegime
			)[0]?.description !== 'personalizado (dias)'
		);
	};

	console.log('Formik errors:', formik.errors);

	return (
		<Paper elevation={5} style={{ padding: 20 }}>
			<Typography variant="h5" component="h2" style={{ marginBottom: '20px' }}>
				Cadastro do Projeto
			</Typography>
			<form onSubmit={formik.handleSubmit} onKeyDown={handleKeyDown}>
				{/* <form onSubmit={formik.handleSubmit}> */}
				<Grid container spacing={3} direction="column">
					<Grid item container direction="row" spacing={3}>
						<Grid item xs={12} sm={4}>
							<TextField
								id="projectNumber"
								name="projectNumber"
								label="Número do Projeto"
								type="number"
								InputProps={{ inputProps: { min: 1 } }}
								value={formik.values.projectNumber || ''}
								onChange={formik.handleChange}
								error={
									formik.touched.projectNumber &&
									Boolean(formik.errors.projectNumber)
								}
								helperText={
									formik.touched.projectNumber && formik.errors.projectNumber
								}
							/>
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<TextField
							fullWidth
							id="purchaseOrder"
							name="purchaseOrder"
							label="PO"
							multiline
							rows={4}
							inputProps={{ maxLength: 1000 }}
							value={formik.values.purchaseOrder}
							onChange={formik.handleChange}
							error={
								formik.touched.purchaseOrder &&
								Boolean(formik.errors.purchaseOrder)
							}
							helperText={
								formik.touched.purchaseOrder && formik.errors.purchaseOrder
							}
							style={{ width: '100%', maxWidth: '1000px' }}
						/>
					</Grid>
					<Grid item container direction="row" spacing={3}>
						<Grid item xs={12} sm={4}>
							<TextField
								id="projectName"
								name="projectName"
								label="Nome do Projeto"
								value={formik.values.projectName}
								onChange={formik.handleChange}
								error={
									formik.touched.projectName &&
									Boolean(formik.errors.projectName)
								}
								helperText={
									formik.touched.projectName && formik.errors.projectName
								}
							/>
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<TextField
							fullWidth
							id="proposal"
							name="proposal"
							label="Proposta"
							multiline
							rows={4}
							inputProps={{ maxLength: 1000 }}
							value={formik.values.proposal}
							onChange={formik.handleChange}
							error={formik.touched.proposal && Boolean(formik.errors.proposal)}
							helperText={formik.touched.proposal && formik.errors.proposal}
							style={{ width: '100%', maxWidth: '1000px' }}
						/>
					</Grid>
					<Grid item xs={12} sm={4}>
						<FormControl fullWidth>
							<InputLabel id="client-id-label">Cliente</InputLabel>
							<Select
								labelId="client-id-label"
								id="clientId"
								name="clientId"
								value={formik.values.clientId}
								onChange={e => {
									formik.setFieldValue('platformId', '');
									formik.handleChange(e);
									setSelectedClientId(+e.target.value);
								}}
								error={
									formik.touched.clientId && Boolean(formik.errors.clientId)
								}
								label="Cliente"
								style={{ width: '235px' }}
							>
								{dropdownClient
									.sort((a, b) => a.client_name.localeCompare(b.client_name))
									.map(client => (
										<MenuItem key={client.id} value={client.id}>
											{client.client_name}
										</MenuItem>
									))}
							</Select>
						</FormControl>
					</Grid>
					<Grid item container direction="row" spacing={3}>
						<Grid item xs={12} sm={4}>
							<FormControl fullWidth>
								<InputLabel id="platform-id-label">Plataforma</InputLabel>
								<Select
									labelId="platform-id-label"
									id="platformId"
									name="platformId"
									value={formik.values.platformId}
									onChange={formik.handleChange}
									error={
										formik.touched.platformId &&
										Boolean(formik.errors.platformId)
									}
									label="Plataforma"
									style={{ width: '100%', maxWidth: '235px' }}
								>
									{filteredPlatforms
										.sort((a, b) =>
											a.platform_name.localeCompare(b.platform_name)
										)
										.map(platform => (
											<MenuItem key={platform.id} value={platform.id}>
												{platform.platform_name}
											</MenuItem>
										))}
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={12} sm={6}>
							<FormControl fullWidth>
								<InputLabel id="contract-category-id-label">
									Categoria do Contrato
								</InputLabel>
								<Select
									labelId="contract-category-id-label"
									id="contractCategoryId"
									name="contractCategoryId"
									value={formik.values.contractCategoryId}
									onChange={formik.handleChange}
									error={
										formik.touched.contractCategoryId &&
										Boolean(formik.errors.contractCategoryId)
									}
									label="Categoria do Contrato"
									style={{ width: '100%', maxWidth: '235px' }}
								>
									{dropdownContractCategory
										.sort((a, b) => a.description.localeCompare(b.description))
										.map(contract_category => (
											<MenuItem
												key={contract_category.id}
												value={contract_category.id}
											>
												{contract_category.description}
											</MenuItem>
										))}
								</Select>
							</FormControl>
						</Grid>
					</Grid>
					<Grid item xs={12} sm={6}>
						<FormControl fullWidth>
							<InputLabel id="userId-label">Coordenador responsável</InputLabel>
							<Select
								labelId="userId-label"
								id="userId"
								name="userId"
								value={formik.values.userId}
								onChange={formik.handleChange}
								error={formik.touched.userId && Boolean(formik.errors.userId)}
								label="Coordenador responsável"
								style={{ width: '235px' }}
							>
								{dropdownUserFiltered
									.filter(user => user.coordinator)
									.map(coordinator => (
										<MenuItem key={coordinator.id} value={coordinator.id}>
											{coordinator.username}
										</MenuItem>
									))}
							</Select>
						</FormControl>
					</Grid>
					<Grid item xs={12} sm={4}>
						<TextField
							id="scopeCount"
							name="scopeCount"
							label="Quantidade de escopos"
							type="number"
							InputProps={{ inputProps: { min: 1 } }}
							value={formik.values.scopeCount}
							onChange={handleScopeCountChange}
							error={
								formik.touched.scopeCount && Boolean(formik.errors.scopeCount)
							}
							helperText={formik.touched.scopeCount && formik.errors.scopeCount}
							style={{ width: '100%', maxWidth: '250px' }}
						/>
					</Grid>
					{projectScopeDropdowns.map((_, index) => (
						<Grid item xs={12} key={index}>
							<Grid container spacing={3}>
								<Grid item xs={12} sm={4}>
									<FormControl fullWidth>
										<InputLabel id={`project-scope-${index}-label`}>
											Escopo do Projeto (SOW) {index + 1}
										</InputLabel>
										<Select
											labelId={`project-scope-${index}-label`}
											id={`projectScopeIds[${index}].projectScopeId`}
											name={`projectScopeIds[${index}].projectScopeId`}
											value={
												formik.values.projectScopeIds[index]?.projectScopeId ||
												''
											}
											onChange={formik.handleChange}
											error={
												formik.touched.projectScopeIds?.[index]
													?.projectScopeId &&
												Boolean(
													formik.errors.projectScopeIds?.[index] &&
														typeof formik.errors.projectScopeIds[index] ===
															'object' &&
														(formik.errors.projectScopeIds[index] as any)
															.projectScopeId
												)
											}
											label={`Escopo do Projeto (SOW) ${index + 1}`}
										>
											{dropdownProjectScope.map(project_scope => (
												<MenuItem
													key={project_scope.id}
													value={project_scope.id}
												>
													{project_scope.sow}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								</Grid>
								<Grid item xs={12} sm={4}>
									<FormControl fullWidth>
										<InputLabel id={`project-scope-detais-${index}-label`}>
											Escopo do Projeto (SOW Detais) {index + 1}
										</InputLabel>
										<Select
											labelId={`project-scope-detais-${index}-label`}
											id={`projectScopeIds[${index}].projectScopeDetaisId`}
											name={`projectScopeIds[${index}].projectScopeDetaisId`}
											value={
												formik.values.projectScopeIds[index]
													?.projectScopeDetaisId || ''
											}
											onChange={formik.handleChange}
											error={
												formik.touched.projectScopeIds &&
												Boolean(formik.errors.projectScopeIds)
											}
											label={`Escopo do Projeto (SOW Detais) ${index + 1}`}
										>
											{filteredScopeDetais[index]?.map(project_scope_detais => (
												<MenuItem
													key={project_scope_detais.id}
													value={project_scope_detais.id}
												>
													{project_scope_detais.sow_detais}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								</Grid>
							</Grid>
						</Grid>
					))}
					{/* Outros campos do formulário */}
					<Grid item xs={12} sm={4}>
						<FormControl fullWidth>
							<InputLabel id="project-classification-id-label">
								Classificação do Projeto
							</InputLabel>
							<Select
								labelId="project-classification-id-label"
								id="projectClassificationId"
								name="projectClassificationId"
								value={formik.values.projectClassificationId}
								onChange={formik.handleChange}
								error={
									formik.touched.projectClassificationId &&
									Boolean(formik.errors.projectClassificationId)
								}
								label="Classificação do Projeto"
								style={{ width: '235px' }}
							>
								{dropdownProjectClassification
									.sort((a, b) => a.description.localeCompare(b.description))
									.map(project_classification => (
										<MenuItem
											key={project_classification.id}
											value={project_classification.id}
										>
											{project_classification.description}
										</MenuItem>
									))}
							</Select>
						</FormControl>
					</Grid>

					<Grid item xs={12}>
						<TextField
							fullWidth
							id="scopeObservations"
							name="scopeObservations"
							label="Observações de Escopo"
							multiline
							rows={4}
							value={formik.values.scopeObservations}
							onChange={formik.handleChange}
							error={
								formik.touched.scopeObservations &&
								Boolean(formik.errors.scopeObservations)
							}
							helperText={
								formik.touched.scopeObservations &&
								formik.errors.scopeObservations
							}
							inputProps={{ maxLength: 1000 }}
							style={{ width: '100%', maxWidth: '1000px' }}
						/>
					</Grid>
					<Grid item container direction="row" spacing={3}>
						<Grid item xs={12} sm={4}>
							<TextField
								id="teamQuantity"
								name="teamQuantity"
								label="Quantidade de equipes"
								type="number"
								InputProps={{ inputProps: { min: 1 } }}
								value={formik.values.teamQuantity}
								onChange={formik.handleChange}
								error={
									formik.touched.teamQuantity &&
									Boolean(formik.errors.teamQuantity)
								}
								helperText={
									formik.touched.teamQuantity && formik.errors.teamQuantity
								}
								style={{ width: '100%', maxWidth: '250px' }}
							/>
						</Grid>

						<Grid item xs={12} sm={4}>
							<FormControl fullWidth>
								<InputLabel id="shift-id-label">Turno</InputLabel>
								<Select
									labelId="shift-id-label"
									id="shiftId"
									name="shiftId"
									value={formik.values.shiftId}
									onChange={formik.handleChange}
									error={
										formik.touched.shiftId && Boolean(formik.errors.shiftId)
									}
									label="Turno"
									style={{ width: '100%', maxWidth: '235px' }}
								>
									{dropdownShift
										.sort((a, b) => a.description.localeCompare(b.description))
										.map(shift => (
											<MenuItem key={shift.id} value={shift.id}>
												{shift.description}
											</MenuItem>
										))}
								</Select>
							</FormControl>
						</Grid>
					</Grid>
					<Grid item xs={12} sm={4}>
						<TextField
							id="teamSize"
							name="teamSize"
							label="Número total de pessoas"
							type="number"
							InputProps={{ inputProps: { min: 1 } }}
							value={formik.values.teamSize}
							onChange={handleTeamSizeChange}
							error={formik.touched.teamSize && Boolean(formik.errors.teamSize)}
							helperText={formik.touched.teamSize && formik.errors.teamSize}
							style={{ width: '100%', maxWidth: '250px' }}
						/>
					</Grid>
					{saleOperationRoleDropdowns.map((_, index) => (
						<Grid item xs={12} key={index}>
							<FormControl fullWidth>
								<InputLabel id={`sale-operation-role-${index}-label`}>
									Perfil do Funcionário {index + 1}
								</InputLabel>
								<Select
									labelId={`sale-operation-role-${index}-label`}
									id={`saleOperationRoleIds[${index}]`}
									name={`saleOperationRoleIds[${index}]`}
									value={String(
										formik.values.saleOperationRoleIds[index] || ''
									)}
									onChange={formik.handleChange}
									error={
										formik.touched.saleOperationRoleIds &&
										Boolean(formik.errors.saleOperationRoleIds)
									}
									label={`Perfil do Funcionário ${index + 1}`}
									style={{ width: '100%', maxWidth: '1000px' }}
								>
									{dropdownSaleOperationRole
										.sort((a, b) => a.description.localeCompare(b.description))
										.map(saleOperationRole => (
											<MenuItem
												key={saleOperationRole.id}
												value={saleOperationRole.id}
											>
												{saleOperationRole.description}
											</MenuItem>
										))}
								</Select>
							</FormControl>
						</Grid>
					))}
					{/* <Grid container spacing={3} direction="column"> */}
					<Grid item xs={12} sm={4}>
						<TextField
							id="materialCount"
							name="materialCount"
							label="Quantidade de materiais"
							type="number"
							InputProps={{ inputProps: { min: 1 } }}
							value={formik.values.materialCount}
							onChange={handleMaterialCountChange}
							error={
								formik.touched.materialCount &&
								Boolean(formik.errors.materialCount)
							}
							helperText={
								formik.touched.materialCount && formik.errors.materialCount
							}
							style={{ width: '100%', maxWidth: '250px' }}
						/>
					</Grid>

					{materialDropdowns.map((_, index) => (
						<Grid item xs={12} key={index}>
							<Grid container spacing={3}>
								<Grid item xs={12} sm={4}>
									<FormControl fullWidth>
										<InputLabel id={`material-${index}-label`}>
											Material {index + 1}
										</InputLabel>
										<Select
											labelId={`material-${index}-label`}
											id={`materialSelections[${index}].materialId`}
											name={`materialSelections[${index}].materialId`}
											value={
												formik.values.materialSelections[index]?.materialId ||
												''
											}
											onChange={formik.handleChange}
											// error={
											// 	formik.touched.materialSelections?.[index]
											// 		?.materialId &&
											// 	typeof formik.errors.materialSelections?.[index] ===
											// 		'object' &&
											// 	Boolean(
											// 		formik.errors.materialSelections?.[index]
											// 			?.materialId
											// 	)
											// }
											error={
												formik.touched.materialSelections?.[index]
													?.materialId &&
												Boolean(
													formik.errors.materialSelections?.[index] &&
														typeof formik.errors.materialSelections[index] ===
															'object' &&
														(formik.errors.materialSelections[index] as any)
															.materialId
												)
											}
											label={`Material ${index + 1}`}
										>
											{materialMultipleSelectOptions.map(material => (
												<MenuItem key={material.id} value={material.id}>
													{material.description}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								</Grid>

								<Grid item xs={12} sm={4}>
									<TextField
										id={`materialSelections[${index}].quantity`}
										name={`materialSelections[${index}].quantity`}
										label={`Quantidade do Material ${index + 1}`}
										type="number"
										InputProps={{ inputProps: { min: 1 } }}
										value={
											formik.values.materialSelections[index]?.quantity || ''
										}
										onChange={formik.handleChange}
										// error={
										// 	formik.touched.materialSelections?.[index]?.quantity &&
										// 	typeof formik.errors.materialSelections?.[index] ===
										// 		'object' &&
										// 	Boolean(
										// 		formik.errors.materialSelections?.[index]?.quantity
										// 	)
										// }
										error={
											formik.touched.materialSelections?.[index]?.quantity &&
											Boolean(
												formik.errors.materialSelections?.[index] &&
													typeof formik.errors.materialSelections[index] ===
														'object' &&
													(formik.errors.materialSelections[index] as any)
														.quantity
											)
										}
										// helperText={
										// 	formik.touched.materialSelections?.[index]?.quantity &&
										// 	typeof formik.errors.materialSelections?.[index] ===
										// 		'object' &&
										// 	formik.errors.materialSelections?.[index]?.quantity
										// }
										helperText={
											formik.touched.materialSelections?.[index]?.quantity &&
											typeof formik.errors.materialSelections?.[index] ===
												'object' &&
											(
												formik.errors.materialSelections[index] as {
													quantity?: string;
												}
											).quantity
										}
										style={{ width: '100%', maxWidth: '250px' }}
									/>
								</Grid>
							</Grid>
						</Grid>
					))}
					{/* </Grid> */}
					<Grid item xs={12}>
						<TextField
							fullWidth
							id="customerExpectation"
							name="customerExpectation"
							label="Expectativa do Cliente"
							multiline
							rows={4}
							value={formik.values.customerExpectation}
							onChange={formik.handleChange}
							error={
								formik.touched.customerExpectation &&
								Boolean(formik.errors.customerExpectation)
							}
							helperText={
								formik.touched.customerExpectation &&
								formik.errors.customerExpectation
							}
							inputProps={{ maxLength: 1000 }}
							style={{ width: '100%', maxWidth: '1000px' }}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							id="expectedEmbarkationDate"
							name="expectedEmbarkationDate"
							label="Data Prevista de Embarque"
							type="date"
							InputLabelProps={{
								shrink: true,
							}}
							value={formik.values.expectedEmbarkationDate}
							onChange={formik.handleChange}
							error={
								formik.touched.expectedEmbarkationDate &&
								Boolean(formik.errors.expectedEmbarkationDate)
							}
							helperText={
								formik.touched.expectedEmbarkationDate &&
								formik.errors.expectedEmbarkationDate
							}
							style={{ width: '100%', maxWidth: '235px' }}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<FormControl fullWidth>
							<InputLabel id="embarkation-regime-id-label">
								Regime de embarque
							</InputLabel>
							<Select
								labelId="embarkation-regime-id-label"
								id="embarkationRegime"
								name="embarkationRegime"
								value={formik.values.embarkationRegime}
								onChange={formik.handleChange}
								error={
									formik.touched.embarkationRegime &&
									Boolean(formik.errors.embarkationRegime)
								}
								label="Regime de embarque"
								style={{ width: '100%', maxWidth: '235px' }}
							>
								{dropdownEmbarkationRegime.map(embarkationRegime => (
									<MenuItem
										key={embarkationRegime.id}
										value={embarkationRegime.id}
									>
										{embarkationRegime.description}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Grid>
					<Grid item xs={12} sm={4}>
						<TextField
							id="embarkationPeriod"
							name="embarkationPeriod"
							label="Regime de Embarque Personalizado (dias)"
							type="number"
							InputProps={{ inputProps: { min: 1 } }}
							value={formik.values.embarkationPeriod || ''}
							onChange={formik.handleChange}
							disabled={handleDisableEmbarkationPeriod()}
							error={
								formik.touched.embarkationPeriod &&
								Boolean(formik.errors.embarkationPeriod)
							}
							helperText={
								formik.touched.embarkationPeriod &&
								formik.errors.embarkationPeriod
							}
							style={{ width: '100%', maxWidth: '350px' }}
						/>
					</Grid>
					<Grid item container direction="row" spacing={3}>
						<Grid item xs={12} sm={4}>
							<TextField
								id="numberOfBoarding"
								name="numberOfBoarding"
								label="Quantidade de embarques"
								type="number"
								InputProps={{ inputProps: { min: 1 } }}
								value={formik.values.numberOfBoarding}
								onChange={formik.handleChange}
								error={
									formik.touched.numberOfBoarding &&
									Boolean(formik.errors.numberOfBoarding)
								}
								helperText={
									formik.touched.numberOfBoarding &&
									formik.errors.numberOfBoarding
								}
								style={{ width: '100%', maxWidth: '250px' }}
							/>
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<TextField
							fullWidth
							id="observation"
							name="observation"
							label="Observação"
							multiline
							rows={4}
							value={formik.values.observation}
							onChange={formik.handleChange}
							error={
								formik.touched.observation && Boolean(formik.errors.observation)
							}
							helperText={
								formik.touched.observation && formik.errors.observation
							}
							inputProps={{ maxLength: 1000 }}
							style={{ width: '100%', maxWidth: '1000px' }}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<FormControl fullWidth>
							<InputLabel id="boarding-status-id-label">
								Status do projeto
							</InputLabel>
							<Select
								labelId="boarding-status-id-label"
								id="boardingStatus"
								name="boardingStatus"
								value={formik.values.boardingStatus}
								onChange={formik.handleChange}
								error={
									formik.touched.boardingStatus &&
									Boolean(formik.errors.boardingStatus)
								}
								label="Status do projeto"
								style={{ width: '100%', maxWidth: '235px' }}
							>
								{dropdownBoardingStatus
									.sort((a, b) => a.description.localeCompare(b.description))
									.map(boardingStatus => (
										<MenuItem key={boardingStatus.id} value={boardingStatus.id}>
											{boardingStatus.description}
										</MenuItem>
									))}
							</Select>
						</FormControl>
					</Grid>
					<Grid item xs={12}>
						<Button
							variant="contained"
							type="submit"
							sx={{
								backgroundColor: green[500],
								'&:hover': {
									backgroundColor: green[700],
								},
								color: 'white',
							}}
						>
							<strong>Adicionar</strong>
						</Button>
					</Grid>
				</Grid>
			</form>
		</Paper>
	);
}
