import React, { useEffect, useState } from 'react';
import AddSkill from './AddSkill';
import {
	apiGetAllSaleOperationRole,
	apiGetAllSkills,
	IProjectScopeBackEnd,
	ISaleOperationRoleBackEnd,
	ISkill,
} from '../../../services/apiService';
import { toast } from 'react-toastify';
import { MoonLoader } from 'react-spinners';
import UpdateSaleOperationRole from './UpdateSkill';

function SkillSettings() {
	const [skill, setSkill] = useState<ISkill[]>([]);
	const [updatedDataToggle, setUpdatedDataToggle] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const skills = await apiGetAllSkills();
				setSkill(skills);
			} catch (error) {
				toast.error('Erro ao carregar habilidades');
			} finally {
				setTimeout(() => {
					setIsLoading(false);
				}, 500);
			}
		};
		fetchData();
	}, []);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const skills = await apiGetAllSkills();
				setSkill(skills);
			} catch (error) {
				toast.error('Erro ao recarregar habilidades');
			} finally {
				setTimeout(() => {
					setIsLoading(false);
				}, 500);
			}
		};
		setIsLoading(true);
		fetchData();
	}, [updatedDataToggle]);

	return (
		<div>
			<AddSkill
				updatedDataToggle={updatedDataToggle}
				setUpdatedDataToggle={setUpdatedDataToggle}
			/>
			{isLoading ? (
				<div>
					<MoonLoader color="#36d7b7" />
				</div>
			) : (
				<UpdateSaleOperationRole
					skill={skill}
					updatedDataToggle={updatedDataToggle}
				/>
			)}
		</div>
	);
}

export default SkillSettings;
