import React, { useState } from 'react';
import {
	Button,
	Grid,
	MenuItem,
	Paper,
	Select,
	Typography,
	FormControl,
	InputLabel,
	TextField,
} from '@mui/material';
import { green } from '@mui/material/colors';
import {
  apiCreateProjectScopeDetais,
	IProjectScopeBackEnd,
	IProjectScopeSowDetaisInput,
} from '../../../services/apiService';
import { toast } from 'react-toastify';

interface AddSowDetaisProps {
	sows: IProjectScopeBackEnd[];
	updatedDataToggle: boolean;
	setUpdatedDataToggle: React.Dispatch<React.SetStateAction<boolean>>;
}

function AddSowDetais({
	sows,
	updatedDataToggle,
	setUpdatedDataToggle,
}: AddSowDetaisProps) {
	const [newSowDetais, setNewSowDetais] = useState<IProjectScopeSowDetaisInput>(
		{
			sowDetais: '',
			projectScopeId: null,
		}
	);

	const handleAddSowDetais = async () => {
		if (newSowDetais.sowDetais === '' || newSowDetais.projectScopeId === null) {
			toast.warning('Os campos precisam estar preenchidos');
		} else {
			try {
				await apiCreateProjectScopeDetais(newSowDetais);
				setNewSowDetais({
					sowDetais: '',
					projectScopeId: null,
				});
				setUpdatedDataToggle(!updatedDataToggle);
				toast.success('Plataforma adicionado com sucesso');
			} catch (error) {
				toast.error('Erro ao adicionar o Plataforma');
			}
		}
	};

	return (
		<Grid item xs={12} style={{ marginBottom: 27 }}>
			<Paper elevation={5} style={{ padding: 20 }}>
				<Typography
					variant="h6"
					component="h3"
					style={{ marginBottom: '20px' }}
				>
					Novo "Scope Sow Detais"
				</Typography>
				<Grid container spacing={2} alignItems="center">
					<Grid item xs={4}>
						<TextField
							fullWidth
							label="Sow Detais"
							value={newSowDetais.sowDetais}
							onChange={e =>
								setNewSowDetais({
									...newSowDetais,
									sowDetais: e.target.value,
								})
							}
							variant="outlined"
						/>
					</Grid>
					<Grid item xs={3}>
						<FormControl fullWidth variant="outlined">
							<InputLabel id="sow-select-label">Sow</InputLabel>
							<Select
								labelId="sow-select-label"
								value={newSowDetais.projectScopeId}
								onChange={e =>
									setNewSowDetais({
										...newSowDetais,
										projectScopeId: Number(e.target.value),
									})
								}
								label="Sow"
							>
								{sows
									.sort((a, b) => a.sow.localeCompare(b.sow))
									.map(sow => (
										<MenuItem key={sow.id} value={sow.id}>
											{sow.sow}
										</MenuItem>
									))}
							</Select>
						</FormControl>
					</Grid>
					<Grid item xs={2}>
						<Button
							variant="contained"
							onClick={handleAddSowDetais}
							sx={{
								backgroundColor: green[500], // Cor de fundo verde
								'&:hover': {
									backgroundColor: green[700], // Cor de fundo mais escura ao passar o mouse
								},
								color: 'white', // Cor do texto
							}}
						>
							<strong>Adicionar</strong>
						</Button>
					</Grid>
				</Grid>
			</Paper>
		</Grid>
	);
}

export default AddSowDetais;
