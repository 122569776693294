import React, { useState, useEffect } from 'react';
import {
	Button,
	Grid,
	MenuItem,
	Paper,
	Select,
	Typography,
	FormControl,
	InputLabel,
	TextField,
} from '@mui/material';
import { green } from '@mui/material/colors';
import {
	apiDeleteClient,
	apiUpdateClient,
	IClientBackend,
	IUserBackEnd,
} from '../../../services/apiService';
import { toast } from 'react-toastify';

interface UpdateClientProps {
	clients: IClientBackend[];
	coordinators: IUserBackEnd[];
	updatedDataToggle: boolean;
}

function UpdateClient({
	clients,
	coordinators,
	updatedDataToggle,
}: UpdateClientProps) {
	const [updatedClients, setUpdatedClients] = useState<IClientBackend[]>([]);

	useEffect(() => {
		// Ordena os clientes uma vez quando o componente é montado
		const sortedClients = [...clients].sort((a, b) =>
			a.client_name.localeCompare(b.client_name)
		);
		setUpdatedClients(sortedClients);
	}, [clients, updatedDataToggle]);

	const handleUpdateClient = async (client: IClientBackend) => {
		if (client.user_id === null) {
			toast.error('Coordenador não pode ser nulo');
			return;
		}

		const updatedClient = {
			clientName: client.client_name,
			userId: client.user_id,
		};

		try {
			await apiUpdateClient(client.id, updatedClient);
			toast.success('Cliente atualizado com sucesso');
		} catch (error) {
			toast.error('Erro ao atualizar o cliente');
		}
	};

	const handleDelete = async (clientId: number) => {
		try {
			await apiDeleteClient(clientId);
			const updatedClientsNew = updatedClients.filter(
				client => client.id !== clientId
			);
			setUpdatedClients(updatedClientsNew);
			toast.success('Registro deletado com sucesso!');
		} catch (error) {
			toast.error(
				'Erro: o cliente possui uma plataforma ou projeto ligado a ele. Delete-os antes.'
			);
		}
	};
	const handleNameChange = (id: number, newName: string) => {
		setUpdatedClients(prevClients =>
			prevClients.map(client =>
				client.id === id ? { ...client, client_name: newName } : client
			)
		);
	};

	const handleCoordinatorChange = (id: number, newUserId: number) => {
		setUpdatedClients(prevClients =>
			prevClients.map(client =>
				client.id === id ? { ...client, user_id: newUserId } : client
			)
		);
	};

	return (
		<Grid item xs={12}>
			<Paper elevation={5} style={{ padding: 20 }}>
				<Typography
					variant="h6"
					component="h3"
					style={{ marginBottom: '20px' }}
				>
					Clientes
				</Typography>
				{updatedClients.map(client => (
					<Grid
						container
						spacing={2}
						alignItems="center"
						key={client.id}
						style={{ marginBottom: 20 }}
					>
						<Grid item xs={3}>
							<TextField
								fullWidth
								label="Nome Cliente"
								value={client.client_name}
								onChange={e => handleNameChange(client.id, e.target.value)}
								variant="outlined"
							/>
						</Grid>
						<Grid item xs={2.5}>
							<FormControl fullWidth variant="outlined">
								<InputLabel id={`coordinator-select-label-${client.id}`}>
									Coordenador
								</InputLabel>
								<Select
									labelId={`coordinator-select-label-${client.id}`}
									value={client.user_id !== null ? String(client.user_id) : ''}
									onChange={e =>
										handleCoordinatorChange(client.id, Number(e.target.value))
									}
									label="Coordenador"
								>
									{coordinators.map(user => (
										<MenuItem key={user.id} value={user.id}>
											{user.username}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={2}>
							<Button
								color="primary"
								variant="contained"
								onClick={() => handleUpdateClient(client)}
							>
								Atualizar
							</Button>
							<Button
								variant="contained"
								onClick={() => handleDelete(client.id)} // Correção: Passar uma função anônima que chama handleDelete
								sx={{
									backgroundColor: 'red',
									'&:hover': {
										backgroundColor: 'darkred',
									},
									color: 'white',
									marginLeft: 2,
								}}
							>
								Deletar
							</Button>
						</Grid>
					</Grid>
				))}
			</Paper>
		</Grid>
	);
}

export default UpdateClient;
