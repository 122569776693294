import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';

// Registra o plugin globalmente
Chart.register(ChartDataLabels);

interface ActualPercentage {
	labels: string[];
	actual_by_planned: number[];
	actual_by_scheduled: number[];
}

interface BarChartProps {
	data: ActualPercentage | undefined;
	type: 'Planejado' | 'Programado';
	title: string;
}

const BarChart: React.FC<BarChartProps> = ({ data, type, title }) => {
	const chartRef = useRef<HTMLCanvasElement>(null);
	const chartInstance = useRef<Chart | null>(null);

	useEffect(() => {
		if (chartRef.current && data) {
			const ctx = chartRef.current.getContext('2d');
			if (ctx) {
				// Destruir o gráfico anterior se existir
				if (chartInstance.current) {
					chartInstance.current.destroy();
				}

				const chartData = {
					labels: data.labels,
					datasets: [
						{
							label: type === 'Planejado' ? 'Planejado' : 'Programado',
							data:
								type === 'Planejado'
									? data.actual_by_planned
									: data.actual_by_scheduled,
							backgroundColor: '#001D55',
						},
					],
				};

				// Criação do novo gráfico
				chartInstance.current = new Chart(ctx, {
					type: 'bar',
					data: chartData,
					options: {
						indexAxis: 'y',
						responsive: true,
						plugins: {
							datalabels: {
								color: context => {
									const value = context.dataset.data[context.dataIndex] ?? 0;
									return context.dataIndex === 0 && value > 0
										? 'white'
										: 'black';
								},
								font: {
									weight: 'bold',
								},
								align: context => {
									return context.dataIndex === 0 ? 'center' : 'end';
								},
								anchor: context => {
									return context.dataIndex === 0 ? 'center' : 'end';
								},
								formatter: (value, context) => {
									// Garante que o valor não nulo será exibido como rótulo para todas as barras
									value = value ?? 0;
									return value !== 0 ? value : '';
								},
							},
							legend: {
								display: false,
							},
							title: {
								display: true,
								text: title,
								font: {
									size: 15,
									weight: 'bold',
								},
								padding: {
									top: 10,
									bottom: 5,
								},
							},
						},
						scales: {
							x: {
								display: false,
							},
							y: {
								grid: {
									display: false,
								},
								ticks: {
									display: true,
								},
								afterFit(scale) {
									scale.width = 200; // Ajuste conforme necessário
								},
								max: 50, // Certifique-se de que este valor é adequado para seu conjunto de dados
							},
						},
					},
				});
			}
		}

		// Função de limpeza ao desmontar o componente
		return () => {
			if (chartInstance.current) {
				chartInstance.current.destroy();
			}
		};
	}, [data, type]); // Dependências do useEffect

	return (
		<div style={{ width: '640px', height: '320px' }}>
			<canvas ref={chartRef} />
		</div>
	);
};

export default BarChart;
