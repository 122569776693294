import React, { useState, useEffect } from 'react';
import {
	Grid,
	Paper,
	Typography,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	Button,
	Tooltip,
	IconButton,
	List,
	ListItem,
	ListItemText,
	FormControlLabel,
	Checkbox,
} from '@mui/material';
import {
	IBoardingBackend,
	IOperationRoleBackEnd,
	ISkill,
	IWorkerSkill,
	IWorkerBackEnd,
	apiGetAllOperationRoles,
	apiGetAllSkills,
	apiGetWorkersBySkillsDateAvailability,
	apiUpdateOrAddWorkerBoarding,
	ISkillsBoardingDate,
} from '../services/apiService';
import { InfoOutlined } from '@mui/icons-material';
import { toast } from 'react-toastify';
import { green } from '@mui/material/colors';
import { existInVisibilityRoleInLocalStorage } from '../utils/existInVisibilityRoleInLocalStorage';

interface IProps {
	workers: IWorkerBackEnd[];
	setWorkers: (workers: IWorkerBackEnd[]) => void;
	boarding: IBoardingBackend | null;
	addedWorker: boolean;
	setAddedWorker: (addedWorker: boolean) => void;
	selectedBoardingNumber: number | '';
}

function formatWorkerSkills(workerSkills: IWorkerSkill[]): string {
	const formattedSkills: string[] = [];

	for (const skill of workerSkills) {
		formattedSkills.push(
			`${skill.skill.description} (${skill.level.description})`
		);
	}

	return formattedSkills.join(', ');
}

function WorkerPeople({
	boarding,
	workers,
	setWorkers,
	addedWorker,
	setAddedWorker,
	selectedBoardingNumber,
}: IProps) {
	const [selectedSkills, setSelectedSkills] = useState<string[]>([]);
	const [selectedOperationRoles, setSelectedOperationRoles] = useState<
		number[]
	>([]);
	const [skills, setSkills] = useState<ISkill[]>([]);
	const [operationRoles, setOperationRoles] = useState<IOperationRoleBackEnd[]>(
		[]
	);
	const [showInfo, setShowInfo] = useState(false);
	const [filterStandBy, setFilterStandBy] = useState(true);
	const [hideAddWorkerButton, setHideAddWorkerButton] = useState(false);

	useEffect(() => {
		setHideAddWorkerButton(
			existInVisibilityRoleInLocalStorage('hide add worker button (people)')
		);

		const fetchData = async () => {
			try {
				const response = await apiGetAllSkills();
				setSkills(response);
			} catch (error) {
				toast.error('ERROR: falha ao buscar habilidades.');
				console.error('Erro ao buscar habilidades: ', error);
			}

			try {
				const response = await apiGetAllOperationRoles();
				setOperationRoles(response);
			} catch (error) {
				toast.error('ERROR: falha ao buscar funções de operação.');
				console.error('Erro ao buscar funções de operação:', error);
			}
		};

		fetchData();
	}, []);

	const handleResearch = async (event: any) => {
		event.preventDefault();
		console.log('🚀  skills:', skills);
		console.log('🚀  selectedOperationRoles:', selectedOperationRoles);
		console.log('🚀  selectedSkills:', selectedSkills);

		const skillsIds = selectedSkills.map(skill => {
			const skillFind = skills.find(obj => obj.description === skill);
			return skillFind ? skillFind.id : null;
		});
		console.log('🚀  skillsIds:', skillsIds);

		console.log('🚀  boarding:', boarding);

		const skillsBoardingDate: ISkillsBoardingDate = {
			operationRoleIds: selectedOperationRoles,
			skillIds: skillsIds,
			boardingStartDate: boarding?.scheduled_embarkation_date,
			boardingEndDate: boarding?.scheduled_disembarkation_date,
			filterStandBy: filterStandBy,
			filterUnscheduled: true,
		};

		if (selectedBoardingNumber) {
			try {
				const response = await apiGetWorkersBySkillsDateAvailability(
					skillsBoardingDate
				);
				console.log(
					'🚀 reponse apiGetWorkersBySkillsDateAvailability:',
					response
				);
				setWorkers(response);
				if (response.length === 0) {
					toast.warning('WARNING: nenhum funcionário disponível.');
				}
			} catch (error) {
				toast.error('ERROR: falha ao pesquisar funcionários.');
				console.error('Erro ao pesquisar funcionários:', error);
			}
		} else {
			toast.error('ERRO: Selecione um embarque!');
		}
	};

	const handleInfoClick = () => {
		setShowInfo(!showInfo);
	};

	const onAddClickWorker = async (workerId: number) => {
		const updateWorker = {
			boardindId: boarding?.id,
			workerId: workerId,
			scheduledStartDate: boarding?.scheduled_embarkation_date,
			scheduledEndDate: boarding?.scheduled_disembarkation_date,
			actualStartDate: boarding?.actual_embarkation_date,
			actualEndDate: boarding?.actual_disembarkation_date,
		};

		try {
			const response = await apiUpdateOrAddWorkerBoarding(updateWorker);
			console.log('🚀 reponse apiUpdateOrAddWorkerBoarding:', response);
			toast.success('SUCCESS: trabalhador adicionado.');

			const filteredWorkers = workers.filter(worker => worker.id !== workerId);
			setWorkers(filteredWorkers);
			setAddedWorker(!addedWorker);
		} catch (error) {
			toast.error('ERROR: falha ao adicionar trabalhador.');
			console.error('Erro ao adicionar trabalhador:', error);
		}
	};

	return (
		<>
			<Grid item xs={12}>
				<Paper elevation={5} style={{ padding: 20 }}>
					<Typography
						variant="h5"
						component="h2"
						style={{ marginBottom: '20px' }}
					>
						Funcionários
						<Tooltip title="Informação" arrow>
							<IconButton onClick={handleInfoClick}>
								<InfoOutlined />
							</IconButton>
						</Tooltip>
					</Typography>
					{showInfo && (
						<Typography variant="body1" style={{ marginBottom: '40px' }}>
							<div>
								Filtra os funcionários de acordo com a data{' '}
								<strong>programada</strong> do embarque. Inicialmente a data
								programada é igual a data planejada do projeto.
							</div>
							<div style={{ marginTop: '20px' }}>
								O check-box <strong>Stand-by (drake)</strong> filtra os
								funcionários que estão com status "stand-by" no Drake para o
								período do embarque selecionado.
							</div>
						</Typography>
					)}
					<div>
						<form onSubmit={handleResearch}>
							<Grid container spacing={2}>
								<Grid item>
									<FormControlLabel
										control={
											<Checkbox
												checked={filterStandBy}
												onChange={e => setFilterStandBy(e.target.checked)}
												name="filterStandBy"
												color="primary"
											/>
										}
										label="Stand-by (drake)"
									/>
								</Grid>
							</Grid>
							<Grid item style={{ marginTop: '15px' }}>
								<FormControl fullWidth>
									<InputLabel
										id="mutiple-skill-label"
										className={
											selectedSkills.length > 0 ? 'input-label-selected' : ''
										}
										style={{ marginBottom: '10px' }}
									>
										Qualificação Necessária
									</InputLabel>
									<Select
										labelId="mutiple-skill-label"
										id="mutiple-skill"
										label="Qualificação Necessária"
										multiple
										value={selectedSkills}
										onChange={(e: any) => setSelectedSkills(e.target.value)}
										renderValue={(selected: any) => (
											<div>
												{selected.map((value: any) => (
													<Button
														key={value}
														variant="contained"
														style={{
															marginRight: '5px',
															backgroundColor: '#d0e3f1',
															color: 'black',
														}}
													>
														{value}
													</Button>
												))}
											</div>
										)}
									>
										{skills
											.sort((a, b) =>
												a.description.trim().localeCompare(b.description.trim())
											)
											.map(skill => (
												<MenuItem key={skill.id} value={skill.description}>
													{skill.description}
												</MenuItem>
											))}
									</Select>
								</FormControl>
							</Grid>
							<Grid item style={{ marginTop: '23px' }}>
								<FormControl fullWidth>
									<InputLabel id="operation-role-id-label">Função</InputLabel>
									<Select
										labelId="operation-role-id-label"
										id="operationRoleId"
										name="operationRoleId"
										multiple
										value={selectedOperationRoles}
										onChange={e =>
											setSelectedOperationRoles(e.target.value as number[])
										}
										label="Função"
										renderValue={(selected: any) => (
											<div>
												{selected.map((value: any) => {
													const role = operationRoles.find(
														role => role.id === value
													);
													return (
														<Button
															key={value}
															variant="contained"
															style={{
																marginRight: '5px',
																backgroundColor: '#d0e3f1',
																color: 'black',
															}}
														>
															{role?.description}
														</Button>
													);
												})}
											</div>
										)}
									>
										{operationRoles
											.sort((a, b) =>
												a.description.trim().localeCompare(b.description.trim())
											)
											.map(operationRole => (
												<MenuItem
													key={operationRole.id}
													value={operationRole.id}
												>
													{operationRole.description}
												</MenuItem>
											))}
									</Select>
								</FormControl>
							</Grid>

							<Button
								type="submit"
								variant="contained"
								color="primary"
								style={{
									marginTop: '23px',
									background: '#e5e5e5',
									color: 'black',
								}}
							>
								<strong>Pesquisar</strong>
							</Button>
						</form>
						<List style={{ marginTop: '10px' }}>
							{workers.map(worker => (
								<ListItem
									key={worker.id}
									divider
									style={{ marginBottom: '20px' }}
								>
									<ListItemText
										primary={
											<span>
												<span style={{ fontWeight: 'bold' }}>
													{worker.worker_name}
												</span>
												<div style={{ marginTop: '10px' }}>
													Função: {worker.operation_role.description}
												</div>
												<div style={{ marginTop: '10px' }}>
													{'Habilidade: ' +
														formatWorkerSkills(worker.worker_skill)}
												</div>
											</span>
										}
									/>
									{!hideAddWorkerButton && (
										<Button
											variant="contained"
											sx={{
												backgroundColor: green[500], // Cor de fundo verde
												'&:hover': {
													backgroundColor: green[700], // Cor de fundo mais escura ao passar o mouse
												},
												color: 'white', // Cor do texto
												minWidth: '115px', // Largura mínima para uniformidade
											}}
											onClick={() => onAddClickWorker(worker.id)}
										>
											<strong>Adicionar</strong>
										</Button>
									)}
								</ListItem>
							))}
						</List>
					</div>
				</Paper>
			</Grid>
		</>
	);
}

export default WorkerPeople;
