export const validToken = (): boolean => {
	const token = localStorage.getItem('token');
	const tokenExpiresAt = localStorage.getItem('tokenExpiresAt');

	if (token && tokenExpiresAt !== null) {
		const dateUtcToken = Date.parse(tokenExpiresAt);

		// obtem a data do sistem em UTC
		const nowUTC = Date.now();
		const date = new Date(nowUTC); // Cria o objeto Date com base no tempo atual
		const timezoneOffsetMinutes = date.getTimezoneOffset(); // Obtém o offset do fuso horário local em minutos
		date.setMinutes(date.getMinutes() + timezoneOffsetMinutes); // Adiciona o offset do fuso horário local
		const dateUtc = date.getTime();

		if (dateUtc > dateUtcToken) {
			console.log('O token expirou.');
			return false;
		} else {
			console.log('O token ainda é válido.');
			return true;
		}
	} else {
		console.log('O token inválido.');
		return false;
	}
};
