import React, { useState, useEffect } from 'react';
import {
	Button,
	Grid,
	MenuItem,
	Paper,
	Select,
	Typography,
	FormControl,
	InputLabel,
	TextField,
} from '@mui/material';
import { green } from '@mui/material/colors';
import {
  apiDeleteSaleOperationRole,
	apiUpdateProjecScope,
	apiUpdateSaleOperationRole,
	IProjectScopeBackEnd,
	IProjectScopeInput,
	ISaleOperationRoleBackEnd,
	ISaleOperationRoleInput,
} from '../../../services/apiService';
import { toast } from 'react-toastify';

interface UpdateSaleOperationRoleProps {
	saleOperationRole: ISaleOperationRoleBackEnd[];
	updatedDataToggle: boolean;
}

function UpdateSaleOperationRole({
	saleOperationRole,
	updatedDataToggle,
}: UpdateSaleOperationRoleProps) {
	const [updatedSaleOperationRoles, setUpdatedSaleOperationRoles] = useState<
		ISaleOperationRoleBackEnd[]
	>([]);

	useEffect(() => {
		// Ordena os sale_operation_rolees uma vez quando o componente é montado
		const sortedSaleOperationRoles = [...saleOperationRole].sort((a, b) =>
			a.description.localeCompare(b.description)
		);
		setUpdatedSaleOperationRoles(sortedSaleOperationRoles);
	}, [saleOperationRole, updatedDataToggle]);

	const handleUpdateSaleOperationRole = async (
		sale_operation_role: ISaleOperationRoleBackEnd
	) => {
		if (sale_operation_role.description === '') {
			toast.error('O campo não pode ser vazio');
			return;
		}

		const updatedSaleOperationRole = {
			description: sale_operation_role.description,
		};

		try {
			await apiUpdateSaleOperationRole(
				sale_operation_role.id,
				updatedSaleOperationRole
			);
			toast.success('SaleOperationRole atualizado com sucesso');
		} catch (error) {
			toast.error('Erro ao atualizar o sale_operation_role');
		}
	};

	const handleDelete = async (saleOperationRoleId: number) => {
		try {
			await apiDeleteSaleOperationRole(saleOperationRoleId);
			const updatedSaleOperationRolesNew = updatedSaleOperationRoles.filter(
				saleOperationRole => saleOperationRole.id !== saleOperationRoleId
			);
			setUpdatedSaleOperationRoles(updatedSaleOperationRolesNew);
			toast.success('Registro deletado com sucesso!');
		} catch (error) {
			toast.error(
				'Erro: a função comercial possui um ou mais projetos ligados a ela. Delete-os antes.'
			);
		}
	};

	const handleNameChange = (id: number, newSaleOperationRole: string) => {
		setUpdatedSaleOperationRoles(prevSaleOperationRoles =>
			prevSaleOperationRoles.map(sale_operation_role =>
				sale_operation_role.id === id
					? { ...sale_operation_role, description: newSaleOperationRole }
					: sale_operation_role
			)
		);
	};

	return (
		<Grid item xs={12}>
			<Paper elevation={5} style={{ padding: 20 }}>
				<Typography
					variant="h6"
					component="h3"
					style={{ marginBottom: '20px' }}
				>
					Funções comerciais
				</Typography>
				{updatedSaleOperationRoles.map(sale_operation_role => (
					<Grid
						container
						spacing={2}
						alignItems="center"
						key={sale_operation_role.id}
						style={{ marginBottom: 20 }}
					>
						<Grid item xs={3}>
							<TextField
								fullWidth
								label="Função comercial"
								value={sale_operation_role.description}
								onChange={e =>
									handleNameChange(sale_operation_role.id, e.target.value)
								}
								variant="outlined"
							/>
						</Grid>
						<Grid item xs={2}>
							<Button
								color="primary"
								variant="contained"
								onClick={() =>
									handleUpdateSaleOperationRole(sale_operation_role)
								}
							>
								Atualizar
							</Button>
							<Button
								variant="contained"
								onClick={() => handleDelete(sale_operation_role.id)} // Correção: Passar uma função anônima que chama handleDelete
								sx={{
									backgroundColor: 'red',
									'&:hover': {
										backgroundColor: 'darkred',
									},
									color: 'white',
									marginLeft: 2,
								}}
							>
								Deletar
							</Button>
						</Grid>
					</Grid>
				))}
			</Paper>
		</Grid>
	);
}

export default UpdateSaleOperationRole;
