import React from 'react';
import '@blueprintjs/core/lib/css/blueprint.css';
import '@blueprintjs/table/lib/css/table.css';
import { Column, Cell, Table2 } from '@blueprintjs/table';
import { IWorkerHistogram } from '../../../services/apiService';

interface IWorkerHistogramTableProps {
	workerHistogram: IWorkerHistogram | undefined;
	startDate: string;
	endDate: string;
	isFullScreen: boolean;
}

function formatDate(date: string): string {
	const [year, month, day] = date.split('-');
	const monthNames = [
		'jan',
		'fev',
		'mar',
		'abr',
		'mai',
		'jun',
		'jul',
		'ago',
		'set',
		'out',
		'nov',
		'dez',
	];

	const formattedDate = new Date(`${year}-${month}-${day}T00:00:00`);
	const daysOfWeek = ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'];
	const dayOfWeekIndex = formattedDate.getDay();
	const dayOfWeek = daysOfWeek[dayOfWeekIndex];

	return `${dayOfWeek.toUpperCase()}\n${day}/${month}\n\n`;
}

function generateColumnWidth(n: number): number[] {
	const array: number[] = [200, 250];

	for (let i = 0; i < n; i++) {
		array.push(53);
	}

	return array;
}

function getLightColor(code: string): string {
	if (code.endsWith('*')) {
		return '#001D55'; // Preto
	}

	const colorMap: { [key: string]: string } = {
		// mapeamento de cores aqui
		STB: '#E0FFFF',
		TMF: '#F0E68C',
		E: '#FFD700',
		DE: '#FFA07A',
		FO: '#E6E6FA',
		AFI: '#D8BFD8',
		FE: '#FFB6C1',
		TMS: '#ADD8E6',
		H: '#98FB98',
		Q: '#FFDEAD',
		D: '#FFC0CB',
		DT: '#FFFACD',
		HO5: '#FAFAD2',
		HO1: '#F5DEB3',
		AD5: '#FFDAB9',
		TO: '#E0FFFF',
		FT: '#F0E68C',
		AT: '#FFD700',
		AD1: '#FFA07A',
		HI: '#E6E6FA',
		EI: '#D8BFD8',
		DI: '#FFB6C1',
		DEI: '#ADD8E6',
		FTO: '#98FB98',
	};

	if (code === '') {
		return '#FFFFFF'; // Light Gray
	}

	return colorMap[code] || '#f5cc18'; // Default to Light Gray
}

function WorkerHistogramTable({
	workerHistogram,
	startDate,
	endDate,
	isFullScreen,
}: IWorkerHistogramTableProps) {
	if (!workerHistogram) return null;

	const { workers, days } = workerHistogram;

	const columns = [
		<Column
			key="worker-name"
			name="worker-name"
			cellRenderer={(rowIndex: number) => (
				<Cell>{workers[rowIndex].worker_name}</Cell>
			)}
			columnHeaderCellRenderer={(columnIndex: number) => (
				<div
					style={{
						whiteSpace: 'pre-wrap',
						borderBottom: '1px solid rgba(0,0,0,0.1)',
						borderRight: '1px solid rgba(0,0,0,0.1)',
						paddingTop: 5,
						paddingLeft: 5,
					}}
				>
					{'Nome\n\n'}
				</div>
			)}
		/>,
		<Column
			key="operation-role"
			name="operation-role"
			cellRenderer={(rowIndex: number) => (
				<Cell>{workers[rowIndex].operation_role}</Cell>
			)}
			columnHeaderCellRenderer={(columnIndex: number) => (
				<div
					style={{
						whiteSpace: 'pre-wrap',
						borderBottom: '1px solid rgba(0,0,0,0.1)',
						borderRight: '1px solid rgba(0,0,0,0.1)',
						paddingTop: 5,
						paddingLeft: 5,
					}}
				>
					{'Função de operação\n\n\n'}
				</div>
			)}
		/>,
		...days.map((day, index) => (
			<Column
				key={`Day_${index}`}
				name={formatDate(day)}
				cellRenderer={(rowIndex: number) => {
					const eventCode = workers[rowIndex].events[index];
					const backgroundColor = getLightColor(eventCode);
					const textColor = eventCode.endsWith('*') ? '#FFFFFF' : '#000000'; // Branco se terminar com '*', preto caso contrário

					return (
						<Cell
							style={{
								backgroundColor: backgroundColor,
								color: textColor,
							}}
						>
							{eventCode}
						</Cell>
					);
				}}
				columnHeaderCellRenderer={(columnIndex: number) => (
					<div
						style={{
							whiteSpace: 'pre-wrap',
							borderBottom: '1px solid rgba(0,0,0,0.1)',
							borderRight: '1px solid rgba(0,0,0,0.1)',
							paddingTop: 5,
							paddingLeft: 5,
							paddingRight: 5,
						}}
					>
						{formatDate(day)}
					</div>
				)}
			/>
		)),
	];

	return (
		<div
			style={{
				width: isFullScreen ? '100%' : 1545,
				height: isFullScreen ? 'calc(100vh - 100px)' : 600,
			}}
		>
			<Table2
				numRows={workers.length}
				numFrozenColumns={2}
				numFrozenRows={0}
				enableRowHeader={false}
				columnWidths={generateColumnWidth(workerHistogram.days.length)}
			>
				{columns}
			</Table2>
		</div>
	);
}

export default WorkerHistogramTable;
