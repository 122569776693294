import React, { useEffect, useState } from 'react';
import AddSaleOperationRole from './AddBoardinStatus';
import {
	apiGetAllBoardingStatus,
	apiGetAllSaleOperationRole,
	IBoardingStatusBackEnd,
	IProjectScopeBackEnd,
	ISaleOperationRoleBackEnd,
} from '../../../services/apiService';
import { toast } from 'react-toastify';
import { MoonLoader } from 'react-spinners';
import UpdateSaleOperationRole from './UpdateBoardinStatus';
import AddBoardinStatus from './AddBoardinStatus';

function BoardinStatusSettings() {
	const [boardingStatus, setBoardingStatus] = useState<
		IBoardingStatusBackEnd[]
	>([]);
	const [updatedDataToggle, setUpdatedDataToggle] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const boardingStatus = await apiGetAllBoardingStatus();
				setBoardingStatus(boardingStatus);
			} catch (error) {
				toast.error('Erro ao carregar status');
			} finally {
				setTimeout(() => {
					setIsLoading(false);
				}, 500);
			}
		};
		fetchData();
	}, []);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const boardingStatus = await apiGetAllBoardingStatus();
				setBoardingStatus(boardingStatus);
			} catch (error) {
				toast.error('Erro ao recarregar status');
			} finally {
				setTimeout(() => {
					setIsLoading(false);
				}, 500);
			}
		};
		setIsLoading(true);
		fetchData();
	}, [updatedDataToggle]);

	return (
		<div>
			<AddBoardinStatus
				updatedDataToggle={updatedDataToggle}
				setUpdatedDataToggle={setUpdatedDataToggle}
			/>
			{isLoading ? (
				<div>
					<MoonLoader color="#36d7b7" />
				</div>
			) : (
				<UpdateSaleOperationRole
					boardingStatus={boardingStatus}
					updatedDataToggle={updatedDataToggle}
				/>
			)}
		</div>
	);
}

export default BoardinStatusSettings;
