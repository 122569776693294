import React from 'react';
import { Grid, Typography, Paper, Box } from '@mui/material';
import ProgressBarComponent from './ProgressBarComponent';

import { IPerformanceOverview } from '../../../services/apiService';
import SemiCircularDonut from './SemiCircularDonut';
import DonutChart from './DonutChart';

interface DataSummaryProps {
	performanceOverview: IPerformanceOverview | undefined;
	startDate: string;
	endDate: string;
}

const SummaryBar: React.FC<DataSummaryProps> = ({
	performanceOverview,
	startDate,
	endDate,
}) => {
	const planned = performanceOverview?.data_summary.planned ?? 0;
	const scheduled = performanceOverview?.data_summary.scheduled ?? 0;
	const actual = performanceOverview?.data_summary.actual ?? 0;

	const planProgDifference = planned - scheduled;
	const actualSchedulePercentage =
		performanceOverview?.data_summary.actual_scheduled_percentage ?? 0;

	return (
		<Grid container spacing={2} style={{ marginTop: 10, marginBottom: 10 }}>
			<Grid item xs={3} style={{ maxWidth: '250px' }}>
				<Paper
					style={{
						padding: '10px',
						textAlign: 'center',
						backgroundColor: '#007EB8',
						color: 'white',
					}}
				>
					<Typography variant="h4">{planned}</Typography>
					<Typography>Planejado</Typography>
				</Paper>
			</Grid>
			<Grid item xs={3} style={{ maxWidth: '250px' }}>
				<Paper
					style={{
						padding: '10px',
						textAlign: 'center',
						backgroundColor: '#001D55',
						color: 'white',
					}}
				>
					<Typography variant="h4">{scheduled}</Typography>
					<Typography>Programado</Typography>
				</Paper>
			</Grid>
			<Grid item xs={3} style={{ maxWidth: '250px' }}>
				<Paper
					style={{
						padding: '10px',
						textAlign: 'center',
						backgroundColor: '#CCAA14',
						color: 'black',
					}}
				>
					<Typography variant="h4">{actual}</Typography>
					<Typography>Realizado</Typography>
				</Paper>
			</Grid>
			<Grid item xs={2}>
				<Box>
					<Paper elevation={0} >
						{/* <ProgressBarComponent percentage={actualSchedulePercentage} /> */}
						{/* <div style={{ width: '300px', height: '150px' }}>
							<SemiCircularDonut percentage={actualSchedulePercentage} />
						</div> */}
						{/* <DonutChart percentage={actualSchedulePercentage} /> */}
						<Box >
							<SemiCircularDonut percentage={actualSchedulePercentage} />
						</Box>
					</Paper>
				</Box>
			</Grid>
		</Grid>
	);
};

export default SummaryBar;
