import React, { useState } from 'react';
import {
	Button,
	Grid,
	MenuItem,
	Paper,
	Select,
	Typography,
	FormControl,
	InputLabel,
	TextField,
} from '@mui/material';
import { green } from '@mui/material/colors';
import {
  apiCreateProjectScope,
	apiCreateMaterial,
	IProjectScopeInput,
	IMaterialInput,
	IUserBackEnd,
} from '../../../services/apiService';
import { toast } from 'react-toastify';

interface AddMaterialProps {
	updatedDataToggle: boolean;
	setUpdatedDataToggle: React.Dispatch<React.SetStateAction<boolean>>;
}

function AddMaterial({
	updatedDataToggle,
	setUpdatedDataToggle,
}: AddMaterialProps) {
	const [newMaterial, setNewMaterial] =
		useState<IMaterialInput>({
			description: '',
		});

	const handleAddMaterial = async () => {
		if (newMaterial.description === '') {
			toast.warning('O campo precisa estar preenchido');
		} else {
			try {
				await apiCreateMaterial(newMaterial);
				setNewMaterial({
					description: '',
				});
				setUpdatedDataToggle(!updatedDataToggle);
				toast.success('Material adicionada com sucesso');
			} catch (error) {
				toast.error('Erro ao adicionar material');
			}
		}
	};

	return (
		<Grid item xs={12} style={{ marginBottom: 27 }}>
			<Paper elevation={5} style={{ padding: 20 }}>
				<Typography
					variant="h6"
					component="h3"
					style={{ marginBottom: '20px' }}
				>
					Novo Material
				</Typography>
				<Grid container spacing={2} alignItems="center">
					<Grid item xs={3}>
						<TextField
							fullWidth
							label="Material"
							value={newMaterial.description}
							onChange={e =>
								setNewMaterial({
									...newMaterial,
									description: e.target.value,
								})
							}
							variant="outlined"
						/>
					</Grid>
					<Grid item xs={2}>
						<Button
							variant="contained"
							onClick={handleAddMaterial}
							sx={{
								backgroundColor: green[500], // Cor de fundo verde
								'&:hover': {
									backgroundColor: green[700], // Cor de fundo mais escura ao passar o mouse
								},
								color: 'white', // Cor do texto
							}}
						>
							<strong>Adicionar</strong>
						</Button>
					</Grid>
				</Grid>
			</Paper>
		</Grid>
	);
}

export default AddMaterial;
