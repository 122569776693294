import React from 'react';
import ProjectEdit from '../../components/ProjectEdit/ProjectEdit';

function ProjectEditPage() {
	return (
		<>
			<ProjectEdit />
		</>
	);
}

export default ProjectEditPage;
