import React, { useEffect, useState } from 'react';
import AddPlatform from './AddPlatform';
import UpdatePlatform from './UpdatePlatform';
import {
	apiGetAllClient,
	apiGetAllPlatformsClients,
	apiGetAllUsers,
	IClientBackEnd,
	IPlatformBackEnd,
	IUserBackEnd,
} from '../../../services/apiService';
import { toast } from 'react-toastify';
import { MoonLoader } from 'react-spinners';

function PlatformSettings() {
	const [clientsDropdown, setClientsDropdown] = useState<IClientBackEnd[]>([]);
	const [platforms, setPlatforms] = useState<IPlatformBackEnd[]>([]);
	const [updatedDataToggle, setUpdatedDataToggle] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const clients = await apiGetAllClient();
				setClientsDropdown(clients);
			} catch (error) {
				toast.error('Erro ao carregar dados iniciais de clientes');
			}
			try {
				const platforms = await apiGetAllPlatformsClients();
				setPlatforms(platforms);
			} catch (error) {
				toast.error('Erro ao carregar plataformas');
			} finally {
				setTimeout(() => {
					setIsLoading(false);
				}, 500);
			}
		};
		fetchData();
	}, []);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const platforms = await apiGetAllPlatformsClients();
				setPlatforms(platforms);
			} catch (error) {
				toast.error('Erro ao recarregar plataformas');
			} finally {
				setTimeout(() => {
					setIsLoading(false);
				}, 500);
			}
		};
		setIsLoading(true);
		fetchData();
	}, [updatedDataToggle]);

	return (
		<div>
			<AddPlatform
				clients={clientsDropdown}
				updatedDataToggle={updatedDataToggle}
				setUpdatedDataToggle={setUpdatedDataToggle}
			/>
			{isLoading ? (
				<div>
					<MoonLoader color="#36d7b7" />
				</div>
			) : (
				<UpdatePlatform
					platforms={platforms}
					clients={clientsDropdown}
					updatedDataToggle={updatedDataToggle}
				/>
			)}
		</div>
	);
}

export default PlatformSettings;
