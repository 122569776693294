// utils.ts

import { IOperationalProjection } from '../../services/apiService';

// Função para formatar a data no formato dd/mm/yyyy
export function formatDate(date: string): string {
	const [year, month, day] = date.split('-');
	return `${day}/${month}/${year}`;
}

// Função para formatar a data como string padrão
export function formatDateString(date: string): string {
	const dateParts = date.split('-');
	const year = dateParts[0];
	const month = dateParts[1];
	const day = dateParts[2];
	return date !== '' ? `${day}/${month}/${year}` : date;
}

// Função para formatar os dados da tabela para exportação
export function formatTableDataForExport(
	operationalProjection: IOperationalProjection | undefined
) {
	if (!operationalProjection) return [];

	const { rows, days, daily_sum } = operationalProjection;

	// Cabeçalhos das colunas
	const headers = [
		'WO',
		'Coordenador',
		'Cliente',
		'Tipo',
		'Plataforma',
		'Escopo',
		'Data início',
		'Planejado (dias)',
		'Equipe (qt pessoas)',
		'Status',
		'Situação',
		'Comentários',
		...days.map(day => formatDate(day)),
	];

	// Filtrar daily_sum para incluir apenas o "Realizado"
	const realizadoSum = daily_sum.find(sum => sum.situation === 'Realizado');

	// Se "Realizado" estiver presente, adicione-o como a primeira linha
	let data = [];
	if (realizadoSum) {
		data.push([
			'', // WO vazio
			'', // Coordenador vazio
			'', // Cliente vazio
			'', // Tipo vazio
			'', // Plataforma vazia
			'', // Escopo vazio
			'', // Data início vazia
			'', // Planejado (dias) vazio
			'', // Equipe (qt pessoas) vazio
			'', // Status vazio
			realizadoSum.situation, // Situação
			'TOTAL REALIZADO', // Comentários
			...realizadoSum.number_of_workers, // Quantidade de trabalhadores por dia
		]);
	}

	// Dados da tabela
	const rowsData = rows.map(row => [
		row.wo,
		row.coordinator,
		row.client,
		row.type,
		row.platform,
		row.scope,
		formatDateString(row.start_date),
		row.planned_days,
		row.team_size,
		row.status,
		row.situation,
		row.comments,
		...row.number_of_workers,
	]);

	// Concatenar os dados, garantindo que "Realizado" esteja na primeira linha
	data = data.concat(rowsData);

	return [headers, ...data];
}

// Função para exportar os dados do resumo para uma nova aba do Excel
export function formatSummaryDataForExport(
	operationalProjection: IOperationalProjection | undefined
) {
	if (!operationalProjection) return [];

	const planned = operationalProjection.data_summary.planned ?? 0;
	const scheduled = operationalProjection.data_summary.scheduled ?? 0;
	const planProgDifference = planned - scheduled;
	const planProgPercentage = (scheduled / planned) * 100;

	// Cabeçalhos
	const summaryHeaders = ['Métrica', 'Valor'];

	// Dados do resumo
	const summaryData = [
		['Planejado', planned],
		['Programado', scheduled],
		['Diferença Planejado-Programado', planProgDifference],
		['Porcentagem Programado/Planejado', `${planProgPercentage.toFixed(2)}%`],
	];

	return [summaryHeaders, ...summaryData];
}

// Função para exportar os dados de cada mês para uma nova aba do Excel
export function formatMonthlyDataForExport(
	operationalProjection: IOperationalProjection | undefined
) {
	if (!operationalProjection) return [];

	const workers_month = operationalProjection.workers_month;

	// Extrair nomes abreviados dos meses e ano
	const monthAndYear = workers_month.map(item => {
		const [year, month] = item.date.split('-');
		const monthNames = [
			'Jan',
			'Fev',
			'Mar',
			'Abr',
			'Mai',
			'Jun',
			'Jul',
			'Ago',
			'Set',
			'Out',
			'Nov',
			'Dez',
		];
		const monthAbbr = monthNames[parseInt(month, 10) - 1];
		return `${monthAbbr}-${year.substr(2)}`;
	});

	// Cabeçalho da tabela
	const monthlyHeaders = ['Meses', ...monthAndYear];

	// Linha de valores
	const monthlyData = [
		[
			'Diárias realizadas',
			...workers_month.map(item => item.number_of_workers),
		],
	];

	return [monthlyHeaders, ...monthlyData];
}
