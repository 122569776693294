import axios from "axios";

const BASE_URL =
	process.env.REACT_APP_NODE_ENV === "development"
		? "http://localhost:8000"
		: "/api";

// const BASE_URL = `http://${process.env.REACT_APP_SERVICE_BACKEND}:8000`;

// const BASE_URL = 'http://146.190.186.191:80';

const defaultAxiosConfig = {
	maxBodyLength: Infinity,
	baseURL: BASE_URL,
};

const axiosInstance = axios.create({
	...defaultAxiosConfig,
	headers: {
		Accept: "application/json",
		Authorization: `Bearer ${localStorage.getItem("token")}`,
	},
	timeout: 10000,
});

// Adicionando interceptor de resposta
axiosInstance.interceptors.response.use(
	(response) => response,
	(error) => {
		// Se o status da resposta for 401, redireciona para a tela de login
		if (error.response && error.response.status === 401) {
			// Implemente o redirecionamento para a tela de login conforme necessário
			// Se estiver usando React Router, você pode querer armazenar o estado de login globalmente e ouvir mudanças nesse estado para redirecionar
			window.location.href = "/login";
		}
		return Promise.reject(error);
	}
);

export async function read(url: string) {
	const { data } = await axiosInstance.get(url);
	return data;
}

export async function exclude(url: string) {
	await axiosInstance.delete(url);
}

export async function create(url: string, object: object) {
	const { data } = await axiosInstance.post(url, object);
	return data;
}

export async function edit(url: string, object: object) {
	const { data } = await axiosInstance.put(url, object);
	return data;
}
