import { IClientBackEnd } from '../services/apiService';

export function removeDuplicatesByIdUtil(
	clients: IClientBackEnd[]
): IClientBackEnd[] {
	const uniqueIds = new Set<number>();
	const uniqueClients: IClientBackEnd[] = [];

	for (const client of clients) {
		if (!uniqueIds.has(client.id)) {
			uniqueIds.add(client.id);
			uniqueClients.push(client);
		}
	}

	return uniqueClients;
}

