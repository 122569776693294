import React from 'react';
import {
	FormControl,
	FormLabel,
	RadioGroup,
	FormControlLabel,
	Radio,
} from '@mui/material';

interface VariationSelectProps {
	value: string;
	onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const VariationSelect: React.FC<VariationSelectProps> = ({
	value,
	onChange,
}) => {
	return (
		<FormControl component="fieldset" style={{ margin: 8 }}>
			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					flexWrap: 'wrap',
				}}
			>
				<FormLabel
					component="legend"
					style={{ marginRight: 16, marginBottom: 0 }}
				>
					Variação em Relação:
				</FormLabel>
				<RadioGroup
					aria-label="variation"
					name="variation"
					value={value}
					onChange={onChange}
					style={{ flexDirection: 'row' }}
				>
					<FormControlLabel
						value="Planejado"
						control={<Radio />}
						label="Planejado"
					/>
					<FormControlLabel
						value="Programado"
						control={<Radio />}
						label="Programado"
					/>
				</RadioGroup>
			</div>
		</FormControl>
	);
};

export default VariationSelect;
