import React, { useState, useEffect } from 'react';
import {
	Button,
	Box,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	TextField,
} from '@mui/material';
import {
	apiGetAllPlatformsClients,
	apiGetAllProjectClassification,
	apiGetAllProjectNumber,
	apiGetAllProjectScope,
	apiGetAllUsers,
	apiGetWorkerBoardingByCriteria,
	IClientBackEnd,
	IPlatformBackEnd,
	IProjectClassificationBackEnd,
	IProjectNumberBackEnd,
	IProjectScopeBackEnd,
	IUserBackEnd,
	IPerformanceOverview,
	apiGetPerformanceOverview,
} from '../../../services/apiService';
import { removeDuplicatesByIdUtil } from '../../../utils/clientsBackEnd';
import { toast } from 'react-toastify';
import { getMonthBoundaryDate } from '../../../utils/getMonthBoundaryDate';
import axios from 'axios';

interface FiltersProps {
	setPerformanceOverview: React.Dispatch<
		React.SetStateAction<IPerformanceOverview | undefined>
	>;
	startDate: string;
	setStartDate: React.Dispatch<React.SetStateAction<string>>;
	endDate: string;
	setEndDate: React.Dispatch<React.SetStateAction<string>>;
}

const Filters: React.FC<FiltersProps> = ({
	setPerformanceOverview,
	startDate,
	setStartDate,
	endDate,
	setEndDate,
}) => {
	const [dropdownClient, setDropdownClient] = useState<IClientBackEnd[]>([]);
	const [selectedClientId, setSelectedClientId] = useState<number | ''>('');
	const [dropdownPlatform, setDropdownPlatform] = useState<IPlatformBackEnd[]>(
		[]
	);
	const [filteredPlatforms, setFilteredPlatforms] = useState<
		IPlatformBackEnd[]
	>([]);
	const [selectedPlatformId, setSelectedPlatformId] = useState<number | ''>('');
	const [dropdownProjectNumber, setDropdownProjectNumber] = useState<
		IProjectNumberBackEnd[]
	>([]);
	const [selectedProjectNumber, setSelectedProjectNumber] = useState<
		number | ''
	>('');
	const [dropdownProjectScope, setDropdownProjectScope] = useState<
		IProjectScopeBackEnd[]
	>([]);
	const [selectedProjectScopeId, setSelectedProjectScopeId] = useState<
		number | ''
	>('');
	const [dropdownProjectClassification, setDropdownProjectClassification] =
		useState<IProjectClassificationBackEnd[]>([]);
	const [selectedProjectClassificationId, setSelectedProjectClassificationId] =
		useState<number | ''>('');
	const [dropdownUser, setDropdownUser] = useState<IUserBackEnd[]>([]);
	const [selectedUserId, setSelectedUserId] = useState<number | ''>('');

	useEffect(() => {
		const fetchDropdownData = async () => {
			try {
				const backEndAllPlatformsClients = await apiGetAllPlatformsClients();
				const clients = backEndAllPlatformsClients.map(obj => obj.client);
				const clientsUnique = removeDuplicatesByIdUtil(clients);
				setDropdownClient(clientsUnique);
				setDropdownPlatform(backEndAllPlatformsClients);
			} catch (error) {
				toast.error(
					'ERROR: falha ao carregar os dados de clientes e plataformas.'
				);
			}
			try {
				const backEndAllProjectNumber = await apiGetAllProjectNumber();
				setDropdownProjectNumber(backEndAllProjectNumber);
			} catch (error) {
				toast.error(
					'ERROR: falha ao carregar os dados dos números dos projetos.'
				);
			}
			try {
				const backEndAllUser = await apiGetAllUsers();
				setDropdownUser(backEndAllUser);
			} catch (error) {
				toast.error('ERROR: falha ao carregar os dados de coordenadores.');
			}
			try {
				const backEndAllProjectClassification =
					await apiGetAllProjectClassification();
				setDropdownProjectClassification(backEndAllProjectClassification);
			} catch (error) {
				toast.error(
					'ERROR: falha ao carregar os dados de classificação de projeto.'
				);
			}

			try {
				const backEndAllProjectScope = await apiGetAllProjectScope();
				setDropdownProjectScope(backEndAllProjectScope);
			} catch (error) {
				toast.error('ERROR: falha ao carregar os dados de escopo de projeto.');
			}
		};

		fetchDropdownData();
	}, []);

	useEffect(() => {
		if (selectedClientId) {
			const filtered = dropdownPlatform.filter(
				platform => platform.client.id === selectedClientId
			);
			setFilteredPlatforms(filtered);
		} else {
			setFilteredPlatforms([]);
		}
	}, [selectedClientId]);

	const handleFilterSubmit = () => {
		const filters = {
			startDate,
			endDate,
			selectedClientId,
			selectedPlatformId,
			selectedProjectNumber,
			selectedProjectScopeId,
			selectedProjectClassificationId,
			selectedUserId,
		};
		console.log('🚀  filters:', filters);
		const fetchPerformanceOverview = async () => {
			try {
				const performanceOverview = await apiGetPerformanceOverview(
					startDate,
					endDate,
					selectedClientId,
					selectedPlatformId,
					selectedProjectNumber,
					selectedProjectScopeId,
					selectedProjectClassificationId,
					selectedUserId
				);
				setPerformanceOverview(performanceOverview);
        console.log('🚀  performanceOverview:', performanceOverview);
			} catch (error) {
				setPerformanceOverview(undefined);
				if (axios.isAxiosError(error)) {
					if (error.response?.status !== 404) {
						toast.error('ERROR: falha ao carregar dados.');
					} else {
						toast.warning('WARNING: nenhum dado encontrado.');
					}
				} else {
					toast.error('ERROR: falha ao carregar dados.');
				}
			}
		};
		if (startDate === '' || endDate === '') {
      toast.warning('As datas devem estar preenchidas.');
		} else {
      fetchPerformanceOverview();
		}    
	};

  	const handleClear = () => {
			setSelectedPlatformId('');
			setSelectedProjectNumber('');
			setSelectedClientId('');
			setSelectedProjectScopeId('');
			setSelectedProjectClassificationId('');
			setSelectedUserId('');
			setStartDate('');
			setEndDate(''); // Esta linha deve limpar o endDate
		};
return (
	<Box display="flex" flexWrap="wrap" gap="20px">
		<TextField
			label="Data início"
			type="date"
			value={startDate}
			onChange={e => setStartDate(e.target.value)}
			InputLabelProps={{
				shrink: true,
			}}
		/>
		<TextField
			label="Data fim"
			type="date"
			value={endDate}
			onChange={e => setEndDate(e.target.value)}
			InputLabelProps={{
				shrink: true,
			}}
		/>
		<FormControl style={{ minWidth: '150px' }}>
			<InputLabel>Cliente</InputLabel>
			<Select
				label="Cliente"
				value={selectedClientId}
				onChange={e => {
					setSelectedClientId(+e.target.value);
					setSelectedPlatformId('');
				}}
			>
				{dropdownClient
					.sort((a, b) => a.client_name.localeCompare(b.client_name))
					.map(client => (
						<MenuItem key={client.id} value={client.id}>
							{client.client_name}
						</MenuItem>
					))}
			</Select>
		</FormControl>

		<FormControl style={{ minWidth: '150px' }}>
			<InputLabel>Rigs</InputLabel>
			<Select
				label="Rigs"
				value={selectedPlatformId}
				onChange={e => setSelectedPlatformId(+e.target.value)}
			>
				{filteredPlatforms
					.sort((a, b) => a.platform_name.localeCompare(b.platform_name))
					.map(platform => (
						<MenuItem key={platform.id} value={platform.id}>
							{platform.platform_name}
						</MenuItem>
					))}
			</Select>
		</FormControl>

		<FormControl style={{ minWidth: '150px' }}>
			<InputLabel>WO</InputLabel>
			<Select
				label="WO"
				value={selectedProjectNumber}
				onChange={e => setSelectedProjectNumber(+e.target.value)}
			>
				{dropdownProjectNumber
					.sort((a, b) => a.project_number - b.project_number)
					.map(projectNumber => (
						<MenuItem
							key={projectNumber.id}
							value={projectNumber.project_number}
						>
							{projectNumber.project_number}
						</MenuItem>
					))}
			</Select>
		</FormControl>

		<FormControl style={{ minWidth: '150px' }}>
			<InputLabel>Escopo</InputLabel>
			<Select
				label="Escopo"
				value={selectedProjectScopeId}
				onChange={e => setSelectedProjectScopeId(+e.target.value)}
			>
				{dropdownProjectScope
					.sort((a, b) => a.sow.localeCompare(b.sow))
					.map(project_scope => (
						<MenuItem key={project_scope.id} value={project_scope.id}>
							{project_scope.sow}
						</MenuItem>
					))}
			</Select>
		</FormControl>

		<FormControl style={{ minWidth: '150px' }}>
			<InputLabel>Tipo</InputLabel>
			<Select
				label="Tipo"
				value={selectedProjectClassificationId}
				onChange={e => setSelectedProjectClassificationId(+e.target.value)}
			>
				{dropdownProjectClassification
					.sort((a, b) => a.description.localeCompare(b.description))
					.map(project_classification => (
						<MenuItem
							key={project_classification.id}
							value={project_classification.id}
						>
							{project_classification.description}
						</MenuItem>
					))}
			</Select>
		</FormControl>

		<FormControl style={{ minWidth: '150px' }}>
			<InputLabel>Coordenador</InputLabel>
			<Select
				label="Coordenador"
				value={selectedUserId}
				onChange={e => setSelectedUserId(+e.target.value)}
			>
				{dropdownUser
					.sort((a, b) => a.username.localeCompare(b.username))
					.filter(user => user.coordinator)
					.map(coordinator => (
						<MenuItem key={coordinator.id} value={coordinator.id}>
							{coordinator.username}
						</MenuItem>
					))}
			</Select>
		</FormControl>

		<Box
			display="flex"
			justifyContent="flex-start"
			// marginTop="10px"
			gap="20px"
			width="100%"
		>
			<Button
				variant="contained"
				onClick={handleFilterSubmit}
				style={{ height: '56px' }}
			>
				Filtrar
			</Button>
			<Button
				variant="contained"
				onClick={handleClear}
				style={{
					background: '#e5e5e5',
					color: 'black',
				}}
			>
				Limpar
			</Button>
		</Box>
	</Box>
);

};

export default Filters;
