import React, { useEffect, useState } from 'react';
import {
	apiGetAllLevels,
	apiGetAllSkillLevels,
	apiGetAllSkills,
	apiGetAllUsers,
	ILevel,
	ISkill,
	ISkillLevel,
} from '../../../services/apiService';
import { toast } from 'react-toastify';
import { MoonLoader } from 'react-spinners';
import AddSkillLevel from './AddSkillLevel';
import UpdateSkillLevel from './UpdateSkillLevel';

function SkillLevelSettings() {
	const [levelsDropdown, setLevelsDropdown] = useState<ILevel[]>([]);
	const [skillsDropdown, setSkillsDropdown] = useState<ISkill[]>([]);
	const [skillLevels, setSkillLevels] = useState<ISkillLevel[]>([]);
	const [updatedDataToggle, setUpdatedDataToggle] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const levels = await apiGetAllLevels();
				setLevelsDropdown(levels);
			} catch (error) {
				toast.error('Erro ao carregar dados iniciais de níveis');
			}

			try {
				const skills = await apiGetAllSkills();
				setSkillsDropdown(skills);
			} catch (error) {
				toast.error('Erro ao carregar dados iniciais de habilidades');
			}

			try {
				const skillLevels = await apiGetAllSkillLevels();
				setSkillLevels(skillLevels);
			} catch (error) {
				toast.error('Erro ao carregar habilidades e níveis');
			} finally {
				setTimeout(() => {
					setIsLoading(false);
				}, 500);
			}
		};
		fetchData();
	}, []);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const skillLevels = await apiGetAllSkillLevels();
				setSkillLevels(skillLevels);
			} catch (error) {
				toast.error('Erro ao carregar habilidades e níveis');
			} finally {
				setTimeout(() => {
					setIsLoading(false);
				}, 500);
			}
		};
		setIsLoading(true);
		fetchData();
	}, [updatedDataToggle]);

	return (
		<div>
			<AddSkillLevel
				levels={levelsDropdown}
				skills={skillsDropdown}
				updatedDataToggle={updatedDataToggle}
				setUpdatedDataToggle={setUpdatedDataToggle}
			/>
			{isLoading ? (
				<div>
					<MoonLoader color="#36d7b7" />
				</div>
			) : (
				<UpdateSkillLevel
					skillLevels={skillLevels}
					skills={skillsDropdown}
					levels={levelsDropdown}
					updatedDataToggle={updatedDataToggle}
				/>
			)}
		</div>
	);
}

export default SkillLevelSettings;
