import * as XLSX from 'xlsx';
import { IPerformanceOverview } from '../../services/apiService';

export function handleDownloadExcel(
	performanceOverview: IPerformanceOverview | undefined,
	startDate: string,
	endDate: string
) {
	if (!performanceOverview) {
		return;
	}

	const workbook = XLSX.utils.book_new();

	// Aba 1: Dados da Tabela Operacional
	const operationalData = formatOperationalData(performanceOverview);
	const operationalSheet = XLSX.utils.aoa_to_sheet(operationalData);
	XLSX.utils.book_append_sheet(
		workbook,
		operationalSheet,
		'Tabela Operacional'
	);

	// Aba 2: Resumo
	const summaryData = formatSummaryData(performanceOverview);
	const summarySheet = XLSX.utils.aoa_to_sheet(summaryData);
	XLSX.utils.book_append_sheet(workbook, summarySheet, 'Resumo');

	// Aba 3: Realizado por Cliente
	const actualByClientData = formatActualByClientData(performanceOverview);
	const actualByClientSheet = XLSX.utils.aoa_to_sheet(actualByClientData);
	XLSX.utils.book_append_sheet(
		workbook,
		actualByClientSheet,
		'Realizado por Cliente'
	);

	// Aba 4: Realizado por Coordenador
	const actualByCoordinatorData =
		formatActualByCoordinatorData(performanceOverview);
	const actualByCoordinatorSheet = XLSX.utils.aoa_to_sheet(
		actualByCoordinatorData
	);
	XLSX.utils.book_append_sheet(
		workbook,
		actualByCoordinatorSheet,
		'Realizado por Coordenador'
	);

	// Escreve o arquivo Excel
	XLSX.writeFile(
		workbook,
		`performance_overview_${startDate}_to_${endDate}.xlsx`
	);
}

// Função para formatar a data no formato dia/mês/ano
function formatDate(dateString: string): string {
	const [year, month, day] = dateString.split('-');
	return `${day}/${month}/${year}`;
}

function formatOperationalData(
	performanceOverview: IPerformanceOverview
): any[][] {
	const { days, daily_sum } = performanceOverview;

	// Formatar as datas para o formato dia/mês/ano
	const formattedDays = days.map(formatDate);

	const headers = ['Data', ...formattedDays];
	const rows = daily_sum.map(item => [
		item.situation,
		...item.number_of_workers,
	]);

	return [headers, ...rows];
}

function formatSummaryData(performanceOverview: IPerformanceOverview): any[][] {
	const data_summary = performanceOverview.data_summary;

	return [
		['Métrica', 'Valor'],
		['Planejado', data_summary.planned],
		['Programado', data_summary.scheduled],
		['Realizado', data_summary.actual],
		['% Realizado Programado', `${data_summary.actual_scheduled_percentage}%`],
	];
}

function formatActualByClientData(
	performanceOverview: IPerformanceOverview
): any[][] {
	const { actual_percentage_clients } = performanceOverview;

	const headers = ['Cliente', 'Planejado', 'Programado'];
	const rows = actual_percentage_clients.labels.map((label, index) => [
		label,
		actual_percentage_clients.actual_by_planned[index],
		actual_percentage_clients.actual_by_scheduled[index],
	]);

	return [headers, ...rows];
}

function formatActualByCoordinatorData(
	performanceOverview: IPerformanceOverview
): any[][] {
	const { actual_percentage_coordinators } = performanceOverview;

	const headers = ['Coordenador', 'Planejado', 'Programado'];
	const rows = actual_percentage_coordinators.labels.map((label, index) => [
		label,
		actual_percentage_coordinators.actual_by_planned[index],
		actual_percentage_coordinators.actual_by_scheduled[index],
	]);

	return [headers, ...rows];
}
