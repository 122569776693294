import React from 'react';
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
} from '@mui/material';
import {
	IOperationalProjection,
	ITotalWorkersMonthly,
} from '../../../services/apiService';

interface MonthlyTableProps {
	operationalProjection: IOperationalProjection | undefined;
}

const MonthlyTable: React.FC<MonthlyTableProps> = ({
	operationalProjection,
}) => {
	if (!operationalProjection) return null;

	const workers_month: ITotalWorkersMonthly[] =
		operationalProjection.workers_month;

	// Extrair nomes abreviados dos meses e ano
	const monthAndYear = workers_month.map(item => {
		const [year, month] = item.date.split('-');
		const monthNames = [
			'Jan',
			'Fev',
			'Mar',
			'Abr',
			'Mai',
			'Jun',
			'Jul',
			'Ago',
			'Set',
			'Out',
			'Nov',
			'Dez',
		];
		const monthAbbr = monthNames[parseInt(month, 10) - 1];
		return `${monthAbbr}-${year.substr(2)}`;
	});

	// Cabeçalho da tabela
	const tableHeader = ['Meses', ...monthAndYear];

	// Linha de valores
	const tableRow = [
		'Diárias realizadas',
		...workers_month.map(item => item.number_of_workers),
	];

	return (
		<TableContainer component={Paper}>
			<Table>
				<TableHead>
					<TableRow>
						{tableHeader.map((header, index) => (
							<TableCell
								key={index}
								style={{
									fontWeight: 'bold',
									background: '#f0f0f0',
									borderRight: '1px solid #ccc',
								}}
							>
								{header}
							</TableCell>
						))}
					</TableRow>
				</TableHead>
				<TableBody>
					<TableRow>
						{tableRow.map((cell, index) => (
							<TableCell key={index} style={{ borderRight: '1px solid #ccc' }}>
								{cell}
							</TableCell>
						))}
					</TableRow>
				</TableBody>
			</Table>
		</TableContainer>
	);
};

export default MonthlyTable;
