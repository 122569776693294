import {
	FormControl,
	Grid,
	InputLabel,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	MenuItem,
	Paper,
	Select,
	TextField,
	Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
	IBoardingBackend,
	IProjectNumberBackEnd,
	IProjectSaleOperationRole,
	IWorkerBackEnd,
	IWorkerBoardingBackEnd,
	apiGetAllProjectNumber,
	apiGetBoardingByProjectNumber,
	apiGetSaleOperationRoleByProjectNumber,
	apiGetWorkerBoardingByBoardingId,
} from '../services/apiService';

import { toast } from 'react-toastify';
import undefinedToText from '../utils/undefinedToText';
import axios from 'axios';

interface IProps {
	setWorkers: (workers: IWorkerBackEnd[]) => void;
	updateBoarding: (newValue: IBoardingBackend | null) => void;
	addedWorker: boolean;
	setAddedWorker: (addedWorker: boolean) => void;
	selectedBoardingNumber: number | '';
	setSelectedBoardingNumber: (selectedBoardingNumber: number | '') => void;
}

function ProjectPeople({
	updateBoarding,
	setWorkers,
	addedWorker,
	setAddedWorker,
	selectedBoardingNumber,
	setSelectedBoardingNumber,
}: IProps) {
	const [dropdownProjectNumber, setDropdownProjectNumber] = useState<
		IProjectNumberBackEnd[]
	>([]);
	const [filteredProjectNumbers, setFilteredProjectNumbers] = useState<
		IProjectNumberBackEnd[]
	>([]);
	const [selectedProjectNumber, setSelectedProjectNumber] = useState<
		number | ''
	>('');
	const [projectName, setProjectName] = useState<string>('');
	const [clientName, setClientName] = useState<string>('');
	const [platformName, setPlatformName] = useState<string>('');

	const [coodinator, setCoodinator] = useState<string>('');
	const [numberOfWorkers, setNumberOfWorkers] = useState<number | ''>('');
	const [scheduledEmbarkationDate, setScheduledEmbarkationDate] =
		useState<string>('');
	const [scheduledDisembarkationDate, setScheduledDisembarkationDate] =
		useState<string>('');
	const [boardings, setBoardings] = useState<IBoardingBackend[]>([]);
	const [projectSaleOperationRoles, setProjectSaleOperationRoles] = useState<
		IProjectSaleOperationRole[]
	>([]);

	const [workerBoardings, setWorkerBoardings] = useState<
		IWorkerBoardingBackEnd[]
	>([]);

	useEffect(() => {
		const fetchDropdownData = async () => {
			try {
				const backEndAllProjectNumber = await apiGetAllProjectNumber();
				setDropdownProjectNumber(backEndAllProjectNumber);
				setFilteredProjectNumbers(backEndAllProjectNumber);
			} catch (error) {
				toast.error(
					'ERROR: falha ao carregar os dados dos números dos projetos.'
				);
			}
		};
		fetchDropdownData();
	}, []);

	useEffect(() => {
		const fetchProjectData = async () => {
			setWorkers([]);
			try {
				const boardings = await apiGetBoardingByProjectNumber(
					Number(selectedProjectNumber)
				);
				try {
					const projectOperationRoles =
						await apiGetSaleOperationRoleByProjectNumber(
							Number(selectedProjectNumber)
						);
					setProjectSaleOperationRoles(projectOperationRoles);
				} catch (error) {
					toast.error('ERROR: falha ao carregar perfis dos funcionários.');
				}
				setBoardings(boardings);
				setProjectName(boardings[0].project.project_name);
				setClientName(boardings[0].project.client.client_name);
				setPlatformName(boardings[0].project.platform.platform_name);
				setCoodinator(boardings[0].project.user.username);
				setSelectedBoardingNumber('');
				setNumberOfWorkers('');
				setWorkerBoardings([]);
				updateBoarding(null);
				setScheduledEmbarkationDate('');
				setScheduledDisembarkationDate('');
			} catch (error) {
				setBoardings([]);
				setProjectName('');
				setClientName('');
				setPlatformName('');
				setCoodinator('');
				setSelectedBoardingNumber('');
				setNumberOfWorkers('');
				setWorkerBoardings([]);
				setProjectSaleOperationRoles([]);
				updateBoarding(null);
				setScheduledEmbarkationDate('');
				setScheduledDisembarkationDate('');
				if (axios.isAxiosError(error)) {
					if (error.response?.status === 404) {
						toast.warning(
							'Nenhum embarque encontrado. Adicione um embarque ao projeto.'
						);
					} else {
						toast.error('ERROR: falha ao carregar os dados do projeto.');
					}
				} else {
					toast.error('ERROR: falha ao carregar os dados do projeto.');
				}
			}
		};

		if (selectedProjectNumber) {
			fetchProjectData();
		}
	}, [selectedProjectNumber]);

	useEffect(() => {
		const featchWorkerBoardings = async (boardindId: number) => {
			try {
				const workerBoardings = await apiGetWorkerBoardingByBoardingId(
					boardindId
				);
				setWorkerBoardings(workerBoardings);
			} catch (error) {
				setWorkerBoardings([]);
				if (axios.isAxiosError(error)) {
					if (error.response?.status !== 404) {
						toast.error('ERROR: falha ao carregar funcionários embarcados.');
					}
				} else {
					toast.error('ERROR: falha ao carregar funcionários embarcados.');
				}
			}
		};
		setWorkers([]);
		if (selectedBoardingNumber) {
			const boarding = boardings.filter(
				boarding => boarding.boarding_number === selectedBoardingNumber
			)[0];
			console.log('🚀  boarding:', boarding);
			updateBoarding(boarding);
			setNumberOfWorkers(boarding.number_of_workers);
			featchWorkerBoardings(boarding.id);
			setScheduledEmbarkationDate(boarding.scheduled_embarkation_date);
			setScheduledDisembarkationDate(boarding.scheduled_disembarkation_date);
		}
	}, [selectedBoardingNumber, addedWorker]);

	return (
		<>
			<Grid item xs={12}>
				<Paper elevation={5} style={{ padding: 20 }}>
					<Typography
						variant="h5"
						component="h2"
						style={{ marginBottom: '20px' }}
					>
						Projeto
					</Typography>
					<div>
						{' '}
						<Grid container spacing={3} direction="column">
							<Grid item container direction="row" spacing={3}>
								<Grid item xs={12} sm={4}>
									<FormControl fullWidth>
										<InputLabel id="projectNumber-id-label">
											Número do projeto
										</InputLabel>
										<Select
											labelId="projectNumber-id-label"
											id="projectNumberId"
											name="projectNumberId"
											value={selectedProjectNumber}
											onChange={e =>
												setSelectedProjectNumber(Number(e.target.value))
											}
											label="Número do projeto"
											style={{ width: '100%', maxWidth: '235px' }}
										>
											{filteredProjectNumbers
												.sort((a, b) => a.project_number - b.project_number)
												.map(projectNumber => (
													<MenuItem
														key={projectNumber.id}
														value={projectNumber.project_number}
													>
														{projectNumber.project_number}
													</MenuItem>
												))}
										</Select>
									</FormControl>
								</Grid>
								<Grid item xs={12} sm={4}>
									<FormControl fullWidth>
										<InputLabel
											id="project-name-id-label"
											shrink={!!projectName}
										>
											Nome do projeto
										</InputLabel>
										<TextField
											id="projectName"
											name="projectName"
											value={projectName}
											label="Nome do projeto"
											onChange={e => setProjectName(e.target.value)}
											disabled // Campo desativado
											style={{ width: '100%', maxWidth: '235px' }}
										/>
									</FormControl>
								</Grid>
							</Grid>
							<Grid item container direction="row" spacing={3}>
								<Grid item xs={12} sm={4}>
									<FormControl fullWidth>
										<InputLabel id="client-name-id-label" shrink={!!clientName}>
											Cliente
										</InputLabel>
										<TextField
											id="clientName"
											name="clientName"
											value={clientName}
											label="Cliente"
											onChange={e => setClientName(e.target.value)}
											disabled // Campo desativado
											style={{ width: '100%', maxWidth: '235px' }}
										/>
									</FormControl>
								</Grid>
								<Grid item xs={12} sm={4}>
									<FormControl fullWidth>
										<InputLabel
											id="platform-name-id-label"
											shrink={!!platformName}
										>
											Plataforma
										</InputLabel>
										<TextField
											id="platformName"
											name="platformName"
											value={platformName}
											label="Plataforma"
											onChange={e => setPlatformName(e.target.value)}
											disabled // Campo desativado
											style={{ width: '100%', maxWidth: '235px' }}
										/>
									</FormControl>
								</Grid>
							</Grid>
							<Grid item container direction="row" spacing={3}>
								<Grid item xs={12} sm={4}>
									<FormControl fullWidth>
										<InputLabel id="coordinator-id-label" shrink={!!coodinator}>
											Coordenador
										</InputLabel>
										<TextField
											id="coordinator"
											name="coordinator"
											value={coodinator}
											label="Coordenador"
											onChange={e => setCoodinator(e.target.value)}
											disabled // Campo desativado
											style={{ width: '100%', maxWidth: '235px' }}
										/>
									</FormControl>
								</Grid>
								<Grid item xs={12} sm={4.35}>
									<FormControl fullWidth>
										<InputLabel id="boarding-id-label">
											Número do Embarque
										</InputLabel>
										<Select
											labelId="boarding-id-label"
											id="boardingId"
											name="boardingId"
											value={selectedBoardingNumber?.toString() || ''}
											onChange={e =>
												setSelectedBoardingNumber(Number(e.target.value))
											}
											label="Número do Embarque"
											style={{ width: '100%', maxWidth: '235px' }}
										>
											{boardings
												.sort((a, b) => a.boarding_number - b.boarding_number)
												.map(boarding => (
													<MenuItem
														key={boarding.id}
														value={boarding.boarding_number}
													>
														{boarding.boarding_number}
													</MenuItem>
												))}
										</Select>
									</FormControl>
								</Grid>
							</Grid>
							<Grid item container direction="row" spacing={3}>
								{' '}
								<Grid item xs={12} sm={4}>
									<FormControl fullWidth>
										<InputLabel
											id="number-of-workers-id-label"
											shrink={!!numberOfWorkers}
										>
											Número de pessoas
										</InputLabel>
										<TextField
											id="numberOfWorkers"
											name="numberOfWorkers"
											value={numberOfWorkers}
											label="Número de pessoas"
											onChange={e => setNumberOfWorkers(+e.target.value)}
											disabled // Campo desativado
											style={{ width: '100%', maxWidth: '235px' }}
										/>
									</FormControl>
								</Grid>
							</Grid>
							<Grid item container direction="row" spacing={3}>
								<Grid item xs={12} sm={4}>
									<TextField
										id="scheduled_embarkation_date"
										name="scheduled_embarkation_date"
										label="Data de embarque (PROG)"
										type="date"
										InputLabelProps={{
											shrink: true,
										}}
										value={scheduledEmbarkationDate}
										onChange={e => setScheduledEmbarkationDate(e.target.value)}
										style={{ width: '100%', maxWidth: '235px' }}
										disabled // Campo desativado
									/>
								</Grid>
								<Grid item xs={12} sm={4}>
									<TextField
										id="scheduled_disembarkation_date"
										name="scheduled_disembarkation_date"
										label="Data de desembarque (PROG)"
										type="date"
										InputLabelProps={{
											shrink: true,
										}}
										value={scheduledDisembarkationDate}
										onChange={e =>
											setScheduledDisembarkationDate(e.target.value)
										}
										style={{ width: '100%', maxWidth: '235px' }}
										disabled // Campo desativado
									/>
								</Grid>
							</Grid>
						</Grid>
						<div style={{ display: 'flex' }}>
							<div style={{ marginTop: '30px', marginRight: '280px' }}>
								{projectSaleOperationRoles && (
									<>
										{' '}
										<Typography variant="h5" gutterBottom>
											Perfis dos funcionários
										</Typography>
										<List>
											{projectSaleOperationRoles
												.sort((a, b) =>
													a.sale_operation_role.description.localeCompare(
														b.sale_operation_role.description
													)
												)
												.map((projectOperationRole, index) => (
													<ListItem key={index}>
														<ListItemIcon>
															<div
																style={{
																	width: '8px',
																	height: '8px',
																	borderRadius: '50%',
																	backgroundColor: 'black',
																}}
															></div>
														</ListItemIcon>
														<ListItemText
															primary={
																projectOperationRole.sale_operation_role
																	.description
															}
														/>
													</ListItem>
												))}
										</List>
									</>
								)}
							</div>
							<div style={{ marginTop: '30px' }}>
								{workerBoardings && (
									<>
										{' '}
										<Typography variant="h5" gutterBottom>
											Alocados
										</Typography>
										<List>
											{workerBoardings
												.sort((a, b) => {
													const descriptionA = a.worker?.worker_name || '';
													const descriptionB = b.worker?.worker_name || '';
													return descriptionA.localeCompare(descriptionB);
												})
												.map((workerBoarding, index) => (
													<ListItem key={index}>
														<ListItemIcon>
															<div
																style={{
																	width: '8px',
																	height: '8px',
																	borderRadius: '50%',
																	backgroundColor: 'black',
																}}
															></div>
														</ListItemIcon>
														<ListItemText
															primary={
																<span>
																	<strong>
																		{workerBoarding.worker?.worker_name ||
																			'Indefinido'}
																	</strong>
																	{' - '}
																	{workerBoarding.worker?.operation_role
																		.description || 'Indefinido'}
																</span>
															}
														/>
													</ListItem>
												))}
										</List>
									</>
								)}
							</div>
						</div>
					</div>
				</Paper>
			</Grid>
		</>
	);
}

export default ProjectPeople;
