import React from 'react';
import '@blueprintjs/core/lib/css/blueprint.css';
import '@blueprintjs/table/lib/css/table.css';
import { Column, Cell, Table2 } from '@blueprintjs/table';
import {
	IOperationalProjection,
	ITableRow,
} from '../../../services/apiService';
import { Console } from 'console';

interface IProjectionTableProps {
	operationalProjection: IOperationalProjection | undefined;
	startDate: string;
	endDate: string;
	isFullScreen: boolean; // Adicione esta linha
}

function formatDate(date: string): string {
	const [year, month, day] = date.split('-');
	const monthNames = [
		'jan',
		'fev',
		'mar',
		'abr',
		'mai',
		'jun',
		'jul',
		'ago',
		'set',
		'out',
		'nov',
		'dez',
	];
	const abbreviatedMonth = monthNames[parseInt(month, 10) - 1];
	const abbreviatedYear = year.slice(-2);
	return `${day}\n${abbreviatedMonth}\n${abbreviatedYear}`;
}

function generateColumnWidth(n: number): number[] {
	const array: number[] = [
		50, 120, 120, 100, 200, 180, 90, 75, 70, 110, 90, 400,
	];

	for (let i = 0; i < n; i++) {
		array.push(35);
	}

	return array;
}

function getCellStyle(
	row: ITableRow,
	situation: string,
	value: number
): React.CSSProperties {
	let backgroundColor = '';
	let color = '';

	switch (situation) {
		case 'Planejado':
			backgroundColor = value !== 0 ? '#007EB8' : 'white';
			color = value !== 0 ? 'white' : 'black';
			break;
		case 'Programado':
			backgroundColor = value !== 0 ? '#001D55' : 'white';
			color = value !== 0 ? 'white' : 'black';
			break;
		case 'Realizado':
			backgroundColor = value !== 0 ? '#CCAA14' : 'white';
			color = value !== 0 ? 'black' : 'black';
			break;
		default:
			backgroundColor = 'white';
			color = 'black';
			break;
	}

	return {
		backgroundColor: backgroundColor,
		color: color,
	};
}

function filterZero(n: number) {
	const value = n === 0 ? '' : n;
	return value;
}

function formatDateString(date: string): string {
	const dateParts = date.split('-');

	const year = dateParts[0];
	const month = dateParts[1];
	const day = dateParts[2];

	const formattedDate = date !== '' ? `${day}/${month}/${year}` : date;
	return formattedDate;
}

function ProjectionTable({
	operationalProjection,
	startDate,
	endDate,
	isFullScreen,
}: IProjectionTableProps) {
	if (!operationalProjection) return null;
	// console.log(generateColumnWidth(operationalProjection.days.length + 1));

	const { rows, days } = operationalProjection;

	const daily_sum: ITableRow = {
		wo: 0,
		coordinator: '',
		client: '',
		type: '',
		platform: '',
		scope: '',
		start_date: '',
		planned_days: 0,
		team_size: 0,
		status: '',
		situation: 'Realizado',
		comments: 'TOTAL DIÁRIAS',
		number_of_workers: operationalProjection.daily_sum.filter(
			item => item.situation === 'Realizado'
		)[0].number_of_workers,
	};

	const row_with_sum = [daily_sum, ...rows];

	const columns = [
		<Column
			key="WO"
			name="WO"
			cellRenderer={(rowIndex: number) => (
				<Cell>{filterZero(row_with_sum[rowIndex].wo)}</Cell>
			)}
			columnHeaderCellRenderer={(columnIndex: number) => (
				<div
					style={{
						whiteSpace: 'pre-wrap',
						borderBottom: '1px solid rgba(0,0,0,0.1)',
						borderRight: '1px solid rgba(0,0,0,0.1)',
						// marginBottom: 10,
						// padding: 5,
						// paddingBottom: 20,
						paddingTop: 5,
						paddingLeft: 5,
					}}
				>
					{'WO\n\n'}
				</div>
			)}
		/>,
		<Column
			key="Coordinator"
			name="Coordinator"
			cellRenderer={(rowIndex: number) => (
				<Cell>{row_with_sum[rowIndex].coordinator}</Cell>
			)}
			columnHeaderCellRenderer={(columnIndex: number) => (
				<div
					style={{
						whiteSpace: 'pre-wrap',
						borderBottom: '1px solid rgba(0,0,0,0.1)',
						borderRight: '1px solid rgba(0,0,0,0.1)',
						paddingTop: 5,
						paddingLeft: 5,
					}}
				>
					{'Coordenador\n\n\n'}
				</div>
			)}
		/>,
		<Column
			key="Cliente"
			name="Cliente"
			cellRenderer={(rowIndex: number) => (
				<Cell>{row_with_sum[rowIndex].client}</Cell>
			)}
		/>,
		<Column
			key="Tipo"
			name="Tipo"
			cellRenderer={(rowIndex: number) => (
				<Cell>{row_with_sum[rowIndex].type}</Cell>
			)}
		/>,
		<Column
			key="Plataforma"
			name="Plataforma"
			cellRenderer={(rowIndex: number) => (
				<Cell>{row_with_sum[rowIndex].platform}</Cell>
			)}
		/>,
		<Column
			key="Escopo"
			name="Escopo"
			cellRenderer={(rowIndex: number) => (
				<Cell>{row_with_sum[rowIndex].scope}</Cell>
			)}
		/>,
		<Column
			key="Data início"
			name="Data início"
			cellRenderer={(rowIndex: number) => (
				<Cell>{formatDateString(row_with_sum[rowIndex].start_date)}</Cell>
			)}
		/>,
		<Column
			key="Planejado"
			name="Planejado"
			cellRenderer={(rowIndex: number) => (
				<Cell>{filterZero(row_with_sum[rowIndex].planned_days)}</Cell>
			)}
			columnHeaderCellRenderer={(columnIndex: number) => (
				<div
					style={{
						whiteSpace: 'pre-wrap',
						borderBottom: '1px solid rgba(0,0,0,0.1)',
						borderRight: '1px solid rgba(0,0,0,0.1)',
						paddingTop: 5,
						paddingLeft: 5,
						paddingRight: 5,
					}}
				>
					{'Planejado\n(dias)'}
				</div>
			)}
		/>,
		<Column
			key="Equipe (qt pessoas)"
			name="Equipe (qt pessoas)"
			cellRenderer={(rowIndex: number) => (
				<Cell>{filterZero(row_with_sum[rowIndex].team_size)}</Cell>
			)}
			columnHeaderCellRenderer={(columnIndex: number) => (
				<div
					style={{
						whiteSpace: 'pre-wrap',
						borderBottom: '1px solid rgba(0,0,0,0.1)',
						borderRight: '1px solid rgba(0,0,0,0.1)',
						paddingTop: 5,
						paddingLeft: 5,
						paddingRight: 5,
					}}
				>
					{'Equipe\n(qt\npessoas)'}
				</div>
			)}
		/>,
		<Column
			key="Status"
			name="Status"
			cellRenderer={(rowIndex: number) => (
				<Cell>{row_with_sum[rowIndex].status}</Cell>
			)}
			columnHeaderCellRenderer={(columnIndex: number) => (
				<div
					style={{
						whiteSpace: 'pre-wrap',
						borderBottom: '1px solid rgba(0,0,0,0.1)',
						borderRight: '1px solid rgba(0,0,0,0.1)',
						paddingTop: 5,
						paddingLeft: 5,
						paddingRight: 5,
					}}
				>
					{'Posição\noperações'}
				</div>
			)}
		/>,
		<Column
			key="Situação"
			name="Situação"
			cellRenderer={(rowIndex: number) => (
				<Cell>{row_with_sum[rowIndex].situation}</Cell>
			)}
		/>,
		<Column
			key="Comentários"
			name="Comentários"
			cellRenderer={(rowIndex: number) => (
				<Cell>{row_with_sum[rowIndex].comments}</Cell>
			)}
		/>,
		...days.map((day, index) => (
			<Column
				key={`Day_${index}`}
				name={formatDate(day)}
				cellRenderer={(rowIndex: number) => (
					<Cell
						style={getCellStyle(
							row_with_sum[rowIndex],
							row_with_sum[rowIndex].situation,
							row_with_sum[rowIndex].number_of_workers[index]
						)}
					>
						{row_with_sum[rowIndex].number_of_workers[index]}
					</Cell>
				)}
				columnHeaderCellRenderer={(columnIndex: number) => (
					<div
						style={{
							whiteSpace: 'pre-wrap',
							borderBottom: '1px solid rgba(0,0,0,0.1)',
							borderRight: '1px solid rgba(0,0,0,0.1)',
							paddingTop: 5,
							paddingLeft: 5,
							paddingRight: 5,
						}}
					>
						{formatDate(day)}
					</div>
				)}
			/>
		)),
	];

	return (
		<div
			style={{
				width: isFullScreen ? '100%' : 1530,
				height: isFullScreen ? 'calc(100vh - 100px)' : 600,
			}}
		>
			<Table2
				numRows={row_with_sum.length}
				numFrozenColumns={2}
				numFrozenRows={0}
				enableRowHeader={false}
				columnWidths={generateColumnWidth(operationalProjection.days.length)}
			>
				{columns}
			</Table2>
		</div>
	);
}

export default ProjectionTable;
