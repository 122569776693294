import React, { useState, useEffect } from 'react';
import {
	Button,
	Grid,
	MenuItem,
	Paper,
	Select,
	Typography,
	FormControl,
	InputLabel,
	TextField,
} from '@mui/material';
import { green } from '@mui/material/colors';
import {
  apiDeleteBoardingStatus,
	apiUpdateBoardingStatus,
  IBoardingStatusBackEnd,
} from '../../../services/apiService';
import { toast } from 'react-toastify';

interface UpdateBoardinStatusProps {
	boardingStatus: IBoardingStatusBackEnd[];
	updatedDataToggle: boolean;
}

function UpdateBoardingStatus({ boardingStatus, updatedDataToggle }: UpdateBoardinStatusProps) {
	const [updatedBoardingStatuss, setUpdatedBoardingStatuss] = useState<
		IBoardingStatusBackEnd[]
	>([]);

	useEffect(() => {
		// Ordena os boardingStatuses uma vez quando o componente é montado
		const sortedBoardingStatuss = [...boardingStatus].sort((a, b) =>
			a.description.localeCompare(b.description)
		);
		setUpdatedBoardingStatuss(sortedBoardingStatuss);
	}, [boardingStatus, updatedDataToggle]);

	const handleUpdateBoardingStatus = async (
		boardingStatus: IBoardingStatusBackEnd
	) => {
		if (boardingStatus.description === '') {
			toast.error('O campo não pode ser vazio');
			return;
		}

		const updatedBoardingStatus = {
			description: boardingStatus.description,
		};

		try {
			await apiUpdateBoardingStatus(
				boardingStatus.id,
				updatedBoardingStatus
			);
			toast.success('Status atualizado com sucesso');
		} catch (error) {
			toast.error('Erro ao atualizar o boardingStatus');
		}
	};

  const handleDelete = async (boardingStatusId: number) => {
		try {
			await apiDeleteBoardingStatus(boardingStatusId);
			const updatedBoardingStatussNew = updatedBoardingStatuss.filter(
				boardingStatus => boardingStatus.id !== boardingStatusId
			);
			setUpdatedBoardingStatuss(updatedBoardingStatussNew);
			toast.success('Registro deletado com sucesso!');
		} catch (error) {
			toast.error(
				'Erro: o status possui um projeto ligado a ele. Altere-o antes.'
			);
		}
	};
	const handleNameChange = (id: number, newBoardingStatus: string) => {
		setUpdatedBoardingStatuss(prevBoardingStatuss =>
			prevBoardingStatuss.map(boardingStatus =>
				boardingStatus.id === id
					? { ...boardingStatus, description: newBoardingStatus }
					: boardingStatus
			)
		);
	};

	return (
		<Grid item xs={12}>
			<Paper elevation={5} style={{ padding: 20 }}>
				<Typography
					variant="h6"
					component="h3"
					style={{ marginBottom: '20px' }}
				>
					Status
				</Typography>
				{updatedBoardingStatuss.map(boardingStatus => (
					<Grid
						container
						spacing={2}
						alignItems="center"
						key={boardingStatus.id}
						style={{ marginBottom: 20 }}
					>
						<Grid item xs={3}>
							<TextField
								fullWidth
								label="Status"
								value={boardingStatus.description}
								onChange={e =>
									handleNameChange(boardingStatus.id, e.target.value)
								}
								variant="outlined"
							/>
						</Grid>
						<Grid item xs={2}>
							<Button
								color="primary"
								variant="contained"
								onClick={() => handleUpdateBoardingStatus(boardingStatus)}
							>
								Atualizar
							</Button>
							<Button
								variant="contained"
								onClick={() => handleDelete(boardingStatus.id)} // Correção: Passar uma função anônima que chama handleDelete
								sx={{
									backgroundColor: 'red',
									'&:hover': {
										backgroundColor: 'darkred',
									},
									color: 'white',
									marginLeft: 2,
								}}
							>
								Deletar
							</Button>
						</Grid>
					</Grid>
				))}
			</Paper>
		</Grid>
	);
}

export default UpdateBoardingStatus;
