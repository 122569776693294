import React, { useEffect, useState } from 'react';
import AddSowDetais from './AddSowDetais';
import {
	apiGetAllSow,
	apiGetAllSowDetais,
	IProjectScopeBackEnd,
	IProjectScopeDetaisSowBackEnd,
} from '../../../services/apiService';
import { toast } from 'react-toastify';
import { MoonLoader } from 'react-spinners';
import UpdateSowDetais from './UpdateSowDetais';

function SowDetaisSettings() {
	const [sowsDropdown, setSowsDropdown] = useState<IProjectScopeBackEnd[]>([]);
	const [sowsDetais, setSowsDetais] = useState<IProjectScopeDetaisSowBackEnd[]>(
		[]
	);
	const [updatedDataToggle, setUpdatedDataToggle] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const sows = await apiGetAllSow();
				setSowsDropdown(sows);
			} catch (error) {
				toast.error('Erro ao carregar sows');
			}
			try {
				const sowDetais = await apiGetAllSowDetais();
				setSowsDetais(sowDetais);
			} catch (error) {
				toast.error('Erro ao carregar sows detais');
			} finally {
				setTimeout(() => {
					setIsLoading(false);
				}, 500);
			}
		};
		fetchData();
	}, []);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const sowDetais = await apiGetAllSowDetais();
				setSowsDetais(sowDetais);
			} catch (error) {
				toast.error('Erro ao carregar sows detais');
			} finally {
				setTimeout(() => {
					setIsLoading(false);
				}, 500);
			}
		};
		setIsLoading(true);
		fetchData();
	}, [updatedDataToggle]);

	return (
		<div>
			<AddSowDetais
				sows={sowsDropdown}
				updatedDataToggle={updatedDataToggle}
				setUpdatedDataToggle={setUpdatedDataToggle}
			/>
			{isLoading ? (
				<div>
					<MoonLoader color="#36d7b7" />
				</div>
			) : (
				<UpdateSowDetais
					sowsDetais={sowsDetais}
					sows={sowsDropdown}
					updatedDataToggle={updatedDataToggle}
				/>
			)}
		</div>
	);
}

export default SowDetaisSettings;
