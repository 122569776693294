import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { loginUser, LoginCredentials } from '../../services/authService'; // Assumindo que esta função faz a chamada de API

interface AuthState {
	isLoggedIn: boolean;
	user: any | null;
	isLoading: boolean;
	error: string | null;
}

const initialState: AuthState = {
	isLoggedIn: false,
	user: null,
	isLoading: false,
	error: null,
};

// Ação assíncrona para login
export const login = createAsyncThunk(
	'auth/login',
	async (credentials: LoginCredentials, { rejectWithValue }) => {
		try {
			const response = await loginUser(credentials);
			// Supondo que loginUser retorna os detalhes do usuário junto com o token
			return response;
		} catch (err: any) {
			return rejectWithValue(err.response.data);
		}
	}
);


export const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		logout: state => {
			state.isLoggedIn = false;
			state.user = null;
		},
		setLoggedIn: (state, action: PayloadAction<boolean>) => {
			state.isLoggedIn = action.payload;
		},
	},
	extraReducers: builder => {
		builder
			.addCase(login.pending, state => {
				state.isLoading = true;
				state.error = null;
			})
			.addCase(login.fulfilled, (state, action: PayloadAction<any>) => {
				state.isLoggedIn = true;
				state.user = action.payload;
				state.isLoading = false;
				localStorage.setItem('token', action.payload.token);
				localStorage.setItem('tokenExpiresAt', action.payload.expiresAt);

        localStorage.setItem(
					'visibilityRoles',
					JSON.stringify(action.payload.visibilityRoles)
				);
			})
			.addCase(login.rejected, (state, action) => {
				state.isLoading = false;
				state.error = action.payload as string;
			});
	},
});

export const { logout, setLoggedIn } = authSlice.actions;

export const selectAuth = (state: RootState) => state.auth;

export default authSlice.reducer;
